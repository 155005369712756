import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {Types, Creators} from './actions'
// api import
import { 
createOvertimeApi, 
updateOvertimeApi, 
fetchOvertimeApi, 
deleteOvertimeApi, 
assignEmployeeApi,

} from './api'
// types import
const { CREATE_OVERTIME,UPDATE_OVERTIME,FETCH_OVERTIME,DELETE_OVERTIME, ASSIGN_EMPLOYEES_OVERTIME } = Types
// success, fail import
const { 

    createOvertimeDone,
    createOvertimeFail, 
    updateOvertimeDone,
    updateOvertimeFail, 
    fetchOvertimeDone,
    fetchOvertimeFail, 
    deleteOvertimeDone,
    deleteOvertimeFail,
    fetchOvertime,
    assignEmployeesOvertimeDone,
    assignEmployeesOvertimeFail,

 } = Creators

// sagas handler

function* createOvertimeSaga(overtime){
    const response = yield call(createOvertimeApi, overtime);
    if (response.status){
        yield put(fetchOvertime());
        yield put(createOvertimeDone(response))
    }else{
        yield put(createOvertimeFail(response))
    }
}

function* updateOvertimeSaga(overtime){
    const response = yield call(updateOvertimeApi, overtime);
    if (response.status){
        yield put(updateOvertimeDone(response))
        yield put(fetchOvertime());
    }else{
        yield put(updateOvertimeFail(response))
    }
}

function* fetchOvertimeSaga(overtime){
    const response = yield call(fetchOvertimeApi, overtime);
    
    if (response.status){
        yield put(fetchOvertimeDone(response))
    }else{
        yield put(fetchOvertimeFail(response))
    }
}

function* deleteOvertimeSaga(overtime){
    const response = yield call(deleteOvertimeApi, overtime);
    if (response.status){
        yield put(deleteOvertimeDone(response))
        yield put(fetchOvertime());

    }else{
        yield put(deleteOvertimeFail(response))
    }
}

function* assignEmployeeSaga(assignment) {
    const response = yield call(assignEmployeeApi, assignment);
    if(response.status){
        yield put(assignEmployeesOvertimeDone(response.data));
    }else{
        yield put(assignEmployeesOvertimeFail(response.data))
    }
}
    
//saga watchers

export function* watchcreateOvertime(){
    yield takeEvery(CREATE_OVERTIME, createOvertimeSaga);
}

export function* watchAssignEMployee(){
    yield takeEvery(ASSIGN_EMPLOYEES_OVERTIME, assignEmployeeSaga)
}

export function* watchupdateOvertime(){
    yield takeEvery(UPDATE_OVERTIME, updateOvertimeSaga)
}

export function* watchfetchOvertime(){
    yield takeEvery(FETCH_OVERTIME, fetchOvertimeSaga)
}

export function* watchdeleteOvertime(){
    yield takeEvery(DELETE_OVERTIME, deleteOvertimeSaga)
}
    
// saga builder

export default function* overtimeSaga(){
    yield all([
        fork(watchcreateOvertime),
        fork(watchupdateOvertime),
        fork(watchfetchOvertime),
        fork(watchdeleteOvertime),
        fork(watchAssignEMployee)
    ])
}