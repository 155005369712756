
import { createActions } from 'reduxsauce'

export const {Types, Creators} = createActions({
        
    timesheetComputation: ['timesheet'],
    payrollComputation: ['payroll'],
    timesheetComputationFail: ['error'],
    timesheetComputationDone: ['timesheet'],
    payrollComputationDone: ['payroll'],
    payrollComputationFail: ['error'],

    timesheetResetComputation: ['timesheet'],
    payrollResetComputation: ['payroll'],
    timesheetResetComputationFail: ['error'],
    timesheetResetComputationDone: ['timesheet'],
    payrollResetComputationDone: ['payroll'],
    payrollResetComputationFail: ['error'],
    
});
    