import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchShift: ['shift'], // will handle one and many case(bulk)
  fetchShiftSuccess: ['shift'],
  fetchShiftFail: ['error'],

  setShift: ['shift'],

  showShift: ['shift'], // will handle one and many case(bulk)
  showShiftSuccess: ['shift'],
  showShiftFail: ['error'],

  fetchDefaultShift: null,
  fetchDefaultShiftDone: ['shifts'],
  fetchDefaultShiftFail: ['error'],

  updateShift: ['shift'],
  updateShiftDone: ['shift'],
  updateShiftFail: ['error'],

  deleteShift: ['shift'],
  deleteShiftDone: null,
  deleteShiftFail: ['error'],

  createShift: ['shift'],
  createShiftDone: ['shift'],
  createShiftFail: ['error'],

  fetchShiftEmployee: ['shift'],
  fetchShiftSuccessEmployee: ['shift'],
  fetchShiftFailEmployee: ['error'],

  fetchOvertimeShiftEmployee: ['shift'],
  fetchOvertimeShiftSuccessEmployee: ['shift'],
  fetchOvertimeShiftFailEmployee: ['error'],

  fetchOvertimeDateForShift: ['shift'],
  fetchOvertimeDateForShiftDone: ['dates'],
  fetchOvertimeDateForShiftFail: ['error'],

  assignShift: ['shift'],
  assignShiftDone: ['shift'],
  assignShiftFail: ['error'],

  acceptShiftAssignment: ['shift_id', 'status'],
  acceptShiftAssignmentDone: null,
  acceptShiftAssignmentFail: ['error'],

  createAttendance: ['shift'],
  createAttendanceDone: ['attendance'],
  createAttendanceFail: ['error'],

  breakAttendanceCompletion: ['attendance'],
  breakAttendanceCompletionDone: ['attendance'],
  breakAttendanceCompletionFail: ['error'],
});
