
import { createActions } from 'reduxsauce'

export const {Types, Creators} = createActions({
        
    createWeekwork: ['weekwork'],
    createWeekworkDone: ['weekwork'],
    createWeekworkFail: ['error'],
    
    updateWeekwork: ['weekwork'],
    updateWeekworkDone: ['weekwork'],
    updateWeekworkFail: ['error'],
    
    fetchWeekwork: [''],
    fetchWeekworkDone: ['weekwork'],
    fetchWeekworkFail: ['error'],
    
    deleteWeekwork: ['weekwork'],
    deleteWeekworkDone: ['weekwork'],
    deleteWeekworkFail: ['error']
    
});