
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {Types, Creators} from './actions'
// api import
import { 
    fetchAttendanceApi,
    fetchFinancialAttendanceApi,
    approveAttendanceApi,
    fetchFileAttendanceApi,
    editAttendanceApi,
    fetchTimesheetApi,
    fetchPayrollApi,
    fetchAllTimesheetApi,
    fetchAllPayrollApi,
    completeBreakApi,
    acceptAssignment
} from './api'
import { fetchTimesheet } from './reducers';
import { acceptAssignmentApi } from '../shifts/api';
// types import
const { 
    FETCH_ATTENDANCE, FETCH_FINANCE, APPROVE_ATTENDANCE, 
    FETCH_FILE_ATTENDANCE, EDIT_ATTENDANCE, FETCH_TIMESHEET,
    FETCH_PAYROLLS, FETCH_ALL_TIMESHEET, FETCH_ALL_PAYROLL, COMPLETE_BREAK, ACCEPT_ASSIGNMENT
} = Types
// success, fail import
const {
    fetchAttendanceDone,
    fetchAttendanceFail, 

    fetchFileAttendanceDone,
    fetchFileAttendanceFail, 

    fetchFinanceDone,
    fetchFinanceFail,
    
    approveAttendanceDone,
    approveAttendanceFail, 

    editAttendanceDone,
    editAttendanceFail, 
    
    completeBreakDone,
    completeBreakFail, 

    fetchTimesheetDone,
    fetchTimesheetFail,

    fetchAllTimesheetDone,
    fetchAllTimesheetFail,

    fetchAllPayrollDone,
    fetchAllPayrollFail,

    fetchPayrollsDone,
    fetchPayrollsFail,

    acceptAssignmentDone,
    acceptAssignmentFail
 } = Creators

// sagas handler

function* fetchAttendanceSaga(attendance){
    const response = yield call(fetchAttendanceApi, {start_date: attendance.time_interval[0], end_date: attendance.time_interval[1]});
    if (response.status){
        yield put(fetchAttendanceDone(response.data))
    }else{
        yield put(fetchAttendanceFail(response))
    }
}

function* fetchTimesheetSaga({file, start, end}){
    const response = yield call(fetchTimesheetApi,file, start, end );
    if (response.status){
        yield put(fetchTimesheetDone(response.data))
    }else{
        yield put(fetchTimesheetFail(response.error))
    }
}

function* fetchAllTimesheetSaga(){
    const response = yield call(fetchAllTimesheetApi);
    if (response.status){
        yield put(fetchAllTimesheetDone(response.data.results))
    }else{
        yield put(fetchAllTimesheetFail(response.error))
    }
}

function* fetchAllPayrollSaga(){
    const response = yield call(fetchAllPayrollApi);
    if (response.status){
        yield put(fetchAllPayrollDone(response.data.results))
    }else{
        yield put(fetchAllPayrollFail(response.error))
    }
}

function* fetchPayrollSaga({file, start, end}){
    
    const response = yield call(fetchPayrollApi, file, start, end );
    
    if (response.status){
        yield put(fetchPayrollsDone(response.data.results))
    }else{
        yield put(fetchPayrollsFail(response.error))
    }
}

function* fetchFileAttendanceSaga({file, date1, date2}){
    const response = yield call(fetchFileAttendanceApi, file, date1, date2 );
    
    if (response.status){
        yield put(fetchFileAttendanceDone(response.data.results))
    }else{
        yield put(fetchFileAttendanceFail(response.data))
    }
}

function* fetchFinancialAttendanceSaga(attendance){
    
    const response = yield call(fetchFinancialAttendanceApi, {start_date: attendance.time_interval[0], end_date: attendance.time_interval[1]});
    if (response.status){
        yield put(fetchFinanceDone(response.data))
    }else{
        yield put(fetchFinanceFail(response))
    }
}

function* approveAttendanceSaga({pk, attendance}){
    const response = yield call(approveAttendanceApi, pk, attendance)
    if (response.status){
        yield put(approveAttendanceDone())
    }else{
        yield put(approveAttendanceFail(response.data))
    }
}
    

function* editAttendanceSaga(attendance){
    const response = yield call(editAttendanceApi, attendance);
    
    if (response.status){
        yield put(editAttendanceDone(response))
    }else{
        yield put(editAttendanceFail(response))
    }
}

function* completeBreakSaga(attendance){
    const response = yield call(completeBreakApi, attendance);
    
    if (response.status){
        yield put(completeBreakDone(response))
    }else{
        yield put(completeBreakFail(response))
    }
}

function* acceptAssignmentSaga(){
    const response = yield call(acceptAssignment);
    
    if (response.status){
        yield put(acceptAssignmentDone(response))
    }else{
        yield put(acceptAssignmentFail(response))
    }
}
//saga watchers

export function* watchfetchAttendance(){
    yield takeEvery(FETCH_ATTENDANCE, fetchAttendanceSaga);
    yield takeEvery(FETCH_FILE_ATTENDANCE, fetchFileAttendanceSaga);
    yield takeEvery(FETCH_TIMESHEET, fetchTimesheetSaga);
    yield takeEvery(FETCH_ALL_TIMESHEET, fetchAllTimesheetSaga)
    yield takeEvery(FETCH_ALL_PAYROLL, fetchAllPayrollSaga)
    yield takeEvery(FETCH_PAYROLLS, fetchPayrollSaga)
}

export function* watchfetchFinancialAttendance(){
    yield takeEvery(FETCH_FINANCE, fetchFinancialAttendanceSaga)
}

export function* approveAttendance(){
    yield takeEvery(APPROVE_ATTENDANCE, approveAttendanceSaga)
    yield takeEvery(ACCEPT_ASSIGNMENT, acceptAssignmentSaga)
}
export function* editAttendance(){
    yield takeEvery(EDIT_ATTENDANCE, editAttendanceSaga)
    yield takeEvery(COMPLETE_BREAK, completeBreakSaga)
}   
// saga builder

export default function* attendanceSaga(){
    yield all([
        fork(watchfetchAttendance),
        fork(watchfetchFinancialAttendance),
        fork(approveAttendance),
        fork(editAttendance),

    ])
}