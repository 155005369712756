import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {Types, Creators} from './actions'
import Cookies  from 'universal-cookie'
// api import
import { 
    createDepartmentApi, 
    updateDepartmentApi, 
    fetchDepartmentApi, 
    deleteDepartmentApi,
    assignToDepartmentApi,
    importDepartment
} from './api'
// types import
const { CREATE_DEPARTMENT,UPDATE_DEPARTMENT,FETCH_DEPARTMENT,DELETE_DEPARTMENT, ASSIGN_DEPARTMENT, UPLOAD_DEPARTMENT} = Types
// success, fail import
const { 
    assignDepartmentDone,
    assignDepartmentFail,
    createDepartmentDone,
    createDepartmentFail, 
    updateDepartmentDone,
    updateDepartmentFail, 
    fetchDepartmentDone,
    fetchDepartmentFail, 
    deleteDepartmentDone,
    deleteDepartmentFail,
    fetchDepartment,
    uploadDepartmentDone,
    uploadDepartmentFail,
 } = Creators

// sagas handler

function* assignDeparmentSaga({department}){
    const response = yield call(assignToDepartmentApi, department);
    if (response.status){
        yield put(assignDepartmentDone(response.data))
    }else{
        yield put(assignDepartmentFail(response.data))
    }
}

function* createDepartmentSaga(department){
    const response = yield call(createDepartmentApi, department);
    if (response.status){
        yield put(createDepartmentDone(response.data))
        yield put(fetchDepartment());
    }else{
        yield put(createDepartmentFail(response.data))
    }
}

function* updateDepartmentSaga(department){
    const response = yield call(updateDepartmentApi, department);
    if (response.status){
        yield put(updateDepartmentDone(response.date))
        yield put(fetchDepartment());
    }else{
        yield put(updateDepartmentFail(response.data))
    }
}

function* fetchDepartmentSaga(){
    const response = yield call(fetchDepartmentApi);
    if (response.status){
        // let cookies = new Cookies()
       // cookies.set('departments', response.data, {path:"/"})
        yield put(fetchDepartmentDone(response.data.results))
    }else{
        yield put(fetchDepartmentFail(response.data))
    }
}

function* deleteDepartmentSaga(department){
    const response = yield call(deleteDepartmentApi, department);
    if (response.status){
        yield put(deleteDepartmentDone(response))
        yield put(fetchDepartment());

    }else{
        yield put(deleteDepartmentFail(response))
    }
}
    
function* uploadDepartmentSaga(department){
    const response = yield call(importDepartment, department);
    if (response.status){
        yield put(uploadDepartmentDone(response))
        yield put(fetchDepartment());
    }else{
        yield put(uploadDepartmentFail(response))
    }
}
//saga watchers

export function* watchcreateDepartment(){
    yield takeEvery(CREATE_DEPARTMENT, createDepartmentSaga);
    yield takeEvery(ASSIGN_DEPARTMENT, assignDeparmentSaga);
    yield takeEvery(UPLOAD_DEPARTMENT, uploadDepartmentSaga)
}

export function* watchupdateDepartment(){
    yield takeEvery(UPDATE_DEPARTMENT, updateDepartmentSaga)
}

export function* watchfetchDepartment(){
    yield takeEvery(FETCH_DEPARTMENT, fetchDepartmentSaga)
}

export function* watchdeleteDepartment(){
    yield takeEvery(DELETE_DEPARTMENT, deleteDepartmentSaga)
}


    
// saga builder

export default function* DepartmentSaga(){
    yield all([
        fork(watchcreateDepartment),
        fork(watchupdateDepartment),
        fork(watchfetchDepartment),
        fork(watchdeleteDepartment),
    ])
}