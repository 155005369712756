import Cookies from 'universal-cookie'; 
import {api, handle_response} from '../../helpers/api'
const cookies = new Cookies();

export async function fetchOvertimeApi(overtime = 0){
    const url = (overtime.overtime === 0 || overtime.overtime === null  || overtime.overtime === undefined ) ? '/overtime' : '/overtime/' + overtime
    return await api.get(url).then(handle_response)
}

export async function createOvertimeApi({overtime}){
    return await api.post('/overtime', {...overtime}).then(handle_response)
}


export async function updateOvertimeApi({overtime}){
    return await api.patch('/overtime/'+overtime.pk, {...overtime}).then(handle_response)
}


export async function deleteOvertimeApi(overtime_id){
    return await api.delete('/overtime/'+ overtime_id.overtime).then(handle_response)
}

export async function assignEmployeeApi({assignment}){
    return await api.post('/overtime-policy-assignment', assignment).then(handle_response)
}