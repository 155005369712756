

import { createReducer } from 'reduxsauce'
import { Types } from './actions'
import { normalizePto } from '../../helpers/utils'
const INITIAL_STATE = {
    error: null, ptos: [], loading: false, pto_list: {}, worker: null, ptoLocation: {},
    employeePtoLocation: {}, ptoTypes: [], assignEmployeeLoading: false,
    errorPtoType: null, userDetails: {}, createSuccess : false, createError: false, createPtoTypeSuccess : false, createPtoTypeError: false

}

// reducers here


// init case
export const createPTO = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        //pto: action.pto,
        loading: true,
        createSuccess : false, 
        createError: false
    }
}


// good case
export const createPTODone = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        //pto: action.pto,
        loading: false, 
        createSuccess : true, 
        createError: false
    }
}

// bad case
export const createPTOFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        //pto: null,
        error: action.error,
        loading: false,
        createSuccess : false, 
        createError: true
        // error: action.error
    }
}



// init case
export const updatePTO = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        //pto: action.pto,
        loading: true,
        createSuccess : false, 
        createError: false
    }
}


// good case
export const updatePTODone = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        // pto: action.pto,
        loading: false,
        createSuccess : true, 
        createError: false
    }
}


// bad case
export const updatePTOFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        // error: true,
        loading: false,
        error: action.error,
        createSuccess : false, 
        createError: true
    }
}



// init case
export const fetchPTO = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        // pto: action.pto,
        loading: true,
        createSuccess : false, 
        createError: false,
        createPtoTypeSuccess : false, createPtoTypeError: false

    }
}


// good case
export const fetchPTODone = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        ptos: normalizePto(action.pto.data),
        loading: false,
        createSuccess : false, 
        createError: false,
        createPtoTypeSuccess : false, createPtoTypeError: false

    }
}


// bad case
export const fetchPTOFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        error: action.error,
        createSuccess : false, 
        createError: false,
        createPtoTypeSuccess : false, createPtoTypeError: false

    }
}



// init case
export const deletePTO = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        // pto: action.pto,
        loading: true,
        createSuccess : false, createError: false
    }
}


// good case
export const deletePTODone = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        //pto: action.pto,
        loading: false,
        createSuccess : true, createError: false
    }
}


// bad case
export const deletePTOFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        //error: true,
        loading: false,
        error: action.error,
        createSuccess : false, createError: true
    }
}

export const fetchWorkerPTOList = (state = INITIAL_STATE, action) => {

    return {
        ...state,
        loading: true,
        createSuccess : false, createError: false
    }
}

export const fetchWorkerPTOListDone = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        pto_list: action.ptos,
        createSuccess : false, createError: false
    }
}

export const fetchWorkerPTOListFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        error: action.error,
        createSuccess : false, createError: false
    }
}
/**
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const ptoLocation = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        // loading: true,
    }
}
/**
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const ptoLocationDone = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        ptoLocation: action.ptoLocation
    }
}
/**
 * @param {*} state 
 * @param {*} action 
 */
export const ptoLocationFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        error: action.error
    }
}

/**
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const employeePtoLocation = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        //loading: true,
    }
}
/**
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const employeePtoLocationDone = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        employeePtoLocation: action.employeePtoLocation
    }
}
/**
 * @param {*} state 
 * @param {*} action 
 */
export const employeePtoLocationFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        error: action.error
    }
}
/**
 * 
 * @param {*} state 
 * @param {*} action 
 */
export const fetchDetailsWorker = (state = INITIAL_STATE, action) => {
    return { ...state, loading: true }
}

export const fetchDetailsWorkerDone = (state = INITIAL_STATE, action) => {
    return { ...state, worker: action.payload.data, loading: false }
}

export const fetchDetailsWorkerFail = (state = INITIAL_STATE, action) => {
    return { ...state, error: action.error, loading: false }
}

// init case
export const createPtoType = (state = INITIAL_STATE, action) => {


    return {
        ...state,
        //pto: action.pto,
        loading: true,
        createSuccess : false, createError: false,
        createPtoTypeSuccess : false, createPtoTypeError: false
    }
}


// good case
export const createPtoTypeDone = (state = INITIAL_STATE, action) => {

    return {
        ...state,
        //pto: action.pto,
        loading: false,
        createSuccess : false, createError: false,
        createPtoTypeSuccess : true, createPtoTypeError: false

    }
}


// bad case
export const createPtoTypeFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        //pto: null,
        error: action.error,
        loading: false,
        createSuccess : false, createError: false,
        createPtoTypeSuccess : false, createPtoTypeError: true

        // error: action.error
    }
}


// init case
export const updatePtoType = (state = INITIAL_STATE, action) => {


    return {
        ...state,
        //pto: action.pto,
        loading: true,
        createSuccess : false, createError: false
    }
}


// good case
export const updatePtoTypeDone = (state = INITIAL_STATE, action) => {

    return {
        ...state,
        //pto: action.pto,
        loading: false,
        createSuccess : false, createError: false
    }
}


// bad case
export const updatePtoTypeFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        //pto: null,
        error: action.error,
        loading: false,
        createSuccess : false, createError: false
        // error: action.error
    }
}


// init case
export const deletePtoType = (state = INITIAL_STATE, action) => {


    return {
        ...state,
        //pto: action.pto,
        loading: true
    }
}


// good case
export const deletePtoTypeDone = (state = INITIAL_STATE, action) => {

    return {
        ...state,
        //pto: action.pto,
        loading: false
    }
}


// bad case
export const deletePtoTypeFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        //pto: null,
        error: action.error,
        loading: false,
        // error: action.error
    }
}



// init case
export const fetchPtoType = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        // pto: action.ptoType,
        loading: true
    }
}


// good case
export const fetchPtoTypeDone = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        ptoTypes: action.ptoTypes.data.results,
        loading: false
    }
}


// bad case
export const fetchPtoTypeFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        errorPtoType: action.error
    }
}


export const assignEmployees = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: true,
        // assignEmployeeLoading: false,
    }
}

export const assignEmployeesDone = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        assignEmployeeLoading: true
    }
}

export const assignEmployeesFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        assignEmployeeLoading: false,
        error: action.error
    }
}


export const fetchUserDetails = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: true,
    }
}


export const fetchUserDetailsDone = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        userDetails: action.details,
    }
}

export const fetchUserDetailsFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loadin: false,
        error: action.error
    }
}

// handlers
export const HANDLERS = {

    [Types.CREATE_PTO]: createPTO,
    [Types.CREATE_PTO_DONE]: createPTODone,
    [Types.CREATE_PTO_FAIL]: createPTOFail,


    [Types.UPDATE_PTO]: updatePTO,
    [Types.UPDATE_PTO_DONE]: updatePTODone,
    [Types.UPDATE_PTO_FAIL]: updatePTOFail,


    [Types.FETCH_PTO]: fetchPTO,
    [Types.FETCH_PTO_DONE]: fetchPTODone,
    [Types.FETCH_PTO_FAIL]: fetchPTOFail,


    [Types.DELETE_PTO]: deletePTO,
    [Types.DELETE_PTO_DONE]: deletePTODone,
    [Types.DELETE_PTO_FAIL]: deletePTOFail,

    [Types.FETCH_WORKER_PTO_LIST]: fetchWorkerPTOList,
    [Types.FETCH_WORKER_PTO_LIST_DONE]: fetchWorkerPTOListDone,
    [Types.FETCH_WORKER_PTO_LIST_FAIL]: fetchWorkerPTOListFail,

    [Types.FETCH_DETAILS_WORKER]: fetchDetailsWorker,
    [Types.FETCH_DETAILS_WORKER_DONE]: fetchDetailsWorkerDone,
    [Types.FETCH_DETAILS_WORKER_FAIL]: fetchDetailsWorkerFail,

    [Types.FETCH_USER_DETAILS]: fetchUserDetails,
    [Types.FETCH_USER_DETAILS_DONE]: fetchUserDetailsDone,
    [Types.FETCH_USER_DETAILS_FAIL]: fetchUserDetailsFail,

    [Types.PTO_LOCATION]: ptoLocation,
    [Types.PTO_LOCATION_DONE]: ptoLocationDone,
    [Types.PTO_LOCATION_FAIL]: ptoLocationFail,

    [Types.EMPLOYEE_PTO_LOCATION]: employeePtoLocation,
    [Types.EMPLOYEE_PTO_LOCATION_DONE]: employeePtoLocationDone,
    [Types.EMPLOYEE_PTO_LOCATION_FAIL]: employeePtoLocationFail,

    [Types.CREATE_PTO_TYPE]: createPtoType,
    [Types.CREATE_PTO_TYPE_DONE]: createPtoTypeDone,
    [Types.CREATE_PTO_TYPE_FAIL]: createPtoTypeFail,

    [Types.UPDATE_PTO_TYPE]: updatePtoType,
    [Types.UPDATE_PTO_TYPE_DONE]: updatePtoTypeDone,
    [Types.UPDATE_PTO_TYPE_FAIL]: updatePtoTypeFail,

    [Types.DELETE_PTO_TYPE]: deletePtoType,
    [Types.DELETE_PTO_TYPE_DONE]: deletePtoTypeDone,
    [Types.DELETE_PTO_TYPE_FAIL]: deletePtoTypeFail,

    [Types.FETCH_PTO_TYPE]: fetchPtoType,
    [Types.FETCH_PTO_TYPE_DONE]: fetchPtoTypeDone,
    [Types.FETCH_PTO_TYPE_FAIL]: fetchPtoTypeFail,

    [Types.ASSIGN_EMPLOYEES]: assignEmployees,
    [Types.ASSIGN_EMPLOYEES_DONE]: assignEmployeesDone,
    [Types.ASSIGN_EMPLOYEES_FAIL]: assignEmployeesFail,

}

export default createReducer(INITIAL_STATE, HANDLERS)