import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    setWorkers: ['workers'],

    fetchWorker: ['worker', 'nopage'],
    fetchWorkerSuccess: ['payload'],
    fetchWorkerFail: ['error'],

    createBulkWorker: ['workers'],
    createBulkWorkerDone: ['worker'],
    createBulkWorkerFail: ['error'],

    createWorker: ['data'],
    createWorkerSuccess: ['worker'],
    createWorkerFail: ['error'],

    updateWorker: ['worker'],
    updateWorkerDone: ['data'],
    updateWorkerFail: ['error'],

    deleteWorker: ['worker'],
    deleteWorkerDone: ['data'],
    deleteWorkerFail: ['error'],

    uploadWorker: ['worker_obj'],
    uploadWorkerDone: ['obj'],
    uploadWorkerFail: ['error'],

    fetchDetailsWorker: ['worker'],
    fetchDetailsWorkerDone: ['payload'],
    fetchDetailsWorkerFail: ['error'],

    assignShiftWorker: ['shift'],
    assignShiftWorkerDone: ['payload'],
    assignShiftWorkerFail: ['error'],

    assignSalary: ['salary'],
    assignSalaryDone: null,
    assignSalaryFail: ['error'],

    getWorkerFileActivity: ['file', 'date1', 'date2'],
    getWorkerFileActivityDone: ['activity'],
    getWorkerFileActivityFail: ['error'],

    getWorkerFileActivityWorkWeek: ['file', 'date1', 'date2'],
    getWorkerFileActivityWorkWeekDone: ['activity'],
    getWorkerFileActivityWorkWeekFail: ['error'],

    fetchQRCodeToken: null,
    fetchQRCodeTokenDone: ['token'],
    fetchQRCodeTokenFail: ['error'],

    updateAvatar: ['avatar'],
    updateAvatarDone: ['data'],
    updateAvatarFail: ['error'],

    approveWorkers: ['worker'],
    approveWorkersDone: ['data'],
    approveWorkersFail: ['error'],

    approvedWorkers: null,
    approvedWorkersDone: ['data'],
    approvedWorkersFail: ['error'],

    approveeWorkers: ['worker'],
    approveeWorkersDone: ['data'],
    approveeWorkersFail: ['error'],

    transitFile: ['fileData'],
    transitFileDone: ['data'],
    transitFileFail: ['error'],

    haltEmployment: ['worker'],
    haltEmploymentDone: ['data'],
    haltEmploymentFail: ['error'],

    stopEmployment: ['worker', 'date'],
    stopEmploymentDone: ['data'],
    stopEmploymentFail: ['error'],
  },
  {}
);
