import Cookies from 'universal-cookie'; 
import {api, handle_response} from '../../helpers/api'
import _ from 'lodash'
const cookies = new Cookies();

export async function fetchRequirementApi(){
    
    //const url = _.isUndefined(requirement) ? '/requirements' : '/requirements/' + requirement
    return await api.get('/requirements').then(handle_response)
}

export async function createRequirementApi(requirement){
   // console.log(requirement)
    const url = '/requirements'
    return await api.post(url, {...requirement}).then(handle_response)
}

export async function deleteRequirementApi(requirement){
    return await api.delete('/requirements/'+ requirement.requirement).then(handle_response)
}

export async function updateRequirementApi({requirement}){
    return await api.patch('/requirement/'+requirement.pk, {...requirement}).then(handle_response)
}
