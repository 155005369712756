// @flow
import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import appMenuSaga from './appMenu/saga';
import workerSaga from './workers/saga';
import shiftSaga from './shifts/saga';
import OvertimeSaga from './overtime/saga';
import LocationSaga from './location/saga';
import ptoSaga from './pto/saga';
import AttendanceSaga from './attendance/sagas';
import LeaveSaga from './leaves/sagas';
import DeviceSage from './device/saga';
import CompanySaga from './company/saga';
import jobSaga from './job/sagas';
import DepartmentSaga from './department/sagas';
import ContractorSaga from './contractor/saga';
import RoleSaga from './roles/sagas';
import MarketplaceSaga from './marketplace/sagas';
import HolidaySaga from './holidays/sagas'
import PayrollSaga from './payroll/sagas'
import WeekWorkSaga from './weekWork/sagas'
import SearchMapSaga from './searchmap/sagas'
import DashboardSaga from './dashboard/saga'
import NotificationSaga from './notifications/sagas'
import ProcessingSaga from './processing/sagas'
import ProjectSaga from './project/sagas'
import RequirementSaga from './requirements/sagas'

export default function* rootSaga(getState) {
  yield all([
    authSaga(),
    layoutSaga(),
    workerSaga(),
    appMenuSaga(),
    shiftSaga(),
    OvertimeSaga(),
    LocationSaga(),
    ptoSaga(),
    AttendanceSaga(),
    LeaveSaga(),
    DeviceSage(),
    CompanySaga(),
    jobSaga(),
    DepartmentSaga(),
    ContractorSaga(),
    RoleSaga(),
    MarketplaceSaga(),
    HolidaySaga(),
    PayrollSaga(),
    WeekWorkSaga(),
    SearchMapSaga(),
    DashboardSaga(),
    NotificationSaga(), 
    ProcessingSaga(),
    ProjectSaga(),
    RequirementSaga()
  ]);
}
