
import { createActions } from 'reduxsauce'

export const {Types, Creators} = createActions({
        
    createDepartment: ['department'],
    createDepartmentDone: ['department'],
    createDepartmentFail: ['error'],
    
    updateDepartment: ['department'],
    updateDepartmentDone: ['department'],
    updateDepartmentFail: ['error'],
    
    fetchDepartment: [''],
    fetchDepartmentDone: ['departments'],
    fetchDepartmentFail: ['error'],
    
    deleteDepartment: ['department'],
    deleteDepartmentDone: ['department'],
    deleteDepartmentFail: ['error'],

    assignDepartment: ['department'],
    assignDepartmentDone: null,
    assignDepartmentFail: ['error'],
    
    uploadDepartment: ['department_obj'],
    uploadDepartmentDone: ['obj'],
    uploadDepartmentFail: ['error'],
});
    