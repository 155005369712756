 
import { createReducer } from 'reduxsauce'
import {Types} from './actions'
const INITIAL_STATE = {
error: null, company: [], loading: false, subscription : [], billingPlans : [], current_company : {}, myCompanies : [],
loadingCompany : false,loadingMyCompany : false, loadCompaniesSuccess : false,loadMyCompanySuccess : false, loadCompaniesError : null
}

// reducers here


// init case
export const createCompagny = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        //company: action.company,
        loading: true
    }
}


// good case
export const createCompagnyDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false
    }
}


// bad case
export const createCompagnyFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        error: action.error
    }
}


// init case
export const updateCompany = (state = INITIAL_STATE, action ) => {
    return {
        //...state,
       // company: action.company,
        loading: true
    }
}


// good case
export const updateCompanyDone = (state = INITIAL_STATE, action ) => {
    
    return {
       // ...state,
        //company: action.company,
        loading: false
    }
}


// bad case
export const updateCompanyFail = (state = INITIAL_STATE, action ) => {
    return {
        //...state,
        error: true,
        loading: false,
       // error: action.error
    }
}



// init case
export const fetchCompany = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true,
        //company: action.company
    }
}


// good case
export const fetchCompanyDone = (state = INITIAL_STATE, action ) => {
    return {
        //...state,
        loading: false,
        company: action.company.results
    }
}


// bad case
export const fetchCompanyFail = (state = INITIAL_STATE, action ) => {
    return {
        //...state,
        loading: false,
        error: action.error
    }
}
// init case
export const getCompany = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true,
        //company: action.company
    }
}


// good case
export const getCompanyDone = (state = INITIAL_STATE, action ) => {
    return {
        //...state,
        loading: false,
        current_company: action.company
    }
}


// bad case
export const getCompanyFail = (state = INITIAL_STATE, action ) => {
    return {
        //...state,
        loading: false,
        error: action.error
    }
}


// init case
export const deleteCompany = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        //company: action.company,
        loading: true
    }
}


// good case
export const deleteCompanyDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        //company: action.company,
        loading: false
    }
}


// bad case
export const deleteCompanyFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        //error: true,
        loading: false,
        error: action.error
    }
}
   
// init case
export const fetchSubscription = (state = INITIAL_STATE, action ) => {
    return {
       // ...state,
       // subscription: action.subscription,
      //  loading: true
    }
}


// good case
export const fetchSubscriptionDone = (state = INITIAL_STATE, action ) => {
    return {
       // ...state,
        subscription: action.subscription,
        loading: false
    }
}


// bad case
export const fetchSubscriptionFail = (state = INITIAL_STATE, action ) => {
    return {
        //...state,
       // subscription: null,
        //error: true,
        loading: false,
        error: action.error
    }
}

 
// init case
export const fetchBillingPlan = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
       // BillingPlan: action.BillingPlan,
       // loading: true
    }
}


// good case
export const fetchBillingPlanDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        billingPlan: action.billingPlan,
        loading: false
    }
}


// bad case
export const fetchBillingPlanFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        //error: true,
        loading: false,
        error: action.error
    }
}

export const getMyCompanies = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loadingCompany : true,
    }
}

export const getMyCompaniesDone = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loadingCompany : false,
        loadCompaniesSuccess : true,
        loadCompaniesError : null,
        myCompanies : action.companies
    }
}

export const getMyCompaniesFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loadingCompany : false,
        loadCompaniesSuccess : false,
        loadCompaniesError : action.error
    }
}
export const getCurrentCompany = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loadingMyCompany : true,
        loadCompaniesSuccess : false,
        loadMyCompanySuccess : false,
        loadCompaniesError : null
    }
} 
export const getCurrentCompanyDone = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loadingMyCompany : false,
        loadCompaniesError : null,
        loadMyCompanySuccess : true,
        current_company : action.company
    }
}
export const getCurrentCompanyFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loadingMyCompany : false,
        loadMyCompanySuccess : false,
        loadCompaniesError : action.error,
    }
} 
    // handlers
export const HANDLERS = {
    
    /*[Types.CREATE_COMPANY]: createCompany,
    [Types.CREATE_COMPANY_DONE]: createCompanyDone,
    [Types.CREATE_COMPANY_FAIL]: createCompanyFail,*/
    
    [Types.UPDATE_COMPANY]: updateCompany,
    [Types.UPDATE_COMPANY_DONE]: updateCompanyDone,
    [Types.UPDATE_COMPANY_FAIL]: updateCompanyFail,

    [Types.FETCH_COMPANY]: fetchCompany,
    [Types.FETCH_COMPANY_DONE]: fetchCompanyDone,
    [Types.FETCH_COMPANY_FAIL]: fetchCompanyFail,

    [Types.GET_COMPANY]: getCompany,
    [Types.GET_COMPANY_DONE]: getCompanyDone,
    [Types.GET_COMPANY_FAIL]: getCompanyFail,
    
    [Types.DELETE_COMPANY]: deleteCompany,
    [Types.DELETE_COMPANY_DONE]: deleteCompanyDone,
    [Types.DELETE_COMPANY_FAIL]: deleteCompanyFail,
    
    [Types.FETCH_SUBSCRIPTION]: fetchSubscription,
    [Types.FETCH_SUBSCRIPTION_DONE]: fetchSubscriptionDone,
    [Types.FETCH_SUBSCRIPTION_FAIL]: fetchSubscriptionFail,

    [Types.FETCH_BILLING_PLAN]: fetchBillingPlan,
    [Types.FETCH_BILLING_PLAN_DONE]: fetchBillingPlanDone,
    [Types.FETCH_BILLING_PLAN_FAIL]: fetchBillingPlanFail,

    [Types.GET_MY_COMPANIES]: getMyCompanies,
    [Types.GET_MY_COMPANIES_DONE]: getMyCompaniesDone,
    [Types.GET_MY_COMPANIES_FAIL]: getMyCompaniesFail,

    [Types.GET_CURRENT_COMPANY]: getCurrentCompany,
    [Types.GET_CURRENT_COMPANY_DONE]: getCurrentCompanyDone,
    [Types.GET_CURRENT_COMPANY_FAIL]: getCurrentCompanyFail,
}
export default createReducer(INITIAL_STATE, HANDLERS)
