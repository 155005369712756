// @flow

import { combineReducers } from 'redux';
import Layout from './layout/reducers';
import Auth from './auth/reducers';
import AppMenu from './appMenu/reducers';
import Worker from './workers/reducers';
import Shift from './shifts/reducers';
import overtime from './overtime/reducers';
import pto from './pto/reducers';
import Location from './location/reducers';
import Attendance from './attendance/reducers';
import Leave from './leaves/reducers';
import device from './device/reducers';
import Company from './company/reducers';
import Department from './department/reducers';
import Job from './job/reducers';
import Contractor from './contractor/reducers';
import Role from './roles/reducers';
import Marketplace from './marketplace/reducers';
import Holiday from './holidays/reducers'
import WeekWork from './weekWork/reducers'
import Payroll from './payroll/reducers'
import SearchMap from './searchmap/reducers'
import Dashboard from './dashboard/reducers'
import Notification from './notifications/reducers'
import Processing from './processing/reducers'
import Project from './project/reducers'
import Requirement from './requirements/reducers'

export default combineReducers({
  Auth,
  AppMenu,
  Layout,
  Worker,
  overtime,
  Shift,
  Location,
  pto,
  Attendance,
  Leave,
  device,
  Company,
  Department,
  Job,
  Contractor,
  Role,
  Marketplace,
  Holiday,
  Payroll,
  WeekWork,
  SearchMap,
  Dashboard,
  Notification,
  Processing,
  Project,
  Requirement
});
