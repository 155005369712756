
import { createActions } from 'reduxsauce'

export const {Types, Creators} = createActions({
        
    createHoliday: ['holiday'],
    createHolidayDone: ['holiday'],
    createHolidayFail: ['error'],
    
    updateHoliday: ['holiday'],
    updateHolidayDone: ['holiday'],
    updateHolidayFail: ['error'],
    
    fetchHoliday: [''],
    fetchHolidayDone: ['holiday'],
    fetchHolidayFail: ['error'],
    
    deleteHoliday: ['holiday'],
    deleteHolidayDone: ['holiday'],
    deleteHolidayFail: ['error'],

    assignEmployeesToHoliday: ['assignment'],
    assignEmployeesToHolidayDone: null,
    assignEmployeesToHolidayFail: ['error'],
});
    