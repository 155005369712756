import Cookies from 'universal-cookie'; 
import {api, handle_response} from '../../helpers/api'
const cookies = new Cookies();

export async function fetchContract(){
    return await api.get('/contract').then(handle_response)
}

export async function fetchDefaultContractApi(action){
    return await api.get('/contract').then(handle_response)
}

export async function createContract(contract){
    
    ///api.setHeader('Tenant', cookies.get('tenant'));
    return await api.post('/contract', {...contract}).then(handle_response)
}


export async function updateContract({contract}){
    //api.setHeader('Tenant', cookies.get('tenant'));
    return await api.patch('/contract/' + contract.pk, {...contract}).then(handle_response)
}


export async function deleteContract(contract_id){
    //api.setHeader('Tenant', cookies.get('tenant'));
    return await api.delete('/contract/'+ contract_id).then(handle_response)
}

export async function fetchContractEmployee(employee_pk){
    const url =  '/contract?start=2020-01-01&end=2020-12-31&employees=' + employee_pk.contract
    return await api.get(url).then(handle_response)
}
export async function fetchMarketPlace(){
    return await api.get('/marketplace').then(handle_response)
}