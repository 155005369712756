import { defineAbility } from '@casl/ability';
import _ from 'lodash';

import { getLoggedInUser, mergeAllPermissions } from './authUtils';

export default defineAbility((can, cannot) => {
  const user = getLoggedInUser();
  if (_.isEmpty(user)) {
    cannot('manage', 'all');
    return;
  }

  /**
   * @type {{[key: string]: string[]}}
   */
  const permissions = mergeAllPermissions();
  _.forEach(permissions, (v, k) => {
    v.forEach((i) => {
      const [action, subject] = i.split('_', 2);
      can(action, subject);
    });
  });
});
