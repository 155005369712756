import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {Types, Creators} from './actions'
// api import
import {
    createLocationApi,
    updateLocationApi,
    fetchLocationApi,
    deleteLocationApi,
    assignEmployeeApi,
    importLocation
} from './api'
import {getAllRates} from '../../helpers/api'
// types import
const { CREATE_LOCATION,UPDATE_LOCATION,FETCH_LOCATION,DELETE_LOCATION, FETCH_RATES, ASSIGN_EMPLOYEES_LOCATION, UPLOAD_LOCATION } = Types
// success, fail import
const {
    createLocationDone,
    createLocationFail,
    updateLocationDone,
    updateLocationFail,
    deleteLocationDone,
    deleteLocationFail,
    fetchLocation,
    fetchLocationFail,

    fetchRatesDone,
    fetchRatesFail,

    assignEmployeesLocationDone,
    assignEmployeesLocationFail,

    uploadLocationDone,
    uploadLocationFail
 } = Creators

// sagas handler


function* fetchRates(action){
    const response = yield call(getAllRates);
    const {status, data} = response
    if (status){
        yield put(fetchRatesDone(data))
    }else{
        yield put(fetchRatesFail(data))
    }
}

function* createLocationSaga(location){
    const response = yield call(createLocationApi, location);
    if (response.status){
        yield put(createLocationDone())
        yield put(fetchLocation())
    }else{
        yield put(createLocationFail(response))
    }
}

function* updateLocationSaga(location){
    const response = yield call(updateLocationApi, location);
    if (response.status){
        yield put(updateLocationDone())
        yield put(fetchLocation())

    }else{
        yield put(updateLocationFail(response.data))
    }
}

function* fetchLocationSaga(location){
    
    const response = yield call(fetchLocationApi, location.location);
    if (response.status){
        
        yield put(Creators.fetchLocationDone(response.data))
        yield put(Creators.fetchRates()) // fetch the rates here 
    }else{
        yield put(fetchLocationFail(response))
    }
}

function* deleteLocationSaga(location){
    const response = yield call(deleteLocationApi, location);
    if (response.status){
        yield put(deleteLocationDone(response))
        yield put(fetchLocation())
    }else{
        yield put(deleteLocationFail(response))
    }
}


function* assignEmployeeSaga(assignment) {
    const response = yield call(assignEmployeeApi, assignment);
    if(response.status){
        yield put(assignEmployeesLocationDone(response.data));
    }else{
        yield put(assignEmployeesLocationFail(response.data))
    }
}

function* uploadLocationSaga(location){
    const response = yield call(importLocation, location);
    if (response.status){
        yield put(fetchLocation())
    }else{
        yield put(uploadLocationFail(response.data))
    }
}
//saga watchers

export function* watchcreateLocation(){
    yield takeEvery(CREATE_LOCATION, createLocationSaga);
    yield takeEvery(UPLOAD_LOCATION, uploadLocationSaga)
}

export function* watchupdateLocation(){
    yield takeEvery(UPDATE_LOCATION, updateLocationSaga)
}

export function* watchfetchLocation(){
    yield takeEvery(FETCH_LOCATION, fetchLocationSaga)
}
export function* watchAssignEMployee(){
    yield takeEvery(ASSIGN_EMPLOYEES_LOCATION, assignEmployeeSaga)
}
export function* watchdeleteLocation(){
    yield takeEvery(DELETE_LOCATION, deleteLocationSaga)
}

export function* watchfetchRate(){
    yield takeEvery(FETCH_RATES, fetchRates)
}
// saga builder

export default function* locationSaga(){
    yield all([
        fork(watchcreateLocation),
        fork(watchupdateLocation),
        fork(watchfetchLocation),
        fork(watchdeleteLocation),
        fork(watchfetchRate),
        fork(watchAssignEMployee)
    ])
}
