import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchNotifications: ['notifications'],
  fetchNotificationsDone: ['notifications'],
  fetchNotificationsFail: ['error'],

  showNotification: ["notification"],
  showNotificationDone: ['notification'],
  showNotificationFail: ['error'],

});
