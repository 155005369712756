import Cookies from 'universal-cookie'; 
import {api, handle_response} from '../../helpers/api'


export async function computationPayrollApi(payroll){
    return await api.post('/pay-manual-exportation', payroll.payroll ).then(handle_response)
}
export async function computationTimeSheetApi(timeSheet){
   return await api.post('/timesheet-manual-exportation', timeSheet.timesheet).then(handle_response)
}
export async function computationResetPayrollApi(worker){
    return await api.delete('/flush-payroll', worker).then(handle_response)
}
export async function computationResetTimeSheetApi({worker}){
   return await api.delete('/flush-timesheet', {...worker}).then(handle_response)
}
