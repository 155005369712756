import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {Types, Creators} from './actions'
// api import
import { 
    createCompanyApi, 
    updateCompanyApi, 
    fetchCompanyApi, 
    deleteCompanyApi,
    fetchSubscriptionApi,
    fetchBillingPlanApi,
    getCompanyApi,
    getCurrentCompanyApi,
    // getMyCompaniesApi
} from './api'
import Cookies from 'universal-cookie';
import { splitCookies } from '../../helpers/authUtils';
import {setSession} from '../auth/saga'
import _ from 'lodash'
// import { api } from '../../helpers/api';
// types import
const { CREATE_COMPAGNY,UPDATE_COMPANY,FETCH_COMPANY,DELETE_COMPANY, FETCH_SUBSCRIPTION, FETCH_BILLING_PLAN, GET_COMPANY, GET_CURRENT_COMPANY,  GET_MY_COMPANIES} = Types
// success, fail import
const { createCompagnyDone,
    createCompagnyFail, 
    updateCompanyDone,
    updateCompanyFail, 
    fetchCompany,
    fetchCompanyDone,
    fetchCompanyFail, 
    deleteCompanyDone,
    deleteCompanyFail,
    // fetchSubscription,
    fetchSubscriptionDone,
    fetchSubscriptionFail, 
    fetchBillingPlanDone,
    // fetchBillingPlanFail,
    getCompanyDone,
    getCompanyFail,
    getCurrentCompanyDone,
    getCurrentCompanyFail,
    getMyCompaniesDone,
    getMyCompaniesFail
 } = Creators

// sagas handler
const cookies = new Cookies()
function* createCompanySaga(company){
    const response = yield call(createCompanyApi, company);
    if (response.status){
        yield put(createCompagnyDone());
        yield put(fetchCompany());// update Company list
    }else{
        yield put(createCompagnyFail(response))
    }
}

function* updateCompanySaga(company){
    const response = yield call(updateCompanyApi, company);
    if (response.status){
        yield put(updateCompanyDone(response))
        const cookies = new Cookies()
        cookies.set("company", response.data, {path: "/"})
        //yield put(fetchCompany());
        
    }else{
        yield put(updateCompanyFail(response))
    }
}

function* fetchCompanySaga(company){
    const response = yield call(fetchCompanyApi, company);

    if (response.status){
        yield put(fetchCompanyDone(response.data))
    }else{
        yield put(fetchCompanyFail(response))
    }
}
function* getCompanySaga(company){
    const response = yield call(getCompanyApi, company);
    if (response.status){
        yield put(getCompanyDone(response.data))
    }else{
        yield put(getCompanyFail(response))
    }
}
function* deleteCompanySaga(company){
    const response = yield call(deleteCompanyApi, company);
    if (response.status){
        yield put(deleteCompanyDone(response))
        yield put(fetchCompany());

    }else{
        yield put(deleteCompanyFail(response))
    }
}

function* fetchSubscriptionSaga(){
    const response = yield call(fetchSubscriptionApi);
    if (response.status){
        yield put(fetchSubscriptionDone(response.data.results))
    }else{
        yield put(fetchSubscriptionFail(response.data))
    }
}

function* fetchBillingPlanSaga(){
    const response = yield call(fetchBillingPlanApi);
    if (response.status){
        yield put(fetchBillingPlanDone(response.data.results))
    }else{
        yield put(fetchSubscriptionFail(response.data))
    }
}
//saga watchers
function* getCurrentCompanySaga(company){
    const response = yield call(getCurrentCompanyApi, company);
    if (response.status){
        const user = {
            access: cookies.get('user').access,
            refresh: cookies.get('user').refresh,
            user: {
                id: cookies.get('user').user.id,
                first_name: cookies.get('user').user.first_name,
                last_name: cookies.get('user').user.last_name,
                email: cookies.get('user').user.email,
                unique_id: cookies.get('user').user.unique_id,
                companies: [ ...cookies.get('user').user.companies ],
                company : _.omit(response.data, ['permissions'])
            },
            role: 'Admin',
        }      
     
        setSession(user)
        splitCookies(response.data.permissions);
        yield put(getCurrentCompanyDone(response.data))
        window.location.href = "/"
    }else{
        yield put(getCurrentCompanyFail(response))
    }
}
function* getMyCompaniesSaga(){
    const response = yield call(getMyCompaniesSaga);
    if (response.status){
        yield put(getMyCompaniesDone(response.data))
    }else{
        yield put(getMyCompaniesFail(response))
    }
}

export function* watchcreateCompany(){
    yield takeEvery(CREATE_COMPAGNY, createCompanySaga)
}

export function* watchupdateCompany(){
    yield takeEvery(UPDATE_COMPANY, updateCompanySaga)
}

export function* watchfetchCompany(){
    yield takeEvery(FETCH_COMPANY, fetchCompanySaga)
}
export function* watchGetCompany(){
    yield takeEvery(GET_COMPANY, getCompanySaga)
    yield takeEvery(GET_CURRENT_COMPANY, getCurrentCompanySaga)
    yield takeEvery(GET_MY_COMPANIES, getMyCompaniesSaga)
}
export function* watchdeleteCompany(){
    yield takeEvery(DELETE_COMPANY, deleteCompanySaga)
}
export function* watchfetchSubscription(){
    yield takeEvery(FETCH_SUBSCRIPTION, fetchSubscriptionSaga)
}

export function* watchfetchBillingPlan(){
    yield takeEvery(FETCH_BILLING_PLAN, fetchBillingPlanSaga)
}
// saga builder

export default function* CompanySaga(){
    yield all([
        //fork(watchcreateCompany),
        fork(watchupdateCompany),
        fork(watchfetchCompany),
        fork(watchdeleteCompany),
        fork(watchfetchSubscription),
        fork(watchfetchBillingPlan),
        fork(watchGetCompany),
    ])
}
    


    