import Cookies from 'universal-cookie'; 
import {api, handle_response} from '../../helpers/api'
const cookies = new Cookies();


export async function fetchWeekworkApi(){
    let url = '/week-work'
    return await api.get(url).then(handle_response)
}
export async function createWeekworkApi({weekwork}){
    api.setHeader('Tenant', cookies.get('user').user.company.domains[0].domain);
   api.setHeader('Authorization', 'Bearer '+ cookies.get('user').access);
   return await api.post('/week-work', {...weekwork}).then(handle_response)
}


export async function updateWeekworkApi({weekwork}){
    api.setHeader('Tenant', cookies.get('user').user.company.domains[0].domain);
   api.setHeader('Authorization', 'Bearer '+ cookies.get('user').access);
   return await api.patch('/week-work/'+weekwork.id, {...weekwork}).then(handle_response)
}


export async function deleteWeekworkApi(weekwork){
    api.setHeader('Tenant', cookies.get('user').user.company.domains[0].domain);
   api.setHeader('Authorization', 'Bearer '+ cookies.get('user').access);
   return await api.delete('/week-work/'+ weekwork.weekwork).then(handle_response)
}
