
import { createActions } from 'reduxsauce'

export const {Types, Creators} = createActions({
        
    /*createCompany: ['company'],
    createCompanyDone: ['company'],
    createCompanyFail: ['error'],*/
    
    updateCompany: ['company'],
    updateCompanyDone: ['company'],
    updateCompanyFail: ['error'],
    
    fetchCompany: ['company'],
    fetchCompanyDone: ['company'],
    fetchCompanyFail: ['error'],
    
    getCompany: ['company'],
    getCompanyDone: ['company'],
    getCompanyFail: ['error'],

    deleteCompany: ['company'],
    deleteCompanyDone: ['company'],
    deleteCompanyFail: ['error'],

    fetchSubscription : null,
    fetchSubscriptionDone : ['subscription'],
    fetchSubscriptionFail : ['error'],
    
    fetchBillingPlan : null,
    fetchBillingPlanDone : ['billing'],
    fetchBillingPlanFail : ['error'],

    getMyCompanies : null,
    getMyCompaniesDone : ['companies'],
    getMyCompaniesFail : ['error'],
    
    getCurrentCompany : ['company'],
    getCurrentCompanyDone : ['company'],
    getCurrentCompanyFail : ['error']
});
    