import React, { Component } from 'react';
import Routes from './routes/Routes';

// CASL setup
import { AbilityContext } from './components/Can';
import ability from './helpers/ability';


// Themes

// default
import './assets/scss/theme.scss';

// dark
import './assets/scss/theme-dark.scss';

// rtl
// import './assets/scss/theme-rtl.scss';

// animate.css
import 'animate.css/animate.min.css';

// configure fake backend
// configureFakeBackend();

/**
 * Main app component
 */
class App extends Component {
  render() {
    return (
      <AbilityContext.Provider value={ability}>
        <Routes />
      </AbilityContext.Provider>
    );
  }
}

export default App;
