import { api, handle_response } from '../../helpers/api'

export async function fetchLeaveApi(){
    return await api.get('/leaves').then(handle_response)
}

export async function fetchLeaveToValidateApi(){
    return await api.get('/leaves-to-validate').then(handle_response)
}

export async function acceptLeaveApi(id){
    return await api.patch('/leave-validation/'+id, {'status': "ACCEPTED"}).then(handle_response)
}

export async function rejectLeaveApi(id){
    return await api.patch('/leave-validation/'+id, {'status': "REJECTED"}).then(handle_response)
}

export async function createLeaveApi({leave}) {
    return await api.post('/leaves', {...leave}).then(handle_response)
}
export async function editLeaveApi({leave}) {
    return await api.patch('/leaves/'+leave.pk, {...leave}).then(handle_response)
}
export async function deleteLeaveApi({leave}) {
    return await api.delete('/leaves/'+leave.pk).then(handle_response)
}
export async function fetchEmployeeLeaveApi() {
    return await api.get('/pto-employee').then(handle_response)
}

