import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {Types, Creators} from './actions'
import Cookies  from 'universal-cookie'
// api import
import { 
createPayrollApi, 
updatePayrollApi, 
fetchPayrollApi, 
deletePayrollApi
} from './api'
// types import
const { CREATE_PAYROLL,UPDATE_PAYROLL,FETCH_PAYROLL,DELETE_PAYROLL} = Types
// success, fail import
const { 
    createPayrollDone,
    createPayrollFail, 
    updatePayrollDone,
    updatePayrollFail, 
    fetchPayrollDone,
    fetchPayrollFail, 
    deletePayrollDone,
    deletePayrollFail,
    fetchPayroll
 } = Creators

// sagas handler

function* createPayrollSaga(payroll){
    const response = yield call(createPayrollApi, payroll);
    if (response.status){
        yield put(createPayrollDone(response))
        yield put(fetchPayroll());
    }else{
        yield put(createPayrollFail(response))
    }
}

function* updatePayrollSaga(payroll){
    const response = yield call(updatePayrollApi, payroll);
    if (response.status){
     //   yield put(updatePayrollDone(response))
        yield put(fetchPayroll());
    }else{
        yield put(updatePayrollFail(response))
    }
}

function* fetchPayrollSaga(){
    const response = yield call(fetchPayrollApi);
    if (response.status){
        //let cookies = new Cookies()
       // cookies.set('departments', response.data, {path:"/"})
        yield put(fetchPayrollDone(response.data))
    }else{
        yield put(fetchPayrollFail(response))
    }
}

function* deletePayrollSaga(payroll){
    const response = yield call(deletePayrollApi, payroll);
    if (response.status){
        yield put(deletePayrollDone(response))
        yield put(fetchPayroll());

    }else{
        yield put(deletePayrollFail(response))
    }
}
    
//saga watchers

export function* watchcreatePayroll(){
    yield takeEvery(CREATE_PAYROLL, createPayrollSaga)
}

export function* watchupdatePayroll(){
    yield takeEvery(UPDATE_PAYROLL, updatePayrollSaga)
}

export function* watchfetchPayroll(){
    yield takeEvery(FETCH_PAYROLL, fetchPayrollSaga)
}

export function* watchdeletePayroll(){
    yield takeEvery(DELETE_PAYROLL, deletePayrollSaga)
}
    
// saga builder

export default function* PayrollSaga(){
    yield all([
        fork(watchcreatePayroll),
        fork(watchupdatePayroll),
        fork(watchfetchPayroll),
        fork(watchdeletePayroll),
    ])
}