import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {Types, Creators} from './actions'
import Cookies  from 'universal-cookie'
// api import
import { 
createJobApi, 
updateJobApi, 
fetchJobApi, 
deleteJobApi, 
assignWorkerPosition,
importJob
} from './api'
// types import assignWorkerToPosition
const { CREATE_JOB,UPDATE_JOB,FETCH_JOB,DELETE_JOB, ASSIGN_WORKER_TO_POSITION, UPLOAD_JOB } = Types
// success, fail import
const { 
    createJobDone,
    createJobFail, 
    updateJobDone,
    updateJobFail, 
    fetchJobDone,
    fetchJobFail, 
    deleteJobDone,
    deleteJobFail,
    fetchJob ,
    uploadJobDone,
    uploadJobFail,
    assignWorkerToPositionDone,
    assignWorkerToPositionFail

 } = Creators

// sagas handler

function* createJobSaga(job){
    const response = yield call(createJobApi, job);
    if (response.status){
        yield put(createJobDone(response))
        yield put(fetchJob());
    }else{
        yield put(createJobFail(response))
    }
}

function* updateJobSaga(job){
    const response = yield call(updateJobApi, job);
    if (response.status){
        yield put(updateJobDone(response))
        yield put(fetchJob());
    }else{
        yield put(updateJobFail(response))
    }
}

function* fetchJobSaga(job){
    const response = yield call(fetchJobApi, job);    
    if (response.status){
        yield put(fetchJobDone(response.data.results))
    }else{
        yield put(fetchJobFail(response))
    }
}

function* deleteJobSaga(job){
    const response = yield call(deleteJobApi, job);
    if (response.status){
        yield put(deleteJobDone(response))
        yield put(fetchJob());

    }else{
        yield put(deleteJobFail(response))
    }
}

function* assignPositionSaga(position){
    const response = yield call(assignWorkerPosition, position);
    if (response.status){
        yield put(assignWorkerToPositionDone(response.data));
    }else{
        yield put(assignWorkerToPositionFail(response.data));
    }
}
function* uploadJobSaga(job){
    const response = yield call(importJob, job);
    if (response.status){
        yield put(uploadJobDone(response))
        yield put(fetchJob());
    }else{
        yield put(uploadJobFail(response))
    }
}  
//saga watchers

export function* watchcreateJob(){
    yield takeEvery(ASSIGN_WORKER_TO_POSITION, assignPositionSaga)
    yield takeEvery(CREATE_JOB, createJobSaga)
    yield takeEvery(UPLOAD_JOB, uploadJobSaga)
}

export function* watchupdateJob(){
    yield takeEvery(UPDATE_JOB, updateJobSaga)
}

export function* watchfetchJob(){
    yield takeEvery(FETCH_JOB, fetchJobSaga)
}

export function* watchdeleteJob(){
    yield takeEvery(DELETE_JOB, deleteJobSaga)
}
    
// saga builder

export default function* jobSaga(){
    yield all([
        fork(watchcreateJob),
        fork(watchupdateJob),
        fork(watchfetchJob),
        fork(watchdeleteJob),

    ])
}