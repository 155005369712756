
import { createReducer } from 'reduxsauce'
import {Types} from './actions'
const INITIAL_STATE = {
error: null, weekworks: [], loading: false
}

// reducers here
// init case
export const createWeekwork = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true,
    }
}


// good case
export const createWeekworkDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
    }
}


// bad case
export const createWeekworkFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        error: action.error
    }
}



// init case
export const updateWeekwork = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true
    }
}


// good case
export const updateWeekworkDone = (state = INITIAL_STATE, action ) => {
    
    return {
        ...state,
        loading: false
    }
}


// bad case
export const updateWeekworkFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
       // weekworks: null,
        error: action.error,
        loading: false,
    }
}


// init case
export const fetchWeekwork = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true,
    }
}


// good case
export const fetchWeekworkDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        weekworks: action.weekwork
    }
}


// bad case
export const fetchWeekworkFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        weekworks: null,
        loading: false,
        error: action.error
    }
}


// init case
export const deleteWeekwork = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true
    }
}


// good case
export const deleteWeekworkDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false
    }
}


// bad case
export const deleteWeekworkFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        error: action.error
    }
}
    // handlers
export const HANDLERS = {
    
    [Types.CREATE_WEEKWORK]: createWeekwork,
    [Types.CREATE_WEEKWORK_DONE]: createWeekworkDone,
    [Types.CREATE_WEEKWORK_FAIL]: createWeekworkFail,
    
    [Types.UPDATE_WEEKWORK]: updateWeekwork,
    [Types.UPDATE_WEEKWORK_DONE]: updateWeekworkDone,
    [Types.UPDATE_WEEKWORK_FAIL]: updateWeekworkFail,
    
    [Types.FETCH_WEEKWORK]: fetchWeekwork,
    [Types.FETCH_WEEKWORK_DONE]: fetchWeekworkDone,
    [Types.FETCH_WEEKWORK_FAIL]: fetchWeekworkFail,

    [Types.DELETE_WEEKWORK]: deleteWeekwork,
    [Types.DELETE_WEEKWORK_DONE]: deleteWeekworkDone,
    [Types.DELETE_WEEKWORK_FAIL]: deleteWeekworkFail,

}
export default createReducer(INITIAL_STATE, HANDLERS)
