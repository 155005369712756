
import { createReducer } from 'reduxsauce'
import {Types} from './action'
const INITIAL_STATE = {
    project_error: null, attachment_error: null, projects: [], loading: false, attachments: [], 
    project : {}, attachment : {}, create_success : false, create_error : null,
}

// init case
export const fetchProjects = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true,
    }
}


// good case
export const fetchProjectsDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        projects : action.projects.data
    }
}


// bad case
export const fetchProjectsFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        project_error: action.error
    }
}


// init case
export const fetchAttachments = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true,
    }
}


// good case
export const fetchAttachmentsDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        attachments : action.attachments
    }
}


// bad case
export const fetchAttachmentsFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        attachment_error: action.error
    }
}

// init case
export const createProject = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true
    }
}


// good case
export const createProjectDone = (state = INITIAL_STATE, action ) => {
    
    return {
        ...state,
        loading: false,
        create_success : true,
        project : action.project
    }
}


// bad case
export const createProjectFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
       // payrolls: null,
        project_error: action.error,
        create_error : action.error,
        create_success : false,
        loading: false,
    }
}

// init case
export const updateProject = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true
    }
}


// good case
export const updateProjectDone = (state = INITIAL_STATE, action ) => {
    
    return {
        ...state,
        loading: false,
        project : action.project
    }
}


// bad case
export const updateProjectFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        project_error: action.error,
        loading: false,
    }
}

// init case
export const deleteProject = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true
    }
}


// good case
export const deleteProjectDone = (state = INITIAL_STATE, action ) => {
    
    return {
        ...state,
        loading: false,
        project : action.project
    }
}


// bad case
export const deleteProjectFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        project_error: action.error,
        loading: false,
    }
}

// init case
export const createAttachment = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true
    }
}


// good case
export const createAttachmentDone = (state = INITIAL_STATE, action ) => {
    
    return {
        ...state,
        loading: false,
        attachment: action.attachment
    }
}


// bad case
export const createAttachmentFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        attachment_error: action.error,
        loading: false,
    }
}
// init case
export const updateAttachment = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true
    }
}


// good case
export const updateAttachmentDone = (state = INITIAL_STATE, action ) => {
    
    return {
        ...state,
        loading: false,
        attachment: action.attachment
    }
}


// bad case
export const updateAttachmentFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        attachment_error: action.error,
        loading: false,
    }
}

// init case
export const deleteAttachment = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true
    }
}


// good case
export const deleteAttachmentDone = (state = INITIAL_STATE, action ) => {
    
    return {
        ...state,
        loading: false,
        attachment: action.attachment
    }
}


// bad case
export const deleteAttachmentFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        attachment_error: action.error,
        loading: false,
    }
}


    // handlers
export const HANDLERS = {
    
    [Types.FETCH_PROJECTS]: fetchProjects,
    [Types.FETCH_PROJECTS_DONE]: fetchProjectsDone,
    [Types.FETCH_PROJECTS_FAIL]: fetchProjectsFail,
    
    [Types.FETCH_ATTACHMENTS]: fetchAttachments,
    [Types.FETCH_ATTACHMENTS_DONE]: fetchAttachmentsDone,
    [Types.FETCH_ATTACHMENTS_FAIL]: fetchAttachmentsFail,

    [Types.CREATE_PROJECT]: createProject,
    [Types.CREATE_PROJECT_DONE]: createProjectDone,
    [Types.CREATE_PROJECT_FAIL]: createProjectFail,

    [Types.CREATE_ATTACHMENT]: createAttachment,
    [Types.CREATE_ATTACHMENT_DONE]: createAttachmentDone,
    [Types.CREATE_ATTACHMENT_FAIL]: createAttachmentFail,

    [Types.UPDATE_ATTACHMENT]: updateAttachment,
    [Types.UPDATE_ATTACHMENT_DONE]: updateAttachmentDone,
    [Types.UPDATE_ATTACHMENT_FAIL]: updateAttachmentFail,

    [Types.UPDATE_PROJECT]: updateProject,
    [Types.UPDATE_PROJECT_DONE]: updateProjectDone,
    [Types.UPDATE_PROJECT_FAIL]: updateProjectFail,

    [Types.DELETE_ATTACHMENT]: deleteAttachment,
    [Types.DELETE_ATTACHMENT_DONE]: deleteAttachmentDone,
    [Types.DELETE_ATTACHMENT_FAIL]: deleteAttachmentFail,

    [Types.DELETE_PROJECT]: deleteProject,
    [Types.DELETE_PROJECT_DONE]: deleteProjectDone,
    [Types.DELETE_PROJECT_FAIL]: deleteProjectFail
   
}
export default createReducer(INITIAL_STATE, HANDLERS)
