import Cookies from 'universal-cookie'; 
import {api, handle_response} from '../../helpers/api'
const cookies = new Cookies();


export async function fetchHolidayApi(){
    let url = '/holidays'
    return await api.get(url).then(handle_response)
}
export async function createHolidayApi({holiday}){
   return await api.post('/holidays', {...holiday}).then(handle_response)
}


export async function updateHolidayApi({holiday}){
   return await api.patch('/holidays/'+holiday.pk, {...holiday}).then(handle_response)
}


export async function deleteHolidayApi(holiday){
   return await api.delete('/holidays/'+ holiday.holiday).then(handle_response)
}

export async function assignToHolidayApi({assignment}){
    return await api.post('/holiday-assign', assignment).then(handle_response);
}
