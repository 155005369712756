import { createReducer } from 'reduxsauce';
import { Types } from './actions';

const records = [
  {
    id: 1,
    age: 32,
    name: 'Burt',
    company: 'Kaggle',
    phone: '+1 (823) 532-2427',
  },
  {
    id: 2,
    age: 23,
    name: 'Long',
    company: 'Magmina',
    phone: '+1 (813) 583-2089',
  },
  {
    id: 3,
    age: 31,
    name: 'Alvarado',
    company: 'Translink',
    phone: '+1 (975) 468-3875',
  },
  {
    id: 4,
    age: 24,
    name: 'Lilia',
    company: 'Digitalus',
    phone: '+1 (891) 537-3461',
  },
  {
    id: 5,
    age: 25,
    name: 'Amanda',
    company: 'Bunga',
    phone: '+1 (916) 522-3747',
  },
  {
    id: 6,
    age: 20,
    name: 'Alexandra',
    company: 'Conjurica',
    phone: '+1 (876) 492-3181',
  },
  {
    id: 7,
    age: 27,
    name: 'Diana',
    company: 'Extragen',
    phone: '+1 (977) 417-3038',
  },
  {
    id: 8,
    age: 26,
    name: 'Goodman',
    company: 'Aquamate',
    phone: '+1 (930) 545-2289',
  },
  {
    id: 9,
    age: 26,
    name: 'Edith',
    company: 'Pyrami',
    phone: '+1 (854) 506-3468',
  },
  {
    id: 10,
    age: 29,
    name: 'Juana',
    company: 'Singavera',
    phone: '+1 (872) 560-2324',
  },
  {
    id: 11,
    age: 21,
    name: 'Fitzgerald',
    company: 'Dancerity',
    phone: '+1 (813) 573-2507',
  },
  {
    id: 12,
    age: 38,
    name: 'Madden',
    company: 'Corpulse',
    phone: '+1 (935) 534-3876',
  },
  {
    id: 13,
    age: 40,
    name: 'Jewell',
    company: 'Frenex',
    phone: '+1 (886) 516-3262',
  },
  {
    id: 14,
    age: 32,
    name: 'Kerr',
    company: 'Artiq',
    phone: '+1 (807) 561-3077',
  },
  {
    id: 15,
    age: 20,
    name: 'Washington',
    company: 'Organica',
    phone: '+1 (948) 458-3517',
  },
  {
    id: 16,
    age: 20,
    name: 'Audrey',
    company: 'Softmicro',
    phone: '+1 (900) 592-3841',
  },
  {
    id: 17,
    age: 39,
    name: 'Allison',
    company: 'Playce',
    phone: '+1 (998) 478-3810',
  },
  {
    id: 18,
    age: 25,
    name: 'Holder',
    company: 'Paragonia',
    phone: '+1 (850) 536-2708',
  },
  {
    id: 19,
    age: 26,
    name: 'Atkinson',
    company: 'Scentric',
    phone: '+1 (850) 467-2761',
  },
  {
    id: 20,
    age: 35,
    name: 'Delaney',
    company: 'Telpod',
    phone: '+1 (934) 468-2218',
  },
];

function normalizeMarketPlaceCompany(company_list) {
  return company_list.map((company, index) => {
    return {
      ...company,
      value: company.pk,
      label: company.name,
    };
  });
}

const INITIAL_STATE = {
  error: null,
  contract: null,
  marketPlace: [],
  loading: false,
  contracts: records,

  // contract creation error handling process control variables
  contractCreated: false,
  contractCreatedFailed: false,

  loadingContractProposals: false,
  contractProposalsLoaded: false,

  deletingRFP: false,
};

// to get all contracts
export const fetchDefaultContract = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
  };
};

export const fetchDefaultContractDone = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    contracts: action.contracts,
  };
};

export const fetchDefaultContractFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    error: action.error,
  };
};

// to get one contract
export const fetchContract = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
  };
};

export const fetchContractDone = (state = INITIAL_STATE, action) => {
  // transform contract list before storing in redux for futher usage and it will be more suitable to be displayed on the fullcalendar component
  return {
    ...state,
    contract: action.contract,
    loading: false,
  };
};

export const fetchContractFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    error: action.error,
  };
};

// update
export const updateContract = (state = INITIAL_STATE, action) => {
  return {
    ...state,

    loading: true,
  };
};

export const updateContractDone = (state = INITIAL_STATE, action) => {
  return {
    ...state,

    loading: false,
  };
};

export const updateContractFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,

    loading: false,
    error: action.error,
  };
};

export const createContract = (state = INITIAL_STATE, action) => {
  return {
    ...state,

    loading: true,
  };
};

export const createContractDone = (state = INITIAL_STATE, action) => {
  return {
    ...state,

    loading: false,
    contractCreated: true,
    contractCreatedFailed: false,
  };
};

export const createContractFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
    contractCreatedFailed: true,
    contractCreated: false,
  };
};

export const deleteContract = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
    contract: action.contract,
  };
};

export const deleteContractDone = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    contract: null,
  };
};

export const deleteContractFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    contract: null,
    error: action.error,
  };
};
//  updateContract: ['contract'], deleteContract, createContract

export const fetchContractEmployee = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
  };
};

export const fetchContractEmployeeDone = (state = INITIAL_STATE, action) => {
  // transform contract list before storing in redux for futher usage and it will be more suitable to be displayed on the fullcalendar component
  return {
    ...state,
    employee_contracts: action.contracts,
    loading: false,
  };
};

export const fetchContractEmployeeFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    error: action.error,
  };
};

// to get one contract
export const fetchMarketPlaceContract = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
  };
};

export const fetchMarketPlaceDone = (state = INITIAL_STATE, action) => {
  // transform contract list before storing in redux for futher usage and it will be more suitable to be displayed on the fullcalendar component
  return {
    ...state,
    marketPlace: normalizeMarketPlaceCompany(action.contract),
    loading: false,
  };
};

export const fetchMarketPlaceFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    error: action.error,
  };
};

// contracts/proposals actions
export const fetchProposals = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingContractProposals: true,
    contractProposalsLoaded: false,
    contractProposalsLoadError: null,
  };
};

export const fetchProposalsDone = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingContractProposals: false,
    contractProposalsLoaded: true,
    proposals: action.proposals,
    contractProposalsLoadError: null,
  };
};

export const fetchProposalsFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingContractProposals: false,
    contractProposalsLoaded: false,
    contractProposalsLoadError: action.error,
  };
};

export const deleteProposal = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    deletingRFP: true,
    contractProposalDeleted: false,
    contractProposalDeletionError: null,
  };
};

export const deleteProposalDone = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    deletingRFP: false,
    contractProposalDeleted: true,
    contractProposalDeletionError: null,
  };
};

export const deleteProposalFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    deletingRFP: false,
    contractProposalDeleted: false,
    contractProposalDeletionError: action.error,
  };
};

export const HANDLERS = {
  [Types.CREATE_CONTRACT]: createContract,
  [Types.CREATE_CONTRACT_DONE]: createContractDone,
  [Types.CREATE_CONTRACT_FAIL]: createContractFail,

  [Types.FETCH_CONTRACT]: fetchContract,
  [Types.FETCH_CONTRACT_SUCCESS]: fetchContractDone,
  [Types.FETCH_CONTRACT_FAIL]: fetchContractFail,

  [Types.FETCH_DEFAULT_CONTRACT]: fetchDefaultContract,
  [Types.FETCH_DEFAULT_CONTRACT_DONE]: fetchDefaultContractDone,
  [Types.FETCH_DEFAULT_CONTRACT_FAIL]: fetchDefaultContractFail,

  [Types.UPDATE_CONTRACT]: updateContract,
  [Types.UPDATE_CONTRACT_DONE]: updateContractDone,
  [Types.UPDATE_CONTRACT_FAIL]: updateContractFail,

  [Types.DELETE_CONTRACT]: deleteContract,
  [Types.DELETE_CONTRACT_DONE]: deleteContractDone,
  [Types.DELETE_CONTRACT_FAIL]: deleteContractFail,

  [Types.FETCH_MARKET_PLACE]: fetchMarketPlaceContract,
  [Types.FETCH_MARKET_PLACE_SUCCESS_EMPLOYEE]: fetchMarketPlaceDone,
  [Types.FETCH_MARKET_PLACE_FAIL_EMPLOYEE]: fetchContractEmployeeFail,

  [Types.FETCH_PROPOSALS]: fetchProposals,
  [Types.FETCH_PROPOSALS_DONE]: fetchProposalsDone,
  [Types.FETCH_PROPOSALS_FAIL]: fetchProposalsFail,

  [Types.DELETE_PROPOSAL]: deleteProposal,
  [Types.DELETE_PROPOSAL_DONE]: deleteProposalDone,
  [Types.DELETE_PROPOSAL_FAIL]: deleteProposalFail,
};

export default createReducer(INITIAL_STATE, HANDLERS);
