
import { createActions } from 'reduxsauce'

export const {Types, Creators} = createActions({
        
    createOvertime: ['overtime'],
    createOvertimeDone: ['overtime'],
    createOvertimeFail: ['error'],
    
    updateOvertime: ['overtime'],
    updateOvertimeDone: ['overtime'],
    updateOvertimeFail: ['error'],
    
    fetchOvertime: ['overtime'],
    fetchOvertimeDone: ['overtime'],
    fetchOvertimeFail: ['error'],
    
    deleteOvertime: ['overtime'],
    deleteOvertimeDone: ['overtime'],
    deleteOvertimeFail: ['error'],

    assignEmployeesOvertime: ['assignment'],
    assignEmployeesOvertimeDone: null,
    assignEmployeesOvertimeFail: ['error'],

    uploadOvertime: ['overtime_obj'],
    uploadOvertimeDone: ['obj'],
    uploadOvertimeFail: ['error'],
    
});
     