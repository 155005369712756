import Cookies from 'universal-cookie'; 
import {api, handle_response} from '../../helpers/api'
const cookies = new Cookies();


export async function fetchDepartmentApi(){
    let url = '/departments'
    return await api.get(url).then(handle_response)
}
export async function createDepartmentApi({department}){
   return await api.post('/departments', {...department}).then(handle_response)
}


export async function updateDepartmentApi({department}){
   return await api.patch('/departments/'+department.pk, {...department}).then(handle_response)
}


export async function deleteDepartmentApi(department){
   return await api.delete('/departments/'+ department.department).then(handle_response)
}


export async function fetchDepartmentWithJobFunctionApi(){
   return await api.get('/department-job-function/').then(handle_response)
}

export async function assignToDepartmentApi(department) {
    return await api.post('/assign-employee-department', department).then(handle_response);
}

export async function importDepartment(form_data_department){
   return await api.post('/department-import/file',
   form_data_department.department_obj
   , {headers: {'Content-Type': 'multipart/form-data'}}).then(handle_response)
}