import { createReducer } from 'reduxsauce'
import {Types} from './actions'

const INITIAL_STATE = {
    error: null, loading: false, dashboard: null
}



export const fetchDashboard = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true,
    }
}

export const fetchDashboardDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        dashboard: action.dashboard
    }
}

export const fetchDashboardFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        error: action.error
    }
}


export const HANDLERS = {
    
    [Types.FETCH_DASHBOARD]: fetchDashboard,
    [Types.FETCH_DASHBOARD_DONE]: fetchDashboardDone,
    [Types.FETCH_DASHBOARD_FAIL]: fetchDashboardFail,
}

export default createReducer(INITIAL_STATE, HANDLERS)