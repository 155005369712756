
import { createReducer } from 'reduxsauce'
import {Types} from './actions'
const INITIAL_STATE = {
    error: null, notifications: [], loading: false, notification: {}
}

// init case
export const showNotification = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true
    }
}


// good case
export const showNotificationDone = (state = INITIAL_STATE, action ) => {
    /**
     * remove location from the reducer here because it is only one object,
     * will trigger a new location fetch in the saga
    **/
    return {
        ...state,
        loading: false,
        notification : action.notification
    }
}


// bad case
export const showNotificationFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        error: action.error
    }
}



// init case
export const fetchNotifications = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true
    }
}

// good case
export const fetchNotificationsDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        notifications: action.notifications.data.results,
        loading: false
    }
}

// bad case
export const fetchNotificationsFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        error: action.error
    }
}


// handlers
export const HANDLERS = {

    [Types.SHOW_NOTIFICATION]: showNotification,
    [Types.SHOW_NOTIFICATION_DONE]: showNotificationDone,
    [Types.SHOW_NOTIFICATION_FAIL]: showNotificationFail,


    [Types.FETCH_NOTIFICATIONS]: fetchNotifications,
    [Types.FETCH_NOTIFICATIONS_DONE]: fetchNotificationsDone,
    [Types.FETCH_NOTIFICATIONS_FAIL]: fetchNotificationsFail,


}
export default createReducer(INITIAL_STATE, HANDLERS)
