

import { createReducer } from 'reduxsauce'
import { Types } from './actions'
const INITIAL_STATE = {
    error: null, requirements: [], loading: false,  createSuccess : false, createError: false, requirement : {}
}

// reducers here


// init case
export const createRequirement = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: true,
        createSuccess : false, 
        createError: false
    }
}


// good case
export const createRequirementDone = (state = INITIAL_STATE, action) => {
    //console.log(action)
    return {
        ...state,
        loading: false, 
        createSuccess : true, 
        requirement : action.requirement.data,
        createError: false
    }
}

// bad case
export const createRequirementFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: action.error,
        loading: false,
        createSuccess : false, 
        createError: true
    }
}



// init case
export const updateRequirement = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: true,
        createSuccess : false, 
        createError: false
    }
}


// good case
export const updateRequirementDone = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        createSuccess : true, 
        createError: false
    }
}


// bad case
export const updateRequirementFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        error: action.error,
        createSuccess : false, 
        createError: true
    }
}



// init case
export const fetchRequirement = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: true,
        createSuccess : false, 
        createError: false,

    }
}


// good case
export const fetchRequirementDone = (state = INITIAL_STATE, action) => {
    //console.log(action)
    return {
        ...state,
        requirements: action.requirements.data.results,
        loading: false,
        createSuccess : false, 
        createError: false,

    }
}


// bad case
export const fetchRequirementFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        error: action.error,
        createSuccess : false, 
        createError: false,

    }
}



// init case
export const deleteRequirement = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: true,
        createSuccess : false, createError: false
    }
}

// good case
export const deleteRequirementDone = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        createSuccess : true, createError: false
    }
}

// bad case
export const deleteRequirementFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        //error: true,
        loading: false,
        error: action.error,
        createSuccess : false, createError: true
    }
}

// handlers
export const HANDLERS = {

    [Types.CREATE_REQUIREMENT]: createRequirement,
    [Types.CREATE_REQUIREMENT_DONE]: createRequirementDone,
    [Types.CREATE_REQUIREMENT_FAIL]: createRequirementFail,


    [Types.UPDATE_REQUIREMENT]: updateRequirement,
    [Types.UPDATE_REQUIREMENT_DONE]: updateRequirementDone,
    [Types.UPDATE_REQUIREMENT_FAIL]: updateRequirementFail,


    [Types.FETCH_REQUIREMENT]: fetchRequirement,
    [Types.FETCH_REQUIREMENT_DONE]: fetchRequirementDone,
    [Types.FETCH_REQUIREMENT_FAIL]: fetchRequirementFail,


    [Types.DELETE_REQUIREMENT]: deleteRequirement,
    [Types.DELETE_REQUIREMENT_DONE]: deleteRequirementDone,
    [Types.DELETE_REQUIREMENT_FAIL]: deleteRequirementFail,

    
}

export default createReducer(INITIAL_STATE, HANDLERS)