import { createReducer } from 'reduxsauce';
import { Types } from './actions';
import { normalizeShift } from '../../helpers/utils';

const INITIAL_STATE = {
  errorList: null,
  shift: null,
  errorCreate: null,
  errorShow: null,
  otherError: null,
  loading: false,
  fetchOneLoading: false,
  otherLoading: false,
  shifts: [],
  default_shifts: [],
  employee_shifts: [],
  shiftOvertimeEmployee: [],
  overtimeDates: [],
  assign_employee_shifts: [],
  acceptShiftAssignmentError: null,
  acceptShiftAssignmentLoading: false,
  acceptShiftAssignmentMessage: null,
  acceptShiftAssignmentSuccess : false,
  attendanceShift: null,
  attendanceShiftLoading: false,
  attendanceShiftError: null,
  breakAttendanceCompletion: null,
  breakAttendanceCompletionLoading: false,
  breakAttendanceCompletionError: null,
  assignShiftError: null,
  assignShiftLoading: false,
  attendanceShiftSuccess : false
};

export const fetchShift = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
    errorList: null,
    acceptShiftAssignmentError: null,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentMessage: null,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
    attendanceShiftSuccess : false,
    acceptShiftAssignmentSuccess : false
  };
};

export const fetchShiftDone = (state = INITIAL_STATE, action) => {
  // transform shift list before storing in redux for futher usage and it will be more suitable to be displayed on the fullcalendar component

  return {
    ...state,
    shifts: { results: normalizeShift(action.shift) },
    loading: false,
    errorList: null,
    acceptShiftAssignmentError: null,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentMessage: null,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
    attendanceShiftSuccess : false,
    acceptShiftAssignmentSuccess : false
  };
};

export const fetchShiftFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    errorList: action.error,
    acceptShiftAssignmentError: null,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentMessage: null,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
    attendanceShiftSuccess : false,
    acceptShiftAssignmentSuccess : false
  };
};
//show
export const showShift = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    fetchOneLoading: true,
    errorShow: null,
    acceptShiftAssignmentError: null,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentMessage: null,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
    attendanceShiftSuccess : false,
  };
};

export const showShiftDone = (state = INITIAL_STATE, action) => {
  // transform shift list before storing in redux for futher usage and it will be more suitable to be displayed on the fullcalendar component
  return {
    ...state,
    shift: action.shift,
    fetchOneLoading: false,
    errorShow: null,
    acceptShiftAssignmentError: null,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentMessage: null,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
    attendanceShiftSuccess : false,
  };
};

export const setShift = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    shift: action.shift,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
    attendanceShiftSuccess : false
  };
};

export const showShiftFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    fetchOneLoading: false,
    errorShow: action.error,
    acceptShiftAssignmentError: null,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentMessage: null,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
    attendanceShiftSuccess : false
  };
};

// update
export const updateShift = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    errorCreate: null,
    otherLoading: true,
    acceptShiftAssignmentError: null,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentMessage: null,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
    attendanceShiftSuccess : false
  };
};

export const updateShiftDone = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    errorCreate: null,
    otherLoading: false,
    acceptShiftAssignmentError: null,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentMessage: null,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
    attendanceShiftSuccess : false
  };
};

export const updateShiftFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,

    otherLoading: false,
    errorCreate: action.error,
    acceptShiftAssignmentError: null,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentMessage: null,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
    attendanceShiftSuccess : false
  };
};

export const createShift = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    errorCreate: null,
    otherLoading: true,
    acceptShiftAssignmentError: null,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentMessage: null,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
    attendanceShiftSuccess : false
  };
};

export const createShiftDone = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    shift: action.shift,
    otherLoading: false,
    errorCreate: null,
    acceptShiftAssignmentError: null,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentMessage: null,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
  };
};

export const createShiftFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    errorCreate: action.error,
    otherLoading: false,
    acceptShiftAssignmentError: null,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentMessage: null,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
  };
};

export const deleteShift = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    otherLoading: true,
    shift: action.shift,
    acceptShiftAssignmentError: null,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentMessage: null,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
  };
};

export const deleteShiftDone = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    otherLoading: false,
    shift: null,
    acceptShiftAssignmentError: null,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentMessage: null,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
  };
};

export const deleteShiftFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    otherLoading: false,
    shift: null,
    errorCreate: action.error,
    acceptShiftAssignmentError: null,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentMessage: null,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
  };
};
//  updateShift: ['shift'], deleteShift, createShift

export const fetchDefaultShift = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
    errorList: null,
    acceptShiftAssignmentError: null,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentMessage: null,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
  };
};

export const fetchDefaultShiftDone = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
    default_shifts: action.shifts,
    errorList: null,
    acceptShiftAssignmentError: null,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentMessage: null,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
  };
};

export const fetchDefaultShiftFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    errorList: action.error,
    acceptShiftAssignmentError: null,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentMessage: null,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
  };
};
export const fetchShiftEmployee = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    otherLoading: true,
    otherError: null,
    acceptShiftAssignmentError: null,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentMessage: null,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
  };
};

export const fetchShiftEmployeeDone = (state = INITIAL_STATE, action) => {
  // transform shift list before storing in redux for futher usage and it will be more suitable to be displayed on the fullcalendar component
  return {
    ...state,
    employee_shifts: action.shift,
    otherLoading: false,
    otherError: null,
    acceptShiftAssignmentError: null,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentMessage: null,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
  };
};

export const fetchShiftEmployeeFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    otherLoading: false,
    otherError: action.error,
    acceptShiftAssignmentError: null,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentMessage: null,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
  };
};

export const fetchOvertimeShiftEmployee = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    otherLoading: true,
    otherError: null,
    acceptShiftAssignmentError: null,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentMessage: null,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
  };
};

export const fetchOvertimeShiftSuccessEmployee = (state = INITIAL_STATE, action) => {
  // transform shift list before storing in redux for futher usage and it will be more suitable to be displayed on the fullcalendar component
  return {
    ...state,
    shiftOvertimeEmployee: action.shift,
    otherLoading: false,
    otherError: null,
    acceptShiftAssignmentError: null,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentMessage: null,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
  };
};

export const fetchOvertimeShiftFailEmployee = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    otherLoading: false,
    otherError: action.error,
    acceptShiftAssignmentError: null,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentMessage: null,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
  };
};

export const fetchOvertimeDateForShift = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    otherLoading: true,
    otherError: null,
    acceptShiftAssignmentError: null,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentMessage: null,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
  };
};

export const fetchOvertimeDateForShiftDone = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    otherLoading: false,
    overtimeDates: action.dates,
    otherError: null,
    acceptShiftAssignmentError: null,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentMessage: null,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
  };
};

export const fetchOvertimeDateForShiftFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    otherLoading: false,
    otherError: action.error,
    acceptShiftAssignmentError: null,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentMessage: null,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
  };
};

export const assignShift = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    acceptShiftAssignmentError: null,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentMessage: null,
    assignShiftLoading: true,
    assignShiftError: null,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
  };
};

export const assignShiftDone = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    assign_employee_shifts: action.shift,
    acceptShiftAssignmentError: null,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentMessage: null,
    assignShiftLoading: false,
    assignShiftError: null,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
  };
};

export const assignShiftFail = (state = INITIAL_STATE, action) => {

  return {
    ...state,
    acceptShiftAssignmentError: null,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentMessage: null,
    assignShiftError: action.error,
    assignShiftLoading: false,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
  };
};

export const acceptShiftAssignment = (state = INITIAL_STATE, action) => {
  return {
    //  ...state,
    acceptShiftAssignmentLoading: true,
    acceptShiftAssignmentMessage: null,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
    acceptShiftAssignmentSuccess : false
  };
};

export const acceptShiftAssignmentDone = (state = INITIAL_STATE, action) => {
  return {
    //...state,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentMessage: 'Assign Successful',
    attendanceShiftLoading: false,
    attendanceShiftError: null,
    acceptShiftAssignmentSuccess : true
  };
};

export const acceptShiftAssignmentFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    acceptShiftAssignmentLoading: false,
    acceptShiftAssignmentError: action.error,
    acceptShiftAssignmentMessage: null,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
    acceptShiftAssignmentSuccess : false
  };
};

export const createAttendance = (state = INITIAL_STATE, action) => {
  return {
    //  ...state,
    attendanceShiftLoading: true,
   
  };
};

export const createAttendanceDone = (state = INITIAL_STATE, action) => {
  return {
    //...state,
    attendanceShiftLoading: false,
    attendanceShift: action.attendance,
    attendanceShiftSuccess : true
  };
};

export const createAttendanceFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    attendanceShiftLoading: false,
    attendanceShiftError: action.error,
    attendanceShiftSuccess : false
  };
};
export const breakAttendanceCompletion = (state = INITIAL_STATE, action) => {
  return {
    //  ...state,
    attendanceShiftLoading: true,
  };
};

export const breakAttendanceCompletionDone = (state = INITIAL_STATE, action) => {
  return {
    //...state,
    breakAttendanceCompletionLoading: false,
    breakAttendanceCompletion: action.attendance,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
  };
};

export const breakAttendanceCompletionFail = (state = INITIAL_STATE, action) => {
  return {
    //...state,
    breakAttendanceCompletionLoading: false,
    breakAttendanceCompletionError: action.error,
    attendanceShiftLoading: false,
    attendanceShiftError: null,
  };
};

export const HANDLERS = {
  [Types.CREATE_SHIFT]: createShift,
  [Types.CREATE_SHIFT_DONE]: createShiftDone,
  [Types.CREATE_SHIFT_FAIL]: createShiftFail,

  [Types.FETCH_SHIFT]: fetchShift,
  [Types.FETCH_SHIFT_SUCCESS]: fetchShiftDone,
  [Types.FETCH_SHIFT_FAIL]: fetchShiftFail,

  [Types.SHOW_SHIFT]: showShift,
  [Types.SHOW_SHIFT_SUCCESS]: showShiftDone,
  [Types.SHOW_SHIFT_FAIL]: showShiftFail,

  [Types.FETCH_DEFAULT_SHIFT]: fetchDefaultShift,
  [Types.FETCH_DEFAULT_SHIFT_DONE]: fetchDefaultShiftDone,
  [Types.FETCH_DEFAULT_SHIFT_FAIL]: fetchDefaultShiftFail,

  [Types.UPDATE_SHIFT]: updateShift,
  [Types.UPDATE_SHIFT_DONE]: updateShiftDone,
  [Types.UPDATE_SHIFT_FAIL]: updateShiftFail,

  [Types.DELETE_SHIFT]: deleteShift,
  [Types.DELETE_SHIFT_DONE]: deleteShiftDone,
  [Types.DELETE_SHIFT_FAIL]: deleteShiftFail,

  [Types.FETCH_SHIFT_EMPLOYEE]: fetchShiftEmployee,
  [Types.FETCH_SHIFT_SUCCESS_EMPLOYEE]: fetchShiftEmployeeDone,
  [Types.FETCH_SHIFT_FAIL_EMPLOYEE]: fetchShiftEmployeeFail,

  [Types.FETCH_OVERTIME_SHIFT_EMPLOYEE]: fetchOvertimeShiftEmployee,
  [Types.FETCH_OVERTIME_SHIFT_SUCCESS_EMPLOYEE]: fetchOvertimeShiftSuccessEmployee,
  [Types.FETCH_OVERTIME_SHIFT_FAIL_EMPLOYEE]: fetchOvertimeShiftFailEmployee,

  [Types.FETCH_OVERTIME_DATE_FOR_SHIFT]: fetchOvertimeDateForShift,
  [Types.FETCH_OVERTIME_DATE_FOR_SHIFT_DONE]: fetchOvertimeDateForShiftDone,
  [Types.FETCH_OVERTIME_DATE_FOR_SHIFT_FAIL]: fetchOvertimeDateForShiftFail,

  [Types.ASSIGN_SHIFT]: assignShift,
  [Types.ASSIGN_SHIFT_DONE]: assignShiftDone,
  [Types.ASSIGN_SHIFT_FAIL]: assignShiftFail,

  [Types.ACCEPT_SHIFT_ASSIGNMENT]: acceptShiftAssignment,
  [Types.ACCEPT_SHIFT_ASSIGNMENT_DONE]: acceptShiftAssignmentDone,
  [Types.ACCEPT_SHIFT_ASSIGNMENT_FAIL]: acceptShiftAssignmentFail,

  [Types.CREATE_ATTENDANCE]: createAttendance,
  [Types.CREATE_ATTENDANCE_DONE]: createAttendanceDone,
  [Types.CREATE_ATTENDANCE_FAIL]: createAttendanceFail,

  [Types.BREAK_ATTENDANCE_COMPLETION]: breakAttendanceCompletion,
  [Types.BREAK_ATTENDANCE_COMPLETION_DONE]: breakAttendanceCompletionDone,
  [Types.BREAK_ATTENDANCE_COMPLETION_FAIL]: breakAttendanceCompletionFail,
};

export default createReducer(INITIAL_STATE, HANDLERS);
