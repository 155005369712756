import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {Types, Creators} from './actions'
import {Creators as PtoCreators} from '../pto/actions' 
import {fetchLeaveApi, fetchLeaveToValidateApi, acceptLeaveApi, rejectLeaveApi, createLeaveApi, fetchEmployeeLeaveApi, editLeaveApi, deleteLeaveApi} from './api'

const {FETCH_LEAVES, FETCH_LEAVES_TO_VALIDATE, ACCEPT_LEAVE, REJECT_LEAVE, CREATE_LEAVE, FETCH_EMPLOYEE_LEAVE, UPDATE_LEAVE, DELETE_LEAVE} = Types

const {
    fetchLeaves,
    fetchLeavesDone,
    fetchLeavesFail,

    fetchLeavesToValidate,
    fetchLeavesToValidateDone,
    fetchLeavesToValidateFail,

    rejectLeaveDone,
    rejectLeaveFail,

    acceptLeaveDone,
    acceptLeaveFail,

    createLeaveDone,
    createLeaveFail,

    updateLeaveDone,
    updateLeaveFail,

    deleteLeaveDone,
    deleteLeaveFail,

    fetchEmployeeLeaveDone,
    fetchEmployeeLeaveFail
} = Creators


function* sagaCreateLeave(leave){
    const response = yield call(createLeaveApi, leave)
    
    if (response.status){
        
       // yield put(createLeaveDone(response.data))
       yield put(fetchLeaves())
       yield put(PtoCreators.fetchDetailsWorker(leave.leave.worker))
    }else{
        yield put(createLeaveFail(response.data.message))
    }
}
function* sagaUpdateLeave(leave){
    const response = yield call(editLeaveApi, leave)
    
    if (response.status){
        
       // yield put(createLeaveDone(response.data))
       yield put(fetchLeaves())
       yield put(PtoCreators.fetchDetailsWorker(leave.worker))
    }else{
        yield put(updateLeaveFail(response.data.message))
    }
}
function* sagaDeleteLeave(leave){
    const response = yield call(deleteLeaveApi, leave)
    
    if (response.status){
        
       // yield put(createLeaveDone(response.data))
       yield put(fetchLeaves())
    }else{
        yield put(deleteLeaveFail(response.data.message))
    }
}


function* sagaFetchLeave(){
    const response = yield call(fetchLeaveApi)
    
    if (response.status){
        yield put(fetchLeavesDone(response.data.results))
    }else{
        yield put(fetchLeavesFail(response.data))
    }
}

function* sagaFetchLeaveToValidate(){
    const response = yield call(fetchLeaveToValidateApi)
    
    if (response.status){
        yield put(fetchLeavesToValidateDone(response.data.results))
    }else{
        yield put(fetchLeavesToValidateFail(response.data))
    }
}

function* sagaAcceptLeave({id}){
    const r = yield call(acceptLeaveApi,id)
    if (r.status){
        yield put(acceptLeaveDone(r.data))
        yield put(fetchLeavesToValidate())
    }else{
        yield put(acceptLeaveFail(r.data))
    }
}

function* sagaRejectLeave({id}){
    const r = yield call(rejectLeaveApi,id)
    if (r.status){
        yield put(rejectLeaveDone(r.data))
        yield put(fetchLeavesToValidate())
    }else{
        yield put(rejectLeaveFail(r.data))
    }
}

function* sagaFetchEmployeeLeave(){
    const response = yield call(fetchEmployeeLeaveApi)
    if (response.status){
        yield put(fetchEmployeeLeaveDone(response.data.results))
        yield put(fetchLeaves())
    }else{
        yield put(fetchEmployeeLeaveFail(response.data))
    }
}


export function *watchCreateLeave(){
    yield takeEvery(CREATE_LEAVE, sagaCreateLeave)
}
export function *watchUpdateLeave(){
    yield takeEvery(UPDATE_LEAVE, sagaUpdateLeave)
}
export function *watchDeleteLeave(){
    yield takeEvery(DELETE_LEAVE, sagaDeleteLeave)
}
export function *watchFetchLeave(){
    yield takeEvery(FETCH_LEAVES, sagaFetchLeave)
    yield takeEvery(FETCH_LEAVES_TO_VALIDATE, sagaFetchLeaveToValidate)
}

export function *watchAcceptLeave(){
    yield takeEvery(ACCEPT_LEAVE, sagaAcceptLeave)
}

export function *watchRejectLeave(){
    yield takeEvery(REJECT_LEAVE, sagaRejectLeave)
}

export function *watchFetchEmployeeLeave(){
    yield takeEvery(FETCH_EMPLOYEE_LEAVE, sagaFetchEmployeeLeave)
}

function* leaveSaga(){
    yield all([
        fork(watchFetchLeave),
        fork(watchAcceptLeave),
        fork(watchRejectLeave),
        fork(watchCreateLeave),
        fork(watchFetchEmployeeLeave),
        fork(watchUpdateLeave),
        fork(watchDeleteLeave),
    ])
}

export default leaveSaga