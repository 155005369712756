import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Types, Creators } from './actions';

import MarketplaceResource from '../../api/marketplace';
import IndustryResource from '../../api/industry';
import RateResource from '../../api/rate';
import RFPResource from '../../api/rfp';

import CompanyModel from '../../models/company';
import IndustryModel from '../../models/industry';
import StateModel from '../../models/state';
import ContractModel from '../../models/contract';
import Resource from '../../api/resource';

const marketplaceResource = new MarketplaceResource();
const industryResource = new IndustryResource();
const rateResource = new RateResource();
const rfpResource = new RFPResource();
const invitationResource = new Resource('/company-invitation');

const {
  fetchCompaniesDone,
  fetchCompaniesFail,

  fetchIndustriesDone,
  fetchIndustriesFail,

  fetchLocationsDone,
  fetchLocationsFail,

  sendRFPDone,
  sendRFPFail,

  sendInviteDone,
  sendInviteFail,
} = Creators;

function* sagaFetchCompanies(payload) {
  const response = yield call(marketplaceResource.list, payload.offset, payload.limit);

  if (response.status) {
    yield put(
      fetchCompaniesDone(
        response.data.results.map((i) => CompanyModel.fromJson(i)),
        payload.offset,
        payload.limit
      )
    );
  } else {
    yield put(fetchCompaniesFail(response.data));
  }
}

function* sagaFetchIndustries() {
  const response = yield call(industryResource.all);

  if (response.status) {
    yield put(fetchIndustriesDone(response.data.results.map((i) => IndustryModel.fromJson(i))));
  } else {
    yield put(fetchIndustriesFail(response.data));
  }
}

function* sagaFetchLocations() {
  const response = yield call(rateResource.all);

  if (response.status) {
    yield put(fetchLocationsDone(response.data.map((i) => StateModel.fromJson(i))));
  } else {
    yield put(fetchLocationsFail(response.data));
  }
}

function* sagaSendRFP({ contract }) {
  const response = yield call(rfpResource.create, contract);

  if (response.status) {
    yield put(sendRFPDone(ContractModel.fromJson(response.data)));
  } else {
    yield put(sendRFPFail(response.data));
  }
}

function* sagaSendInvite({ invites }) {
  const response = yield call(invitationResource.create, invites);

  if (response.status) {
    yield put(sendInviteDone());
  } else {
    yield put(sendInviteFail(response.data));
  }
}

export function* watchFetchCompanies() {
  yield takeEvery(Types.FETCH_COMPANIES, sagaFetchCompanies);
}

export function* watchFetchIndustries() {
  yield takeEvery(Types.FETCH_INDUSTRIES, sagaFetchIndustries);
}

export function* watchFetchLocations() {
  yield takeEvery(Types.FETCH_LOCATIONS, sagaFetchLocations);
}

export function* watchSendRFP() {
  yield takeEvery(Types.SEND_RFP, sagaSendRFP);
}

export function* watchSendInvite() {
  yield takeEvery(Types.SEND_INVITE, sagaSendInvite);
}

function* marketplaceSaga() {
  yield all([
    fork(watchFetchCompanies),
    fork(watchFetchIndustries),
    fork(watchFetchLocations),
    fork(watchSendRFP),
    fork(watchSendInvite),
  ]);
}

export default marketplaceSaga;
