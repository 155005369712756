import {api, handle_response} from '../../helpers/api'
import _ from 'lodash'

export async function fetchCompanyApi(company = 0){
    const url = _.isEmpty(company) ? '/company' : '/company/' + company.company
    return await api.get(url).then(handle_response)
}

export async function createCompanyApi({company}){
    return await api.post('/company', {...company}).then(handle_response)
}


export async function updateCompanyApi(company){
    return await api.patch('/update-company/'+company.company.pk, {...company.company}).then(handle_response)
}


export async function deleteCompanyApi(company_id){
    return await api.delete('/company/'+ company_id.company).then(handle_response)
}

export async function fetchSubscriptionApi(){
    return await api.get('/subscription').then(handle_response)
}
export async function getCompanyApi(company){
    return await api.get('/update-company/'+company.company).then(handle_response)
}
export async function fetchBillingPlanApi(){
    return await api.get('/billing-plan').then(handle_response)
}

export async function getMyCompaniesApi(){
    return await api.get('').then(handle_response)
}

export async function getCurrentCompanyApi({company}){
    api.setHeaders({
        'Tenant': company.company__company__domains__domain
    })
     return await api.get('company-role/'+company.company__company).then(handle_response)
}