import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchContract: null, // will handle one and many case(bulk)
  fetchContractSuccess: ['contracts'],
  fetchContractFail: ['error'],

  fetchDefaultContract: null,
  fetchDefaultContractDone: ['contracts'],
  fetchDefaultContractFail: ['error'],

  fetchStaffingContracts: null,
  fetchStaffingContractsDone: ['contracts'],
  fetchStaffingContractsFail: ['contracts'],

  updateContract: ['contract'],
  updateContractDone: ['contract'],
  updateContractFail: ['error'],

  deleteContract: ['contract'],
  deleteContractDone: null,
  deleteContractFail: ['error'],

  createContract: ['contract'],
  createContractDone: ['contract'],
  createContractFail: ['error'],

  fetchContractEmployee: ['contract'],
  fetchContractSuccessEmployee: ['contract'],
  fetchContractFailEmployee: ['error'],

  fetchMarketPlace: ['contract'],
  fetchMarketPlaceSuccessEmployee: ['contract'],
  fetchMarketPlaceFailEmployee: ['error'],

  fetchProposals: ['offset', 'limit'],
  fetchProposalsDone: ['proposals'],
  fetchProposalsFail: ['error'],

  deleteProposal: ['proposal'],
  deleteProposalDone: null,
  deleteProposalFail: ['error'],
});
