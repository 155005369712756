
import { createActions } from 'reduxsauce'

export const {Types, Creators} = createActions({
        
    createDevice: ['device'],
    createDeviceDone: ['device'],
    createDeviceFail: ['error'],
    
    updateDevice: ['device'],
    updateDeviceDone: ['device'],
    updateDeviceFail: ['error'],
    
    fetchDevice: ['device'],
    fetchDeviceDone: ['device'],
    fetchDeviceFail: ['error'],
    
    deleteDevice: ['device'],
    deleteDeviceDone: ['device'],
    deleteDeviceFail: ['error'],

    fetchApiKey: null,
    fetchApiKeyDone: ['apikey'],
    fetchApiKeyFail: ['error'],

    fetchNewApiKey: null,
    
});
    