import _ from 'lodash';

export default class IndustryModel {
  /**
   * @var {String}
   */
  id = '';

  /**
   * @type {String}
   */
  name = '';

  /**
   * @type {String}
   */
  description = '';

  /**
   * @type {IndustryModel}
   */
  parent = null;

  /**
   * @type {IndustryModel[]}
   */
  children = [];

  constructor() {
    this.toJson = this.toJson.bind(this);
  }

  /**
   * Converts this instance in json format
   * @returns {Object} The json format of this instance.
   */
  toJson() {
    return {
      pk: this.id,
      name: this.name,
      description: this.description,
      parent: this.parent,
      sub_industries: this.children.map((i) => i.toJson()),
    };
  }

  /**
   * Creates a new instance of IndustryModel from a json data.
   * @param {Object} json The json data to parse into an IndustryModel instance.
   * @returns {IndustryModel}
   */
  static fromJson(json) {
    const industry = new IndustryModel();

    if (json !== null && json !== undefined) {
      industry.id = json.pk;
      industry.name = json.name;
      industry.description = json.description;
      industry.parent = json.parent;

      if (_.isArray(json.sub_industries)) {
        industry.children = json.sub_industries.map((i) => IndustryModel.fromJson(i));
      }
    }

    return industry;
  }
}
