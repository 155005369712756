import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {fetchWorkers, importWorker, createManyWorker, createWorker, deleteWorker,
    updateWorker, workerDetails, assignShift, assignSalaryApi, getWorkerFileActivityApi ,
    fetchQRCodeTokenApi, updateAvatar, approveWorker, approvedWorkerApi, approveeWorker,
    transitFileApi, stopEmploymentApi
} from './api'

import {Types, Creators} from './actions'
import {Creators as ptoCreators} from '../pto/actions'

const {
    FETCH_WORKER, CREATE_WORKER, 
    UPDATE_WORKER, DELETE_WORKER,
    UPLOAD_WORKER, CREATE_BULK_WORKER, 
    FETCH_DETAILS_WORKER, ASSIGN_SHIFT_WORKER,
    ASSIGN_SALARY, GET_WORKER_FILE_ACTIVITY,
    APPROVE_WORKERS,APPROVED_WORKERS,APPROVEE_WORKERS,
    FETCH_QR_CODE_TOKEN, GET_WORKER_FILE_ACTIVITY_WORK_WEEK, UPDATE_AVATAR, 
    TRANSIT_FILE, STOP_EMPLOYMENT
} = Types

const {
    fetchWorker,
    fetchWorkerSuccess,
    fetchWorkerFail,

    createWorkerSuccess,
    createWorkerFail,

    updateWorkerDone,
    updateWorkerFail,

    deleteWorkerDone,
    deleteWorkerFail,

    uploadWorkerDone,
    uploadWorkerFail,

    createBulkWorker,
    createBulkWorkerDone,
    createBulkWorkerFail,

    fetchDetailsWorker,
    fetchDetailsWorkerDone,
    fetchDetailsWorkerFail,

    assignShiftWorker,
    assignShiftWorkerDone,
    assignShiftWorkerFail,

    assignSalaryDone,
    assignSalaryFail,

    getWorkerFileActivityDone,
    getWorkerFileActivityFail,

    getWorkerFileActivityWorkWeekDone,
    getWorkerFileActivityWorkWeekFail,

    fetchQRCodeTokenDone,
    fetchQRCodeTokenFail,

    updateAvatarDone,
    updateAvatarFail,

    approveWorkersDone,
    approveWorkersFail,

    approvedWorkersDone,
    approvedWorkersFail,

    approveeWorkersDone,
    approveeWorkersFail,

    transitFileDone,
    transitFileFail,

    stopEmploymentDone,
    stopEmploymentFail,

} = Creators

function* assignSalarySaga(salary){
    const response = yield call(assignSalaryApi, salary);
    if(response.status){
        yield put(assignSalaryDone(response.data ))
        // relaunche the process of getting employees details to update the UI
        yield put(ptoCreators.fetchDetailsWorker(response.data.employee_company))
    }else{
        yield put(assignSalaryFail(response.data))
    }
}

function* fetchWorkerSaga( {worker = 0, nopage = true}){
    console.log(nopage, 'no page')
    const response = yield call(fetchWorkers, {worker, nopage})
    if(response.status){
        yield put(fetchWorkerSuccess(response.data.results ))
    }else{
        yield put(fetchWorkerFail(response))
    }
}

function* createBulkWorkerSaga({ workers }){
    const response = yield call(createManyWorker, workers)
    if(response.status){
        yield put(createBulkWorkerDone(response))
        yield put(Creators.fetchWorker()) // trigger an update of worker list and update the whole state
    }else{
        yield put(createBulkWorkerFail(response))
        //yield put(fetchWorker())

    }
}

function* createWorkerSaga({ data }){
    const response = yield call(createWorker, data)
    if(response.status){
        yield put(createWorkerSuccess(response))
        yield put(fetchWorker())

    }else{
        yield put(createWorkerFail(response))
        //yield put(fetchWorker())

    }
}

function* updateWorkerSaga(worker){

    const response = yield call(updateWorker, worker)
    if(response.status){
        yield put(updateWorkerDone(response))
        // yield put(fetchWorker())
        yield put(ptoCreators.fetchDetailsWorker(worker.worker.pk))
        
    }else{
        yield put(updateWorkerFail(response))
    }
}

function* deleteWorkerSaga(worker){
    const response = yield call(deleteWorker, worker)
    if(response.status){
        yield put(deleteWorkerDone(response))
    }else{
        yield put(deleteWorkerFail(response))
    }
}

function* uploadWorkerSaga(worker){
    
    const response = yield call(importWorker, worker)
    if(response.status){
        yield put(uploadWorkerDone(response.data))
        yield put(fetchWorker())
    }else{
        yield put(uploadWorkerFail(response.data))
    }
}
function* updateAvatarSaga(files){
    const response = yield call(updateAvatar, files.avatar)
    if(response.status){
        yield put(updateAvatarDone(response.data))
        yield put(ptoCreators.fetchDetailsWorker(files.avatar.pk))
    }else{
        yield put(updateAvatarFail(response.data))
    }
}

function* fetchDetailsWorkerSaga(unique_id){
    const response = yield call(workerDetails, unique_id.worker)
    if(response.status){
        yield put(fetchDetailsWorkerDone(response))
    }else{
        yield put(fetchDetailsWorkerFail(response))
    }
}

function* assignShiftWorkerSage(data) {
    const response = yield call(assignShift, data)
    if(response.status){
        yield put(assignShiftWorkerDone(response))
    }else {
        yield put(assignShiftWorkerFail(response))
    }
}

function* getWorkerFileActivitySaga({file, date1, date2}){
    const response = yield call(getWorkerFileActivityApi, file, date1, date2)
    if(response.status){
        yield put(getWorkerFileActivityDone(response.data))
    }else{
        yield put(getWorkerFileActivityFail(response.data))
    }
}

function* getWorkerFileActivityWorkWeekSaga({file, date1, date2}){
    const response = yield call(getWorkerFileActivityApi, file, date1, date2)
    if(response.status){
        yield put(getWorkerFileActivityWorkWeekDone(response.data))
    }else{
        yield put(getWorkerFileActivityWorkWeekFail(response.data))
    }
}

function* fetchQRCodeTokenSaga(){
    const response = yield call(fetchQRCodeTokenApi)
    
    if(response.status){
        yield put(fetchQRCodeTokenDone(response.data.tempToken))
    }else{
        yield put(fetchQRCodeTokenFail(response.data))
    }
}


function* approveWorkerSaga(worker){
    const response = yield call(approveWorker, worker)
    if(response.status){
        yield put(approveWorkersDone(response))   
        yield put(ptoCreators.fetchDetailsWorker(worker.worker.pk))     
    }else{
        yield put(approveWorkersFail(response))
    }
}


function* approvedWorkerSaga(){
    const response = yield call(approvedWorkerApi)
    if(response.status){
        yield put(approvedWorkersDone(response))   
    }else{
        yield put(approvedWorkersFail(response))
    }
}


function* approveeWorkerSaga(worker){
    
    const response = yield call(approveeWorker, worker)
    if(response.status){
        yield put(approveeWorkersDone(response)) 
        yield put(ptoCreators.fetchDetailsWorker(worker.worker.pk))       
    }else{
        yield put(approveeWorkersFail(response))
    }
}

function* transitFileSaga(data){
    const response = yield call(transitFileApi, data);
    if(response.status){
        yield put(transitFileDone(response.data))
    }else{
        yield put(transitFileFail(response.data))
    }
}

function* stopEmploymentSaga(data){
    const response = yield call(stopEmploymentApi, data);
    if(response.status){
        yield put(stopEmploymentDone(response.data))
    }else{
        yield put(stopEmploymentFail(response.data))
    }
}


export function* watchCreateBulkWorker(){
    yield takeEvery(CREATE_BULK_WORKER, createBulkWorkerSaga)
}

export function* watchFetchWorker(){
    yield takeEvery(FETCH_WORKER, fetchWorkerSaga);
    yield takeEvery(GET_WORKER_FILE_ACTIVITY, getWorkerFileActivitySaga);
    yield takeEvery(GET_WORKER_FILE_ACTIVITY_WORK_WEEK, getWorkerFileActivityWorkWeekSaga);
    yield takeEvery(FETCH_QR_CODE_TOKEN, fetchQRCodeTokenSaga)
}

export function* watchTransitFile(){
    yield takeEvery(TRANSIT_FILE, transitFileSaga);
}
export function* watchUpdateWorker(){
    yield takeEvery(UPDATE_WORKER, updateWorkerSaga);
    yield takeEvery(UPDATE_AVATAR, updateAvatarSaga);
}

export function* watchUploadWorker(){
    yield takeEvery(UPLOAD_WORKER, uploadWorkerSaga)
}

export function* watchDeleteWorker(){
    yield takeEvery(DELETE_WORKER, deleteWorkerSaga)
}   

export function* watchCreateWorker(){
    yield takeEvery(CREATE_WORKER, createWorkerSaga)
}

export function* watchFetchDetailsWorker(){
    //yield takeEvery(FETCH_DETAILS_WORKER, fetchDetailsWorkerSaga)
}   
export function* watchApproveWorker(){
    yield takeEvery(APPROVE_WORKERS, approveWorkerSaga)
}
export function* watchApprovedWorker(){
    yield takeEvery(APPROVED_WORKERS, approvedWorkerSaga)
    yield takeEvery(APPROVEE_WORKERS, approveeWorkerSaga)
}
export function* watchAssignShiftWorker(){
    yield takeEvery(ASSIGN_SALARY, assignSalarySaga)
    yield takeEvery(ASSIGN_SHIFT_WORKER, assignShiftWorkerSage)
}

export function* watchEmploymentOperations(){
    //stop. halt restart emploment
    yield takeEvery(STOP_EMPLOYMENT, stopEmploymentSaga)
}

function* workerSaga(){
    yield all([
        fork(watchFetchWorker),
        fork(watchUpdateWorker),
        fork(watchUploadWorker),
        fork(watchDeleteWorker),
        fork(watchCreateWorker),
        fork(watchCreateBulkWorker),
        fork(watchFetchDetailsWorker),
        fork(watchAssignShiftWorker),
        fork(watchApproveWorker),
        fork(watchApprovedWorker),
        fork(watchTransitFile),
        fork(watchEmploymentOperations),
    ])
}

export default workerSaga