import Cookies from 'universal-cookie'; 
import {api, handle_response} from '../../helpers/api'
const cookies = new Cookies();

export async function fetchDeviceApi(device = 0){
    const url = (device.device === 0 || device.device === null  || device.device === undefined ) ? '/device' : '/device/' + device
    return await api.get(url).then(handle_response)
}

export async function createDeviceApi({device}){
    return await api.post('/device', {...device}).then(handle_response)
}


export async function updateDeviceApi({device}){
    return await api.patch('/device/'+device.pk, {...device}).then(handle_response)
}


export async function deleteDeviceApi(device_id){
    return await api.delete('/device/'+ device_id.device).then(handle_response)
}

export async function fetchApiKeyApi(){
    return await api.get('/getapikey').then(handle_response)
}

export async function fetchNewApiKeyApi(){
    return await api.get('/apikey').then(handle_response)
}