import Cookies from 'universal-cookie';
import {api, handle_response} from '../../helpers/api'
import _ from 'lodash';
const cookies = new Cookies();

export async function fetchLocationApi(location = 0){
    const url = (location.location === 0 || location.location === null  || location.location === undefined )  ? '/locations' : '/locations/' + location
    return await api.get(url).then(handle_response)
}

export async function createLocationApi(location){
    return await api.post('/locations', {...location.location}).then(handle_response)
}


export async function updateLocationApi({location}){
    return await api.patch('/locations/'+location.pk, {...location}).then(handle_response)
}


export async function deleteLocationApi(location ){
    return await api.delete('/locations/'+ location.location).then(handle_response)
}

export async function assignEmployeeApi({assignment}){
    return await api.post('/location-assignment', assignment).then(handle_response)
}

export async function importLocation(form_data_location){
    return await api.post('/location-import/file',
    form_data_location.location_obj
    , {headers: {'Content-Type': 'multipart/form-data'}}).then(handle_response)
}
