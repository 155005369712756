import Cookies from 'universal-cookie'; 
import {api, handle_response} from '../../helpers/api'

export async function fetchJobApi(){
    let url = '/positions'
    return await api.get(url).then(handle_response)
}
export async function createJobApi({job}){
    return await api.post('/positions', {...job}).then(handle_response)
}


export async function updateJobApi({job}){
    return await api.patch('/positions/'+job.pk, {...job}).then(handle_response)
}


export async function deleteJobApi(job){
    return await api.delete('/positions/'+ job.job).then(handle_response)
}

export async function assignWorkerPosition({position}){
    return await api.post('/assign-employee-position', position).then(handle_response)
}

export async function importJob(form_data_job){
    return await api.post('/job-import/file',
    form_data_job.job_obj
    , {headers: {'Content-Type': 'multipart/form-data'}}).then(handle_response)
 }