import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import * as FeatherIcon from 'react-feather';
import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';
import ability from '../helpers/ability'
import _ from 'lodash'
import Cookies from 'universal-cookie'
import * as layoutConstants from '../constants/layout';


// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const Verify = React.lazy(() => import('../pages/auth/Verify'));
const Profile = React.lazy(() => import('../pages/other/Profile/index'));
const Message = React.lazy(()=> import('../pages/other/Profile/Messages'))
const CompanyList =  React.lazy(() => import('../pages/auth/CompaniesList'))
// dashboard
const Dashboard = React.lazy(() => import('../pages/dashboard'));
// apps
const Location = React.lazy(() => import('../pages/apps/Location/List'));

const Attendance = React.lazy(() => import('../pages/Attendance/index'));
const Timesheet = React.lazy(() => import('../pages/Attendance/timesheet'));
const GlobalPayroll = React.lazy(() => import('../pages/Payroll/index'));
const Done = React.lazy(() => import('../pages/auth/Done'));

const ViewProfile = React.lazy(() => import('../pages/apps/workers/ViewScreen'));
const EmployeeSettings = React.lazy(() => import('../pages/apps/workers/UserInformation'));
// // pages
const Shift = React.lazy(() => import('../pages/shifts/index'));
const Leave = React.lazy(() => import('../pages/Leaves/index'));
const Departments = React.lazy(() => import('../pages/departement/index'));
const JobFunction = React.lazy(() => import('../pages/jobFonction/index'));
// ui
const CompanySettings = React.lazy(() => import('../pages/Settings/CompanySettings'));
// charts
const Workers = React.lazy(() => import('../pages/apps/workers/List'));
const AddWorker = React.lazy(() => import('../pages/apps/workers/Add'))
const Contractors = React.lazy(() => import('../pages/contractor/List'));
const ContractDetail = React.lazy(() => import('../pages/contractor/Details'));
const WorkerShift = React.lazy(() => import('../pages/apps/workers/WorkerShift'));

// Marketplace
const MarketPlace = React.lazy(() => import('../pages/marketplace/index'));

// Contracts
const ContractProposals = React.lazy(() => import('../pages/contractor/proposals/index'));
const Projects =  React.lazy(() => import('../pages/contractor/projects/Index'))
const DetailProject = React.lazy(() => import('../pages/contractor/projects/Detail/index'))
// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!isUserAuthenticated()) {
        // not logged in so redirect to login page with the return url
        return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
      }

      const loggedInUser = getLoggedInUser();
      // check if route is restricted by role
      if (!_.isUndefined(loggedInUser.role)) {
        if (roles && roles.indexOf(loggedInUser.role) === -1) {
          // role not authorised so redirect to home page
          return <Redirect to={{ pathname: '/' }} />;
        }
      }
      // authorised so return component
      return <Component {...props} />;
    }}
  />
);
const cookies  = new Cookies()
// root routes
const rootRoute = {
  path: '/',
  exact: true,
  component: () => <Redirect to="/dashboard" />,
  route: PrivateRoute,
};

//Admin Route

// dashboards
const dashboardRoutes = {
  path: '/dashboard',
  name: 'Analytics',
  icon: FeatherIcon.Home,
  header: 'Navigation',
  badge: {
    variant: 'success',
    text: '1',
  },
  component: Dashboard,
  roles: ['Admin'],
  route: PrivateRoute,
};

// apps
const EmployeeAppRoute = {
  path: '/employees',
  name: 'Employees',
  exact: true,
  icon: FeatherIcon.User,
  component: Workers,
  route: PrivateRoute,
  roles: ['Admin'],
};

const NotificationsAppRoute = {
  path: '/notifications',
  name: 'Notifications',
  exact: true,
  icon: FeatherIcon.Bell,
  component: Message,
  route: PrivateRoute,
  roles: ['Admin'],
};
const shiftRoute = {
  path: '/shifts',
  name: 'Work Shifts',
  icon: FeatherIcon.Briefcase,
  header: 'Navigation',
  badge: {
    variant: 'success',
    text: '1',
  },
  component: Shift,
  roles: ['Admin'],
  route: PrivateRoute,
};

const leaveApprovalRoute = {
  path: '/leaves',
  name: 'Time Off Approval',
  icon: FeatherIcon.Bookmark,
  component: Leave,
  roles: ['Admin'],
  route: PrivateRoute,
};

const attendanceAppRoutes = {
  path: '/attendance',
  name: 'Attendance',
  icon: FeatherIcon.Calendar,
  component: Attendance,
  roles: ['Admin'],
  route: PrivateRoute,
};
const detailProjectRoute = {
  path : '/project/:p_id/detail',
  name : 'detail-project',
  component : DetailProject,
  roles : ['Admin'],
  route : PrivateRoute,
}

const EarningAppRoutes = {
  path: '/timesheet',
  name: 'Timesheet',
  icon: FeatherIcon.CreditCard,
  component: Timesheet,
  roles: ['Admin'],
  route: PrivateRoute,
};


const GlobalPayrollRoute = {
  path: '/payroll',
  name: 'Payroll',
  icon: FeatherIcon.DollarSign,
  component: GlobalPayroll,
  roles: ['Admin'],
  route: PrivateRoute,
};




// pages
const contractsRoute = {
  path: '/contracts',
  name: 'Contractors',
  header: 'Custom',
  exact: true,
  icon: FeatherIcon.FileText,
  route: PrivateRoute,
  component: Contractors,
  roles: ['Admin'],
  children: [
    {
      path: '/contracts/projects',
      name: 'Projects',
      component: Projects,
      route: PrivateRoute,
      roles: ['Admin'],
    },
    {
      path: '/contracts/proposals',
      name: 'Requests For Proposals',
      component: ContractProposals,
      route: PrivateRoute,
      roles: ['Admin'],
    },
    
  ],
};

const contractDetailsRoute = {
  path: '/contract/:c_id',
  name: 'Contract details',
  header: 'Custom',
  icon: FeatherIcon.FileText,
  route: PrivateRoute,
  component: ContractDetail,
  roles: ['Admin'],
};

const marketplaceRoute = {
  path: '/marketplace',
  name: 'Staffing Marketplace',
  header: 'Custom',
  icon: FeatherIcon.ShoppingCart,
  route: PrivateRoute,
  component: MarketPlace,
  roles: ['Admin'],
};

const AddEmployee = {
  path: '/employee/new',
  name:'Add new employee',
  header: 'Custom',
  route: PrivateRoute,
  component: AddWorker,
  roles: ['Admin']
}


// const appRoutess = [
//   EmployeeAppRoute,
//   attendanceAppRoutes,
//   EarningAppRoutes,
//   projectAppRoutes,
//   taskAppRoutes,
//   contractsRoute,
//   marketplaceRoute
// ];



function checkMenuPermissios(){
  let appRoutes = []

  if(!_.isEmpty(cookies.get('user'))){
    if(!_.isEmpty(cookies.get('user').user.company)){
      appRoutes.push(dashboardRoutes)
    }
  }
  if ( ability.can('view', 'employeecompany')){
      appRoutes.push(EmployeeAppRoute);
  }
  
  if ( ability.can('view', 'attendance')){
    appRoutes.push(attendanceAppRoutes);
  }

  if ( ability.can('view', 'timesheet')){
    appRoutes.push(EarningAppRoutes);
    appRoutes.push(GlobalPayrollRoute);
  }

  if ( ability.can('view', 'shift')){
    appRoutes.push(shiftRoute);
  }

  if ( ability.can('view', 'attendance')){
    appRoutes.push(leaveApprovalRoute);
  }
  if ( ability.can('access', 'marketplace')){
    appRoutes.push(contractsRoute);
  }
  if(ability.can('access', 'marketplace')){
    appRoutes.push(marketplaceRoute)
  }

  return appRoutes
}

// console.log(checkMenuPermissios())
const appRoutes = checkMenuPermissios()

// console.log(appRoutes, ' les route ici')

// components

// auth
const authRoutes = {
  path: '/account',
  name: 'Auth',
  children: [
    {
      path: '/account/login',
      name: 'Login',
      component: Login,
      route: Route,
    },
    {
      path: '/account/done',
      name: 'Done',
      component: Done,
      route: Route,
    },
    {
      path: '/account/verify',
      name: 'Verify',
      component: Verify,
      route: Route,
    },
    {
      path: '/account/profile',
      name: 'Profile',
      component: Profile,
      route: PrivateRoute,
      roles: ['Admin', 'Employee', 'Manager'],
    },
    {
      path: '/company/settings',
      name: 'Settings',
      component: CompanySettings,
      route: PrivateRoute,
      roles: ['Admin'],
    },
    {
      path: '/settings/locations',
      name: 'Locations',
      component: Location,
      route: PrivateRoute,
      roles: ['Admin'],
    },
    {
      path: '/settings/departments',
      name: 'Departments',
      component: Departments,
      route: PrivateRoute,
      roles: ['Admin'],
    },
    {
      path: '/settings/job-function',
      name: 'Job Functions',
      component: JobFunction,
      route: PrivateRoute,
      roles: ['Admin'],
    },
    {
      path: '/account/logout',
      name: 'Logout',
      component: Logout,
      route: Route,
    },
    {
      path: '/account/register',
      name: 'Register',
      component: Register,
      route: Route,
    },
    {
      path: '/account/confirm',
      name: 'Confirm',
      component: Confirm,
      route: Route,
    },
    {
      path: '/account/forget-password',
      name: 'Forget Password',
      component: ForgetPassword,
      route: Route,
    },
    
  ],
};

const ViewProfileRoute = {
  exact: true,
  path: '/employees/:unique_id',
  name: '',
  component: ViewProfile,
  roles: ['Admin'],
  route: PrivateRoute,
};
const CompaniesListRoute = {
  exact: true,
  path: layoutConstants.WORKSPACE_URL,
  name: 'Companies List',
  component: CompanyList,
  roles: ['Admin'],
  route: PrivateRoute,
  layout: {
    layoutType: 'blank'
  }
}
const SettingsUserRoute = {
  exact: true,
  path: '/account/settings',
  name: 'Account Settings',
  component: EmployeeSettings,
  roles: ['Admin'],
  route: PrivateRoute,
}

//Worker route
const shiftWorkerRoutes = {
  path: '/settings/worker/shifts/:unique_id',
  name: '',
  component: WorkerShift,
  roles: ['Admin'],
  route: PrivateRoute,
};
// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const allRoutes = [
  rootRoute,
  ...appRoutes,
  // pagesRoutes,
  //addContractor,
  authRoutes,
  ViewProfileRoute,
  shiftWorkerRoutes,
  contractDetailsRoute,
  marketplaceRoute,
  AddEmployee,
  NotificationsAppRoute,
  SettingsUserRoute,
  CompaniesListRoute,
  detailProjectRoute
  //  globalSettingsRoutes
];

// const allWorkerRoute = [rootRoute, dashboardRoutes, projedctAppRoutes];
const authProtectedRoutes = [
  ...appRoutes,
  // pagesRoutes,
];
const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };
