
  
import { createReducer } from 'reduxsauce'
import {Types} from './actions'
const INITIAL_STATE = {
    error: null, roles: [], loading: false, permissions : []
}

// reducers here

    
    // init case
    export const createRole = (state = INITIAL_STATE, action ) => {
        return {
            ...state,
            loading: true
        }
    }
    
    
    // good case
    export const createRoleDone = (state = INITIAL_STATE, action ) => {
        return {
            ...state,
            loading: false
        }
    }
    
    
    // bad case
    export const createRoleFail = (state = INITIAL_STATE, action ) => {
        return {
            ...state,
            //Role: null,
            error: true,
            loading: false,
           // error: action.error
        }
    }
    
    
    
    // init case
    export const updateRole = (state = INITIAL_STATE, action ) => {
        return {
            ...state,
            loading: true
        }
    }
    
    
    // good case
    export const updateRoleDone = (state = INITIAL_STATE, action ) => {
        return {
            ...state,
            loading: false
        }
    }
    
    
    // bad case
    export const updateRoleFail = (state = INITIAL_STATE, action ) => {
        return {
            ...state,
            role: null,
            loading: false,
            error: action.error
        }
    }
    
    
    
    // init case
    export const fetchRole = (state = INITIAL_STATE, action ) => {
        return {
            ...state,
            loading: true
        }
    }
    
     
    // good case
    export const fetchRoleDone = (state = INITIAL_STATE, action ) => {
        return {
            ...state,
            roles: action.roles,
            loading: false
        }
    }
    
    
    // bad case
    export const fetchRoleFail = (state = INITIAL_STATE, action ) => {
        return {
            ...state,
            loading: false,
            error: action.error
        }
    }
    
    export const fetchPermission = (state = INITIAL_STATE, action) => {
        return {
            ...state,
            loading: true
        }
    }
    export const fetchPermissionDone = (state = INITIAL_STATE, action ) => {
        return {
            ...state,
            permissions: action.permissions,
            loading: false
        }
    }
    
    
    // bad case
    export const fetchPermissionFail = (state = INITIAL_STATE, action ) => {
        return {
            ...state,
            loading: false,
            error: action.error
        }
    }
    // init case
    export const deleteRole = (state = INITIAL_STATE, action ) => {
        return {
            ...state,
            loading: true
        }
    }
    
    
    // good case
    export const deleteRoleDone = (state = INITIAL_STATE, action ) => {
        return {
            ...state,
            loading: false
        }
    }
    
    
    // bad case
    export const deleteRoleFail = (state = INITIAL_STATE, action ) => {
        return {
            ...state,
            //error: true,
            loading: false,
            error: action.error
        }
    }

  
    
// handlers
export const HANDLERS = {
    
    [Types.CREATE_ROLE]: createRole,
    [Types.CREATE_ROLE_DONE]: createRoleDone,
    [Types.CREATE_ROLE_FAIL]: createRoleFail,

    
    [Types.UPDATE_ROLE]: updateRole,
    [Types.UPDATE_ROLE_DONE]: updateRoleDone,
    [Types.UPDATE_ROLE_FAIL]: updateRoleFail,

    
    [Types.FETCH_ROLE]: fetchRole,
    [Types.FETCH_ROLE_DONE]: fetchRoleDone,
    [Types.FETCH_ROLE_FAIL]: fetchRoleFail,

    
    [Types.DELETE_ROLE]: deleteRole,
    [Types.DELETE_ROLE_DONE]: deleteRoleDone,
    [Types.DELETE_ROLE_FAIL]: deleteRoleFail,

    [Types.FETCH_PERMISSION]: fetchPermission,
    [Types.FETCH_PERMISSION_DONE]: fetchPermissionDone,
    [Types.FETCH_PERMISSION_FAIL]: fetchPermissionFail,
 
}

export default createReducer(INITIAL_STATE, HANDLERS)