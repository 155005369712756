
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import {createBrowserHistory} from 'history'
import {api, handle_response} from '../../helpers/api'
import Cookies from 'universal-cookie';
import {logoutUser} from '../auth/actions'
import _ from 'lodash'
const cookies = new Cookies();
const history = createBrowserHistory()

/**
 * return one or many worker detail. will go a get query on /worker or /worker/:worker_id
 * @param {*} worker_id
 */
export async function fetchWorkers({worker = 0, nopage = true}){
    const url = worker === 0 ? (nopage ? '/employees-list?nopage' : '/employees-list') : '/employees-list/' + worker.worker;
    
    return await api.get(url)
      .then(function(response){
          if (response.ok){
              return {status: true, data: response.data}
          }else{
              if(response.status == 404 && response.data.code == "T002"){
                 logoutUser()
              }
              return {status: false, data: response.data}
          }
      });
}

/**
 * create one worker, will do a post query on /worker
 * @param {*} param
 */
export async function createWorker( user){
    return await api.post('/employees-create', user).then(function(response){
            var _ = {data: response.data}
            if (response.ok){
                _.status = true
            }else{
                _.status =false
            }
            return _
        })
}

/**
 * Update an employee via a PATCH query on /worker/:worker_id endpoint
 * @param {} param
 */
export async function updateWorker({worker}){
    return await api.patch('/employees-update/' + worker.pk, {...worker}).then(function(response){
            var _ = {data: response.data}
            if (response.ok){
                _.status = true
            }else{
                _.status =false
            }
            return _
        })
}

/**
 * Delete one worker
 * @param {} param0
 */
export async function deleteWorker({ pk }){
    return await api.delete('/employees/' + pk).then(function(response){
        var _ = {data: response.data}
        if (response.ok){
            _.status = true
        }else{
            _.status =false
        }
        return _
    })
}

export async function createManyWorker(workers){
    if(Array.isArray(workers)){
        return await api.post('/employees?many', workers ).then(handle_response)
    }else{
        throw new Error("Workerlist should be an array of worker not an object")
    }

}

/**
 * Import data one the server, a csv file
 * @param {FormData} form_data_worker csv file with worker data
 */
export async function importWorker(form_data_worker){
    return await api.post('/employees-import/file',
        form_data_worker.worker_obj
    , {headers: {'Content-Type': 'multipart/form-data'}}).then(handle_response)
}

/**
 * Import all worker details
 * @param {int} unique_id 
 */
export async function workerDetails(unique_id){
return await api.get('/employees/'+unique_id).then(handle_response)
}  

/**
 * assign shift to worker
 * @param {json} data
 */
export async function assignShift(data){
    return await api.patch('/assign-shift/'+data.unique_id, data).then(handle_response)
}

export async function assignSalaryApi({salary}){
    return await api.post('/employee-salary', {...salary}).then(handle_response)
}

export async function getWorkerFileActivityApi(file, date1, date2){
    return await api.get(`/global-work-x?_list=${file}&date1=${date1}&date2=${date2}&_class=0`).then(handle_response)
}

export async function fetchQRCodeTokenApi() {
    return await api.get('/qrcode-token').then(handle_response)
}

export async function updateAvatar(avatar){
    const form = new FormData()
    form.append('pp', avatar.pp)
    const headers = {
      'Content-Type': 'multipart/form-data'
    }
    return await api.patch('/employees-details-image/'+avatar.pk,form, {headers}).then(handle_response)
}

export async function approveWorker(workers){
    return await api.patch('/employee-approvers/'+workers.worker.pk, {...workers.worker.approvers}).then(handle_response)
}

export async function approvedWorkerApi(){
    return await api.get('/employee-approved').then(handle_response)
}

export async function approveeWorker(workers){
    return await api.patch('/employee-approvees/'+workers.worker.pk, {...workers.worker.approvees}).then(handle_response)
}

export async function transitFileApi(data){
    return await api.post('/file-promotion', {...data.fileData}).then(handle_response)
}

export async function stopEmploymentApi({worker, date}){
    return await api.post('/work-stop', {worker: worker, date: date}).then(handle_response)
}