import Cookies from 'universal-cookie'; 
import {api, handle_response} from '../../helpers/api'
import _ from 'lodash'
const cookies = new Cookies();

export async function fetchRoleApi({role}){
    const url = _.isUndefined(role) ? '/role' : '/role/' + role
    return await api.get(url).then(handle_response)
}

export async function createRoleApi(role){
    const url = '/role'
    return await api.post(url, {...role}).then(handle_response)
}

export async function deleteRoleApi(role_id){
    return await api.delete('/role/'+ role_id.role).then(handle_response)
}



export async function updateRoleApi({role}){
    return await api.patch('/role/'+role.pk, {...role}).then(handle_response)
}

export async function fetchPermissionApi(){
    return await api.get('/permissions').then(handle_response)
}
