
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {Types, Creators} from './actions'
// api import
import { 
    createRequirementApi, 
    updateRequirementApi, 
    fetchRequirementApi, 
    deleteRequirementApi, 
    
} from './api'
// types import
const { CREATE_REQUIREMENT,UPDATE_REQUIREMENT,FETCH_REQUIREMENT,DELETE_REQUIREMENT} = Types
// success, fail import

const {
    createRequirementDone,
    createRequirementFail, 
    updateRequirementDone,
    updateRequirementFail, 
    fetchRequirement,
    fetchRequirementDone,
    fetchRequirementFail, 
    deleteRequirementDone,
    deleteRequirementFail, 
    
} = Creators

// sagas handler

function* createRequirementSaga(action){
   // console.log(action)
    const response = yield call(createRequirementApi, action.requirement);
    if (response.status){
       
        yield put(createRequirementDone(response))
        yield put(fetchRequirement())
    }else{
        yield put(createRequirementFail(response))
        
    }
}

function* updateRequirementSaga(requirement){
    const response = yield call(updateRequirementApi, requirement);
    if (response.status){
        yield put(updateRequirementDone(response))
        yield put(fetchRequirement());
    }else{
        yield put(updateRequirementFail(response))
    }
}

function* fetchRequirementSaga(){
    const response = yield call(fetchRequirementApi);
    if (response.status){
        yield put(fetchRequirementDone(response))
        
    }else{
        yield put(fetchRequirementFail(response))
    }
}

function* deleteRequirementSaga(pto){
    const response = yield call(deleteRequirementApi, pto);
    if (response.status){
        yield put(deleteRequirementDone(response))
        yield put(fetchRequirement())

    }else{
        yield put(deleteRequirementFail(response))
    }
}



//saga watchers

export function* watchCreateRequirement(){
    yield takeEvery(CREATE_REQUIREMENT, createRequirementSaga)
}

export function* watchUpdateRequirement(){
    yield takeEvery(UPDATE_REQUIREMENT, updateRequirementSaga)
}

export function* watchFetchRequirement(){
    yield takeEvery(FETCH_REQUIREMENT, fetchRequirementSaga)
}

export function* watchDeleteRequirement(){
    yield takeEvery(DELETE_REQUIREMENT, deleteRequirementSaga)
}

// saga builder

export default function* requirementSaga(){
    yield all([
        fork(watchCreateRequirement),
        fork(watchUpdateRequirement),
        fork(watchFetchRequirement),
        fork(watchDeleteRequirement),
    ])
}
    


    