import { createReducer } from 'reduxsauce';
import { Types } from './actions';

const INITIAL_STATE = {
  error: null,
  companies: [],
  industries: [],
  locations: [],
  loadingCompanies: false,
  loadingIndustries: false,
  loadingLocations: false,
  sendingRFP: false,
  sendingRFPDone: false,
  sendingInvite: false,
  sendingInviteDone: false,
};

export const fetchCompanies = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingCompanies: true,
  };
};

export const fetchCompaniesDone = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingCompanies: false,
    companies: action.companies,
  };
};

export const fetchCompaniesFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingCompanies: false,
    error: action.error,
  };
};

export const fetchIndustries = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingIndustries: true,
  };
};

export const fetchIndustriesDone = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingIndustries: false,
    industries: action.industries,
  };
};

export const fetchIndustriesFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingIndustries: false,
    error: action.error,
  };
};

export const fetchLocations = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingLocations: true,
  };
};

export const fetchLocationsDone = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingLocations: false,
    locations: action.locations,
  };
};

export const fetchLocationsFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingLocations: false,
    error: action.error,
  };
};

export const sendRFP = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    sendingRFP: true,
    sendingRFPDone: false,
  };
};

export const sendRFPDone = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    sendingRFP: false,
    sendingRFPDone: true,
    contract: action.contract,
  };
};

export const sendRFPFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    sendingRFP: false,
    sendingRFPDone: false,
    error: action.error,
  };
};

export const sendInvite = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    sendingInvite: true,
    sendingInviteDone: false,
  };
};

export const sendInviteDone = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    sendingInvite: false,
    sendingInviteDone: true,
    contract: action.contract,
  };
};

export const sendInviteFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    sendingInvite: false,
    sendingInviteDone: false,
    error: action.error,
  };
};

export const HANDLER = {
  [Types.FETCH_COMPANIES]: fetchCompanies,
  [Types.FETCH_COMPANIES_DONE]: fetchCompaniesDone,
  [Types.FETCH_COMPANIES_FAIL]: fetchCompaniesFail,

  [Types.FETCH_INDUSTRIES]: fetchIndustries,
  [Types.FETCH_INDUSTRIES_DONE]: fetchIndustriesDone,
  [Types.FETCH_INDUSTRIES_FAIL]: fetchIndustriesFail,

  [Types.FETCH_LOCATIONS]: fetchLocations,
  [Types.FETCH_LOCATIONS_DONE]: fetchLocationsDone,
  [Types.FETCH_LOCATIONS_FAIL]: fetchLocationsFail,

  [Types.SEND_RFP]: sendRFP,
  [Types.SEND_RFP_DONE]: sendRFPDone,
  [Types.SEND_RFP_FAIL]: sendRFPFail,

  [Types.SEND_INVITE]: sendInvite,
  [Types.SEND_INVITE_DONE]: sendInviteDone,
  [Types.SEND_INVITE_FAIL]: sendInviteFail,
};

export default createReducer(INITIAL_STATE, HANDLER);
