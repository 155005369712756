export default class AttachmentModel {
    /**
     * @var {String} id
     */
    id = '';
  
    /**
     * @var {String} title
     */
    title = 0;
  
    /**
     * @var {String} file
     */
    file = '';
  
    
  
    constructor() {
      this.toJson = this.toJson.bind(this);
    }
  
    /**
     * Converts this instance in json format
     * @returns {Object} The json format of this instance.
     */
    toJson() {
      return {
        pk: this.id,
        title: this.title,
        file: this.file,
        
      };
    }
  
    /**
     * Creates a new instance of AttachmentModel from a json data.
     * @param {Object} json The json data to parse into an AttachmentModel instance.
     * @returns {AttachmentModel}
     */
    static fromJson(json) {
      const model = new AttachmentModel();
  
      if (json !== null && json !== undefined) {
        model.id = json.pk;
        model.title = json.title;
        model.file = json.file;
       
      }
  
      return model;
    }
  }
  