
import { createReducer } from 'reduxsauce'
import {Types} from './actions'
const INITIAL_STATE = {
error: null, device: [], loading: false, apikey: 'API_KEY_HERE', fetchingApiKey: false
}

// reducers here

export const fetchApiKey = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        fetchingApiKey: true,
    }
}

export const fetchApiKeyDone = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        fetchingApiKey: false,
        apikey: action.apikey
    }
}

export const fetchApiKeyFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        fetchingApiKey: false,
        error: action.error
    }
}

export const fetchNewApiKey = (state = INITIAL_STATE, action) => {
    // will use fetApiKey(DONE | FAIL) for the rest
    return {
        ...state,
        fetchingApiKey: true,
    }
}


// init case
export const createDevice = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        //device: action.device,
        loading: true
    }
}


// good case
export const createDeviceDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false
    }
}


// bad case
export const createDeviceFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        error: action.error
    }
}


// init case
export const updateDevice = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
       // device: action.device,
        loading: true
    }
}


// good case
export const updateDeviceDone = (state = INITIAL_STATE, action ) => {
    
    return {
        ...state,
        //device: action.device,
        loading: false
    }
}


// bad case
export const updateDeviceFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        error: action.error,
        loading: false,
       // error: action.error
    }
}



// init case
export const fetchDevice = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true,
        //device: action.device
    }
}


// good case
export const fetchDeviceDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        device: action.device.results
    }
}


// bad case
export const fetchDeviceFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        
        loading: false,
        error: action.error
    }
}



// init case
export const deleteDevice = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        //device: action.device,
        loading: true
    }
}


// good case
export const deleteDeviceDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        //device: action.device,
        loading: false
    }
}


// bad case
export const deleteDeviceFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        device: null,
        //error: true,
        loading: false,
        error: action.error
    }
}
        
        

    // handlers
export const HANDLERS = {
    
    [Types.CREATE_DEVICE]: createDevice,
    [Types.CREATE_DEVICE_DONE]: createDeviceDone,
    [Types.CREATE_DEVICE_FAIL]: createDeviceFail,

    
    [Types.UPDATE_DEVICE]: updateDevice,
    [Types.UPDATE_DEVICE_DONE]: updateDeviceDone,
    [Types.UPDATE_DEVICE_FAIL]: updateDeviceFail,

    
    [Types.FETCH_DEVICE]: fetchDevice,
    [Types.FETCH_DEVICE_DONE]: fetchDeviceDone,
    [Types.FETCH_DEVICE_FAIL]: fetchDeviceFail,

    
    [Types.DELETE_DEVICE]: deleteDevice,
    [Types.DELETE_DEVICE_DONE]: deleteDeviceDone,
    [Types.DELETE_DEVICE_FAIL]: deleteDeviceFail,

    [Types.FETCH_API_KEY] : fetchApiKey,
    [Types.FETCH_API_KEY_DONE] : fetchApiKeyDone,
    [Types.FETCH_API_KEY_FAIL] : fetchApiKeyFail,

    [Types.FETCH_NEW_API_KEY] : fetchNewApiKey,

    
}
export default createReducer(INITIAL_STATE, HANDLERS)
