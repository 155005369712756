import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {Types, Creators} from './actions'
// api import
import {
    fetchNotificationApi,

} from './api'
// types import
const {FETCH_NOTIFICATIONS } = Types
// success, fail import
const {
    fetchNotificationsDone,
    fetchNotificationsFail,

 } = Creators

function* fetchNotificationsSaga(notification){
    
    const response = yield call(fetchNotificationApi, notification.notification);
    if (response.status){
        
        yield put(fetchNotificationsDone(response))
    }else{
        yield put(fetchNotificationsFail(response))
    }
}
//saga watchers

export function* watchFetchNotification(){
    yield takeEvery(FETCH_NOTIFICATIONS, fetchNotificationsSaga)
}

// saga builder

export default function* notificationSaga(){
    yield all([
        fork(watchFetchNotification),
       
    ])
}
