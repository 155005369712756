import Cookies from 'universal-cookie'; 
import {api, handle_response} from '../../helpers/api'
import _ from 'lodash'
const cookies = new Cookies();

export async function fetchPTOApi({pto}){
    const url = _.isUndefined(pto) ? '/pto-policy' : '/pto-policy/' + pto
    return await api.get(url).then(handle_response)
}

export async function createPTOApi(pto){
    const url = '/pto-policy'
    return await api.post(url, {...pto}).then(handle_response)
}

export async function deletePTOApi(pto_id){
    return await api.delete('/pto-policy/'+ pto_id.pto).then(handle_response)
}

export async function fetchWorkerPTOList(worker_id){
    // get all the policies available for a worker
    return api.get('/employee-pto-policy?employee_company__id='+worker_id).then(handle_response)
}

export async function updatePTOApi({pto}){
    return await api.patch('/pto-policy/'+pto.pk, {...pto}).then(handle_response)
}
export async function ptoLocationApi (ptoLocation){
    return await api.post('/policy-location', {...ptoLocation}).then(handle_response)
}

export async function employeePtoLocationApi ({employeePtoLocation}){
    return await api.post('/policy-location', {...employeePtoLocation}).then(handle_response)
}
export async function PtoTypeApi (){
    return await api.get('/pto-policy-type').then(handle_response)
}
export async function CreatePtoTypeApi (pto_type){
    return await api.post('/pto-policy-type', {...pto_type}).then(handle_response)
}

export async function updatePtoTypeApi (pto_type){
    return await api.patch('/pto-policy-type/'+pto_type.pk, {...pto_type}).then(handle_response)
}

export async function deletePtoTypeApi (pto_type){
    return await api.delete('/pto-policy-type/'+pto_type).then(handle_response)
}

/**
 * Import all worker details
 * @param {int} unique_id 
 */
export async function workerDetails(unique_id){
   return await api.get('/employees-details/'+unique_id).then(handle_response)
}  

export async function assignEmployeesApi(assignment){
    return await api.post('/pto-automatic-assignment', assignment).then(handle_response);
}

export async function getUserDetailsApi(){
    return await api.get('/user-detail').then(handle_response)
}