
import { createReducer } from 'reduxsauce'
import {Types} from './actions'


const INITIAL_STATE = {
    error: null, overtime: null, loading: false, creatingOvertime: false, 
    assignOvertimeStatus: '',  loadingImport: false,importObject: {},
    uploading: false,errorImport: null
}

// reducers here


// init case
export const createOvertime = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        //overtime: action.overtime,
        loading: true,
        
    }
}


// good case
export const createOvertimeDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        creatingOvertime: 'created'
    }
}


// bad case
export const createOvertimeFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        error: action.error,
        creatingOvertime: false
    }
}



// init case
export const updateOvertime = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
       // overtime: action.overtime,
        loading: true
    }
}


// good case
export const updateOvertimeDone = (state = INITIAL_STATE, action ) => {
    
    return {
        ...state,
        //overtime: action.overtime,
        loading: false
    }
}


// bad case
export const updateOvertimeFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        overtime: null,
        error: action.error,
        loading: false,
       // error: action.error
    }
}



// init case
export const fetchOvertime = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true,
        //overtime: action.overtime
    }
}


// good case
export const fetchOvertimeDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        overtime: action.overtime
    }
}


// bad case
export const fetchOvertimeFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        overtime: null,
        loading: false,
        error: action.error
    }
}



// init case
export const deleteOvertime = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        //overtime: action.overtime,
        loading: true
    }
}


// good case
export const deleteOvertimeDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        //overtime: action.overtime,
        loading: false
    }
}


// bad case
export const deleteOvertimeFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        overtime: null,
       // error: true,
        loading: false,
        error: action.error
    }
}
    

export const assignEmployeesOvertime = (state = INITIAL_STATE , action ) => {
    return {
        ...state,
        loading: true,
        assignOvertimeStatus: 'PENDING',
    }
}


export const assignEmployeesOvertimeDone = (state = INITIAL_STATE , action ) => {
    return {
        ...state,
        loading: false,
        assignOvertimeStatus: 'DONE',
    }
}


export const assignEmployeesOvertimeFail = (state = INITIAL_STATE , action ) => {
    return {
        ...state,
        loading: false,
        assignOvertimeStatus: 'FAIL',
    }
}   

export const uploadOvertime = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loadingImport: true,
        uploading: true
    }
}

export const uploadOvertimeDone = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        
        loadingImport: false,
        importObject: action.obj,
        uploading: false,
        errorImport: null
    }
}

export const uploadOvertimeFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loadingImport: false,
        uploading: false,
        errorImport: action.error
    }
}
    // handlers
export const HANDLERS = {
    
    [Types.CREATE_OVERTIME]: createOvertime,
    [Types.CREATE_OVERTIME_DONE]: createOvertimeDone,
    [Types.CREATE_OVERTIME_FAIL]: createOvertimeFail,

    
    [Types.UPDATE_OVERTIME]: updateOvertime,
    [Types.UPDATE_OVERTIME_DONE]: updateOvertimeDone,
    [Types.UPDATE_OVERTIME_FAIL]: updateOvertimeFail,

    
    [Types.FETCH_OVERTIME]: fetchOvertime,
    [Types.FETCH_OVERTIME_DONE]: fetchOvertimeDone,
    [Types.FETCH_OVERTIME_FAIL]: fetchOvertimeFail,

    
    [Types.DELETE_OVERTIME]: deleteOvertime,
    [Types.DELETE_OVERTIME_DONE]: deleteOvertimeDone,
    [Types.DELETE_OVERTIME_FAIL]: deleteOvertimeFail,

    [Types.ASSIGN_EMPLOYEES_OVERTIME]: assignEmployeesOvertime,
    [Types.ASSIGN_EMPLOYEES_OVERTIME_DONE]: assignEmployeesOvertimeDone,
    [Types.ASSIGN_EMPLOYEES_OVERTIME_FAIL]: assignEmployeesOvertimeFail,

    [Types.UPLOAD_OVERTIME]: uploadOvertime,
    [Types.UPLOAD_OVERTIME_DONE]: uploadOvertimeDone,
    [Types.UPLOAD_OVERTIME_FAIL]: uploadOvertimeFail,

    
}
export default createReducer(INITIAL_STATE, HANDLERS)
