import StateModel from './state';

export default class LocationModel {
  /**
   * @var {String}
   */
  id = '';

  /**
   * @type {String}
   */
  name = '';

  /**
   * @type {String}
   */
  minimumRate = '';

  /**
   * @type {StateModel}
   */
  state = null;

  /**
   * @type {String}
   */
  city = '';

  /**
   * @type {Boolean}
   */
  isDefault = true;

  /**
   * @type {Number}
   */
  defaultYearlyRate = 0;

  /**
   * @type {Number}
   */
  defaultHourlyRate = 0;

  /**
   * @type {String}
   */
  timezone = '';

  /**
   * @type {Number}
   */
  workWeekStart = 0;

  /**
   * @type {String}
   */
  regularStartTime = '';

  /**
   * @type {String}
   */
  regularEndTime = '';

  /**
   * @type {{}}
   */
  fullTimezone = {};

  constructor() {
    this.toJson = this.toJson.bind(this);

    // address read-only property
    Object.defineProperty(this, 'address', {
      configurable: false,
      enumerable: false,
      get: () => `${this.city}, ${this.state.state}, ${this.state.country}`,
    });
  }

  /**
   * Converts this instance in json format
   * @returns {Object} The json format of this instance.
   */
  toJson() {
    return {
      pk: this.id,
      minimum_rate: this.minimumRate,
      name: this.name,
      state: this.state.toJson(),
      city: this.city,
      default: this.isDefault,
      default_yearly_rate: this.defaultYearlyRate,
      default_hourly_rate: this.defaultHourlyRate,
      timezone: this.timezone,
      work_week_start: this.workWeekStart,
      regular_start_time: this.regularStartTime,
      regular_end_time: this.regularEndTime,
      full_timezone: this.fullTimezone,
    };
  }

  /**
   * Creates a new instance of LocationModel from a json data.
   * @param {Object} json The json data to parse into an LocationModel instance.
   * @returns {LocationModel}
   */
  static fromJson(json) {
    const model = new LocationModel();

    if (json !== null && json !== undefined) {
      model.id = json.pk;
      model.minimumRate = json.minimum_rate;
      model.name = json.name;
      model.state = StateModel.fromJson(json.state);
      model.city = json.city;
      model.isDefault = json.default;
      model.defaultYearlyRate = json.default_yearly_rate;
      model.defaultHourlyRate = json.default_hourly_rate;
      model.timezone = json.timezone;
      model.workWeekStart = json.work_week_start;
      model.regularStartTime = json.regular_start_time;
      model.regularEndTime = json.regular_end_time;
      model.fullTimezone = json.full_timezone;
    }

    return model;
  }
}
