
import { createReducer } from 'reduxsauce'
import {Types} from './actions'
const INITIAL_STATE = {
    error: null, departments: [], loading: false,
    departmentToAssign: null, departmentAssignationStatus: '',
    errorCreation : null, errorUpdate: null, message : null,
    loadingImport: false,importObject: {},uploading: false,errorImport: null,
    departmentUdpating: null
}

// reducers here


export const assignToDepartment = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true,
        errorCreation : null,
        errorUpdate : null,
        error : null,
        message : null
    }
}

export const assignToDepartmentDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        departmentAssignationStatus: 'DONE',
        errorCreation : null,
        errorUpdate : null,
        error : null,
        message : "Assign Successfull"
    }
}

export const assignToDepartmentFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        departmentAssignationStatus: 'FAIL',
        message : "Assign Fail"
    }
}

// init case
export const createDepartment = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true,
        errorCreation : null,
        errorUpdate : null,
        error : null,
        message :null
    }
}


// good case
export const createDepartmentDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        errorCreation : null,
        errorUpdate : null,
        error : null,
        message : "Department Creation Successful"

    }
}


// bad case
export const createDepartmentFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        errorCreation: action.error,
        errorUpdate : null,
        error : null,
        message : "Department Creation Fail"

    }
}



// init case
export const updateDepartment = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true,
        errorCreation : null,
        errorUpdate : null,
        error : null,
        message :null,
        departmentUdpating: null,
    }
}


// good case
export const updateDepartmentDone = (state = INITIAL_STATE, action ) => {
    
    return {
        ...state,
        loading: false,
        errorCreation : null,
        errorUpdate : null,
        error : null,
        message : "Department Update Successful",
        departmentUdpating: action.department,

    }
}


// bad case
export const updateDepartmentFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        //error: true,
       // department: null,
        errorUpdate: action.error,
        loading: false,
        errorCreation : null,
        error : null,
        message : "Department Update Fail",
        departmentUdpating: null

    }
}


// init case
export const fetchDepartment = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true,
        errorCreation : null,
        errorUpdate : null,
        error : null,
        message : null
    }
}


// good case
export const fetchDepartmentDone = (state = INITIAL_STATE, action ) => {
    
    return {
        ...state,
        loading: false,
        departments: action.departments,
        errorCreation : null,
        errorUpdate : null,
        error : null,
        message : null

    }
}


// bad case
export const fetchDepartmentFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        error: action.error,
        errorCreation : null,
        errorUpdate : null,
        message : null

    }
}



// init case
export const deleteDepartment = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true,
        errorCreation : null,
        errorUpdate : null,
        error : null,
        message :null

    }
}


// good case
export const deleteDepartmentDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        errorCreation : null,
        errorUpdate : null,
        error : null,
        message : "Department Delete successful"

    }
}


// bad case
export const deleteDepartmentFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        error: action.error,
        errorCreation : null,
        errorUpdate : null,
        message : "Department Delete Fail"

    }
}



export const uploadDepartment = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loadingImport: true,
        uploading: true
    }
}

export const uploadDepartmentDone = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        
        loadingImport: false,
        importObject: action.obj,
        uploading: false,
        errorImport: null
    }
}

export const uploadDepartmentFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loadingImport: false,
        uploading: false,
        errorImport: action.error
    }
}
    // handlers
export const HANDLERS = {
    
    [Types.CREATE_DEPARTMENT]: createDepartment,
    [Types.CREATE_DEPARTMENT_DONE]: createDepartmentDone,
    [Types.CREATE_DEPARTMENT_FAIL]: createDepartmentFail,
    
    [Types.UPDATE_DEPARTMENT]: updateDepartment,
    [Types.UPDATE_DEPARTMENT_DONE]: updateDepartmentDone,
    [Types.UPDATE_DEPARTMENT_FAIL]: updateDepartmentFail,
    
    [Types.FETCH_DEPARTMENT]: fetchDepartment,
    [Types.FETCH_DEPARTMENT_DONE]: fetchDepartmentDone,
    [Types.FETCH_DEPARTMENT_FAIL]: fetchDepartmentFail,

    [Types.DELETE_DEPARTMENT]: deleteDepartment,
    [Types.DELETE_DEPARTMENT_DONE]: deleteDepartmentDone,
    [Types.DELETE_DEPARTMENT_FAIL]: deleteDepartmentFail,

    [Types.ASSIGN_DEPARTMENT]: assignToDepartment,
    [Types.ASSIGN_DEPARTMENT_DONE]: assignToDepartmentDone,
    [Types.ASSIGN_DEPARTMENT_FAIL]: assignToDepartmentFail,

    [Types.UPLOAD_DEPARTMENT]: uploadDepartment,
    [Types.UPLOAD_DEPARTMENT_DONE]: uploadDepartmentDone,
    [Types.UPLOAD_DEPARTMENT_FAIL]: uploadDepartmentFail,

}
export default createReducer(INITIAL_STATE, HANDLERS)
