
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {Types, Creators} from './action'

// types import
const { STORE_ADDRESS,GET_ADDRESS } = Types
// success, fail import

const {
    storeAddress,
    getAddress, 
    
} = Creators

// sagas handler

function* storeSaga(action){
    yield put(storeAddress(action))
}

function* getSaga(action){
    yield put(getAddress(action))
}



export function* watchStoreRole(){
    yield takeEvery(STORE_ADDRESS, storeSaga)
}

export function* watchGetRole(){
    yield takeEvery(GET_ADDRESS, getSaga)
}

    
// saga builder

export default function* searchSaga(){
    yield all([
        fork(watchStoreRole),
        fork(watchGetRole),
    ])
}
    


    