import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {Types, Creators} from './actions'
import Cookies  from 'universal-cookie'
// api import
import { 
createHolidayApi, 
updateHolidayApi, 
fetchHolidayApi, 
deleteHolidayApi,
assignToHolidayApi
} from './api'
// types import
const { CREATE_HOLIDAY,UPDATE_HOLIDAY,FETCH_HOLIDAY,DELETE_HOLIDAY, ASSIGN_EMPLOYEES_TO_HOLIDAY} = Types
// success, fail import
const { 
    createHolidayDone,
    createHolidayFail, 
    updateHolidayDone,
    updateHolidayFail, 
    fetchHolidayDone,
    fetchHolidayFail, 
    deleteHolidayDone,
    deleteHolidayFail,
    fetchHoliday,
    assignEmployeesToHolidayDone,
    assignEmployeesToHolidayFail
 } = Creators

// sagas handler

function* createHolidaySaga(holiday){
    const response = yield call(createHolidayApi, holiday);
    if (response.status){
        yield put(createHolidayDone(response))
        yield put(fetchHoliday());
    }else{
        yield put(createHolidayFail(response))
    }
}

function* updateHolidaySaga(holiday){
    const response = yield call(updateHolidayApi, holiday);
    if (response.status){
        yield put(updateHolidayDone(response))
        yield put(fetchHoliday());
    }else{
        yield put(updateHolidayFail(response))
    }
}

function* fetchHolidaySaga(){
    const response = yield call(fetchHolidayApi);
    if (response.status){
        let cookies = new Cookies()
       // cookies.set('departments', response.data, {path:"/"})
        yield put(fetchHolidayDone(response.data.results))
    }else{
        yield put(fetchHolidayFail(response.data))
    }
}

function* deleteHolidaySaga(holiday){
    const response = yield call(deleteHolidayApi, holiday);
    if (response.status){
        yield put(deleteHolidayDone(response))
        yield put(fetchHoliday());

    }else{
        yield put(deleteHolidayFail(response))
    }
}

function* assignHolidaySaga(holiday){
    const response = yield call(assignToHolidayApi, holiday);
    if (response.status){
        yield put(assignEmployeesToHolidayDone(response.data))
    }else{
        yield put(assignEmployeesToHolidayFail(response.data))
    }
}
    
//saga watchers

export function* watchcreateHoliday(){
    yield takeEvery(CREATE_HOLIDAY, createHolidaySaga)
}

export function* watchupdateHoliday(){
    yield takeEvery(UPDATE_HOLIDAY, updateHolidaySaga)
}

export function* watchfetchHoliday(){
    yield takeEvery(FETCH_HOLIDAY, fetchHolidaySaga)
}

export function* watchdeleteHoliday(){
    yield takeEvery(DELETE_HOLIDAY, deleteHolidaySaga)
}


export function* watchAssignHoliday(){
    yield takeEvery(ASSIGN_EMPLOYEES_TO_HOLIDAY, assignHolidaySaga)
}

    
// saga builder

export default function* HolidaySaga(){
    yield all([
        fork(watchcreateHoliday),
        fork(watchupdateHoliday),
        fork(watchfetchHoliday),
        fork(watchdeleteHoliday),
        fork(watchAssignHoliday)
    ])
}