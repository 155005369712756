import { createActions } from 'reduxsauce'

export const {Types, Creators} = createActions({
        
    createPTO: ['pto'],
    createPTODone: ['pto'],
    createPTOFail: ['error'],
    
    updatePTO: ['pto'],
    updatePTODone: ['pto'],
    updatePTOFail: ['error'],
    
    fetchPTO: ['pto'],
    fetchPTODone: ['pto'],
    fetchPTOFail: ['error'],
    
    deletePTO: ['pto'],
    deletePTODone: ['pto'],
    deletePTOFail: ['error'],

    fetchWorkerPTOList: ['worker_id'],
    fetchWorkerPTOListDone: ['ptos'],
    fetchWorkerPTOListFail: ['error'],

    fetchDetailsWorker: ['worker'],
    fetchDetailsWorkerDone: ['payload'],
    fetchDetailsWorkerFail: ['error'],

    fetchUserDetails: ['details'],
    fetchUserDetailsDone: ['details'],
    fetchUserDetailsFail: ['error'],
    
    ptoLocation : ['ptoLocation'],
    ptoLocationDone : ['ptoLocation'],
    ptoLocationFail : ['error'],

    employeePtoLocation : ['employeePtoLocation'],
    employeePtoLocationDone : ['employeePtoLocation'],
    employeePtoLocationFail : ['error'],

    createPtoType: ['ptoType'],
    createPtoTypeDone: ['ptoType'],
    createPtoTypeFail: ['error'],

    updatePtoType: ['ptoType'],
    updatePtoTypeDone: ['ptoType'],
    updatePtoTypeFail: ['error'],

    deletePtoType: ['ptoType'],
    deletePtoTypeDone: ['ptoType'],
    deletePtoTypeFail: ['error'],

    fetchPtoType: ['ptoType'],
    fetchPtoTypeDone: ['ptoTypes'],
    fetchPtoTypeFail: ['error'],

    assignEmployees: ['assignment'],
    assignEmployeesDone: null,
    assignEmployeesFail: ['error'],
});
    