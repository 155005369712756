import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {Types, Creators} from './actions'
import Cookies  from 'universal-cookie'
// api import
import { 
computationPayrollApi, 
computationTimeSheetApi,
computationResetPayrollApi,
computationResetTimeSheetApi
} from './api'
// types import
const { TIMESHEET_COMPUTATION,PAYROLL_COMPUTATION, TIMESHEET_RESET_COMPUTATION, PAYROLL_RESET_COMPUTATION} = Types
// success, fail import
const { 
    timesheetComputationDone,
    timesheetComputationFail, 
    payrollComputationDone,
    payrollComputationFail, 

    timesheetResetComputationDone,
    timesheetResetComputationFail, 
    payrollResetComputationDone,
    payrollResetComputationFail,
    
 } = Creators

// sagas handler

function* computationTimeSheetSaga(timeSheet){
    const response = yield call(computationTimeSheetApi, timeSheet);
    if (response.status){
        yield put(timesheetComputationDone(response))
    }else{
        yield put(timesheetComputationFail(response))
    }
}

function* computationPayrollSaga(payroll){
    const response = yield call(computationPayrollApi, payroll);
    if (response.status){
        yield put(payrollComputationDone());
    }else{
        yield put(payrollComputationFail(response))
    }
}
function* computationResetTimeSheetSaga(timeSheet){
    const response = yield call(computationResetTimeSheetApi, timeSheet);
    if (response.status){
        yield put(timesheetResetComputationDone(response))
    }else{
        yield put(timesheetResetComputationFail(response))
    }
}

function* computationResetPayrollSaga(payroll){
    const response = yield call(computationResetPayrollApi, payroll);
    if (response.status){
        yield put(payrollResetComputationDone());
    }else{
        yield put(payrollResetComputationFail(response))
    }
}

    
//saga watchers

export function* watchComputationTimeSheet(){
    yield takeEvery(TIMESHEET_COMPUTATION, computationTimeSheetSaga)
}

export function* watchComputationPayroll(){
    yield takeEvery(PAYROLL_COMPUTATION, computationPayrollSaga)
}

//saga watchers

export function* watchComputationResetTimeSheet(){
    yield takeEvery(TIMESHEET_RESET_COMPUTATION, computationResetTimeSheetSaga)
}

export function* watchComputationResetPayroll(){
    yield takeEvery(PAYROLL_RESET_COMPUTATION, computationResetPayrollSaga)
}
    
// saga builder

export default function* PayrollSaga(){
    yield all([
        fork(watchComputationTimeSheet),
        fork(watchComputationPayroll),
        fork(watchComputationResetTimeSheet),
        fork(watchComputationResetPayroll)
    ])
}