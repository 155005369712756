
import { createReducer } from 'reduxsauce'
import {Types} from './actions'
import PayrollModel from '../../models/PayrollModel'
const INITIAL_STATE = {
    error: null, payrolls: [], loading: false
}

// reducers here


// init case
export const createPayroll = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true,
    }
}


// good case
export const createPayrollDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
    }
}


// bad case
export const createPayrollFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        error: action.error
    }
}



// init case
export const updatePayroll = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true
    }
}


// good case
export const updatePayrollDone = (state = INITIAL_STATE, action ) => {
    
    return {
        ...state,
        loading: false
    }
}


// bad case
export const updatePayrollFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
       // payrolls: null,
        error: action.error,
        loading: false,
    }
}


// init case
export const fetchPayroll = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true,
    }
}


// good case
export const fetchPayrollDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        payrolls: PayrollModel.fromJsonList(action.payroll.results)
    }
}


// bad case
export const fetchPayrollFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        payrolls: null,
        loading: false,
        error: action.error
    }
}


// init case
export const deletePayroll = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true
    }
}


// good case
export const deletePayrollDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false
    }
}


// bad case
export const deletePayrollFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        error: action.error
    }
}
    // handlers
export const HANDLERS = {
    
    [Types.CREATE_PAYROLL]: createPayroll,
    [Types.CREATE_PAYROLL_DONE]: createPayrollDone,
    [Types.CREATE_PAYROLL_FAIL]: createPayrollFail,
    
    [Types.UPDATE_PAYROLL]: updatePayroll,
    [Types.UPDATE_PAYROLL_DONE]: updatePayrollDone,
    [Types.UPDATE_PAYROLL_FAIL]: updatePayrollFail,
    
    [Types.FETCH_PAYROLL]: fetchPayroll,
    [Types.FETCH_PAYROLL_DONE]: fetchPayrollDone,
    [Types.FETCH_PAYROLL_FAIL]: fetchPayrollFail,

    [Types.DELETE_PAYROLL]: deletePayroll,
    [Types.DELETE_PAYROLL_DONE]: deletePayrollDone,
    [Types.DELETE_PAYROLL_FAIL]: deletePayrollFail,

}
export default createReducer(INITIAL_STATE, HANDLERS)
