export default class StateModel {
  /**
   * @var {String} id
   */
  id = '';

  /**
   * @var {Number} rate
   */
  rate = 0;

  /**
   * @var {String} country
   */
  country = '';

  /**
   * @var {String} state
   */
  state = '';

  /**
   * @var {String} stateCode
   */
  stateCode = '';

  /**
   * @var {String} timezone
   */
  timezone = '';

  constructor() {
    this.toJson = this.toJson.bind(this);
  }

  /**
   * Converts this instance in json format
   * @returns {Object} The json format of this instance.
   */
  toJson() {
    return {
      pk: this.id,
      rate: this.rate,
      country: this.country,
      state: this.state,
      code_state: this.stateCode,
      timezone: this.timezone,
    };
  }

  /**
   * Creates a new instance of StateModel from a json data.
   * @param {Object} json The json data to parse into an StateModel instance.
   * @returns {StateModel}
   */
  static fromJson(json) {
    const model = new StateModel();

    if (json !== null && json !== undefined) {
      model.id = json.pk;
      model.rate = json.rate;
      model.country = json.country;
      model.state = json.state;
      model.stateCode = json.code_state;
      model.timezone = json.timezone;
    }

    return model;
  }
}
