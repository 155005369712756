import {api, handle_response} from '../../helpers/api'
import Cookies from 'universal-cookie'

const cookies = new Cookies()
export async function fetchDashboardApi(){
    api.setHeaders(
        {
            'Tenant': cookies.get('user').user.company.domains[0].domain
        }
    )
    return await api.get('/dashboard').then(handle_response);
}