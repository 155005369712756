import { createActions } from 'reduxsauce'


export const {Types, Creators} = createActions({
    fetchLeaves: null,
    fetchLeavesDone: ['leaves'],
    fetchLeavesFail: ['error'],

    fetchLeavesToValidate: null,
    fetchLeavesToValidateDone: ['leaves'],
    fetchLeavesToValidateFail: ['error'],

    acceptLeave: ['id'],
    acceptLeaveDone: ['leave'],
    acceptLeaveFail: ['error'],

    rejectLeave: ['id'],
    rejectLeaveDone: ['leave'],
    rejectLeaveFail: ['error'],

    createLeave: ['leave'],
    createLeaveDone: ['leave'],
    createLeaveFail: ['error'],

    updateLeave: ['leave'],
    updateLeaveDone: ['leave'],
    updateLeaveFail: ['error'],

    deleteLeave: ['leave'],
    deleteLeaveDone: ['leave'],
    deleteLeaveFail: ['error'],

    fetchEmployeeLeave: ['employeeLeaves'],
    fetchEmployeeLeaveDone: ['employeeLeaves'],
    fetchEmployeeLeaveFail: ['errorEmployeeLeave']
})