
import { createReducer } from 'reduxsauce'
import {Types} from './actions'
import {normalizeHoliday} from '../../helpers/utils'
const INITIAL_STATE = {
error: null, holidays: [], loading: false, loadingAssignSuccess: null
}

// reducers here


// init case
export const createHoliday = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true,
    }
}


// good case
export const createHolidayDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
    }
}


// bad case
export const createHolidayFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        error: action.error
    }
}



// init case
export const updateHoliday = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true
    }
}


// good case
export const updateHolidayDone = (state = INITIAL_STATE, action ) => {
    
    return {
        ...state,
        loading: false
    }
}


// bad case
export const updateHolidayFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        holidays: null,
        error: true,
        loading: false,
    }
}


// init case
export const fetchHoliday = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true,
    }
}


// good case
export const fetchHolidayDone = (state = INITIAL_STATE, action ) => {
    
    return {
        error: null,
        loading: false,
        holidays: normalizeHoliday(action.holiday)
    }
}


// bad case
export const fetchHolidayFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        holiday: null,
        loading: false,
        error: action.error
    }
}



// init case
export const deleteHoliday = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true
    }
}


// good case
export const deleteHolidayDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false
    }
}


// bad case
export const deleteHolidayFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        error: action.error
    }
}

export const assignToHoliday = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: true,
        loadingAssignSuccess: null
    }
}

export const assignToHolidayDone = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        loadingAssignSuccess: true
    }
}

export const assignToHolidayFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        loadingAssignSuccess: false
    }
}
    // handlers
export const HANDLERS = {
    
    [Types.CREATE_HOLIDAY]: createHoliday,
    [Types.CREATE_HOLIDAY_DONE]: createHolidayDone,
    [Types.CREATE_HOLIDAY_FAIL]: createHolidayFail,
    
    [Types.UPDATE_HOLIDAY]: updateHoliday,
    [Types.UPDATE_HOLIDAY_DONE]: updateHolidayDone,
    [Types.UPDATE_HOLIDAY_FAIL]: updateHolidayFail,
    
    [Types.FETCH_HOLIDAY]: fetchHoliday,
    [Types.FETCH_HOLIDAY_DONE]: fetchHolidayDone,
    [Types.FETCH_HOLIDAY_FAIL]: fetchHolidayFail,

    [Types.DELETE_HOLIDAY]: deleteHoliday,
    [Types.DELETE_HOLIDAY_DONE]: deleteHolidayDone,
    [Types.DELETE_HOLIDAY_FAIL]: deleteHolidayFail,


    [Types.ASSIGN_EMPLOYEES_TO_HOLIDAY]: assignToHoliday,
    [Types.ASSIGN_EMPLOYEES_TO_HOLIDAY_DONE]: assignToHolidayDone,
    [Types.ASSIGN_EMPLOYEES_TO_HOLIDAY_FAIL]: assignToHolidayFail,

}
export default createReducer(INITIAL_STATE, HANDLERS)
