// @flow
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILED,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILED,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_FAILED,
    VERIFY,
    VERIFY_SUCCESS,
    VERIFY_FAILED,
    CREATE_COMPANY,
    COMPAGNY_CREATE_SUCCESS,
    COMPAGNY_CREATE_FAILED,
    COMPANY_DONT_EXIST,
    EMAIL_ALREADY_EXIST,
    SET_SHOW_TO_FALSE,
    GET_USER_DETAIL,
    GET_USER_DETAIL_FAIL,
    GET_USER_DETAIL_DONE,
    GET_USER_ACCOUNT_DETAILS,
    GET_USER_ACCOUNT_DETAILS_DONE,
    GET_USER_ACCOUNT_DETAILS_FAIL
} from './constants';


export const getUserDetail = () => ({
    type: GET_USER_DETAIL
})

export const getUserDetailDone = (data) => ({
    type: GET_USER_DETAIL_DONE,
    user: data
})

export const getUserDetailFail = (error) => ({
    type: GET_USER_DETAIL_FAIL,
    error: error
})

export const getUserAccountDetail = () => ({
    type: GET_USER_ACCOUNT_DETAILS
})

export const getUserAccountDetailDone = (data) => ({
    type: GET_USER_ACCOUNT_DETAILS_DONE,
    user: data
})

export const getUserAccountDetailFail = (error) => ({
    type: GET_USER_ACCOUNT_DETAILS_FAIL,
    error: error
})

export const loginUser = (login, password) => ({
    type: LOGIN_USER,
    payload: { login, password },
});

export const loginUserSuccess = (user) => ({
    type: LOGIN_USER_SUCCESS,
    payload: user,
});

export const loginUserFailed = (error) => ({
    type: LOGIN_USER_FAILED,
    payload: error,
});

export const registerUser = (fullname, email, public_phone) => ({
    type: REGISTER_USER,
    payload: { fullname, email, public_phone },
});

export const registerUserSuccess = (user) => ({
    type: REGISTER_USER_SUCCESS,
    payload: user,
});

export const registerUserFailed = (error) => ({
    type: REGISTER_USER_FAILED,
    payload: error,
});

export const logoutUser = (history) => ({
    type: LOGOUT_USER,
    payload: { history },
});

export const forgetPassword = (username) => ({
    type: FORGET_PASSWORD,
    payload: { username },
});

export const forgetPasswordSuccess = (passwordResetStatus) => ({
    type: FORGET_PASSWORD_SUCCESS,
    payload: passwordResetStatus,
});

export const forgetPasswordFailed = (error) => ({
    type: FORGET_PASSWORD_FAILED,
    payload: error,
});
export const verify = (code) => ({
    type: VERIFY,
    payload: { code },
});

export const verifySuccess = (codeVerificationStatus) => ({
    type: VERIFY_SUCCESS,
    payload: codeVerificationStatus,
});

export const verifyFailed = (error) => ({
    type: VERIFY_FAILED,
    payload: error,
});

export const createCompany = (company) => ({
    type: CREATE_COMPANY,
    payload: company
})

export const compagnyCreateSuccess = (result) => ({
    type: COMPAGNY_CREATE_SUCCESS,
    payload: result,
});
export const compagnyCreateFailed = (error) => ({
    type: COMPAGNY_CREATE_FAILED,
    payload: error,
});
export const compagnyDontExist = () => ({
  type: COMPANY_DONT_EXIST,
});
export const emailAlreadyExist = (error) => ({
  type: EMAIL_ALREADY_EXIST,
  payload : error,
});

export const setShowToFalse = () => ({
  type: SET_SHOW_TO_FALSE
});
