import _ from 'lodash';
import moment from 'moment';
import { create } from 'apisauce';
import { handle_response } from './api'
import { format, parse, isSameDay, differenceInBusinessDays } from 'date-fns'
import { ToastItem } from '../components/Toaster';

/**
 * Initialize api for timezone
 */
const apiUrl = create({
    baseURL: 'https://maps.googleapis.com/maps/api/timezone',
});

/**
 * Parses the given input as a moment in the current timezone.
 * @param {moment.MomentInput} input The moment input
 */
export function moment_tz(input) {
    return moment(input).subtract(moment().utcOffset(), 'minutes');
}

export async function getTimezone(address) {
    let date = new Date();
    let timestamp = Math.round(+new Date() / 1000);
    const url =
        '/json?location=' +
        address.geometry.location.lat() +
        ',' +
        address.geometry.location.lng() +
        '&timestamp=' +
        timestamp +
        '&key=AIzaSyBhkz99Q2DK_ufaSGh7Rx2SUgDWSppwPBI';
    return await apiUrl.get(url).then(handle_response);
}
/**
 * Get State from Google Address
 * @param {any} address 
 */
export function getStateFromAddress(address) {
    let states = !_.isEmpty(address) && _.filter(address.address_components, function (address) {
        return _.includes(address.types, "locality") && address.long_name
    })
    return _.size(states) > 0 ? states[0].long_name : ""
}
/**
 * Get City from Google Address
 * @param {any} address 
 */
export function getCityFromAddress(address) {
    let city = !_.isEmpty(address) && _.filter(address.address_components, function (address) {
        return _.includes(address.types, "administrative_area_level_1") && address.long_name
    })
    return city
}
/**
 * Match Google State with our state json to get state with minimum rate  
 * @param {String} cityAddress 
 * @param {Array} rates 
 */
export function getStateAndRateFromAddress(cityAddress, rates) {

    let state = _.filter(rates, function (r) {
        return _.lowerCase(r.code_state) == _.lowerCase(cityAddress[0].short_name)
    })
    return state[0]
}
/**
 * Get Country from Google Address
 * @param {any} address 
 */
export function getCountryFromAddress(address) {
    let country = !_.isEmpty(address) && _.filter(address.address_components, function (address) {
        return _.includes(address.types, "country") && address.long_name
    })
    return _.size(country) > 0 ? country[0].long_name : ""
}
/**
 * Reformat Shift object 
 * @param {any} shiftlist 
 * 
 */
export function normalizeShift(shiftlist) {
    return !_.isEmpty(shiftlist.results)
        ? _.transform(
            shiftlist.results,
            function (shifts, value, key) {
                let start = value.start;
                let end = value.end;
                //    let days =  _.map(value.days, function(d, index){
                //         return d + 1
                //    })
                var ms = moment(end, 'HH:mm:ss').diff(moment(start, 'HH:mm:ss'));
                var d = moment.duration(ms);
                var s = Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');
                var background = 'grey';
                if (value.capacity >= _.size(value.employee)) {
                    //shift open
                    background = '#5369f8';
                } else {
                    background = '#ff5c75';
                }

                //let newShift = _.omit(value,['start', 'end'])
                let newShift = value;

                shifts.push(
                    (shifts[key] = {
                        ...newShift,
                        // days : days,
                        title: newShift.name,
                        id: newShift.id,
                        rrule: {
                            ...newShift.rrule,
                            dtstart: newShift.start_timeframe,
                        },
                        duration: s,
                        color: background,
                        // start : newShift.start_timeframe+"T"+start,
                        // end : newShift.end_timeframe+"T"+end
                        //start: value.fullstart,
                        // end: value.fullend
                    })
                );
            },
            []
        )
        : [];
}

/**
 * Reformat holidays object
 * @param {Array} holidays 
 */
export function normalizeHoliday(holidays) {
    return _.transform(holidays, function (holidays, value, key) {
        holidays.push({
            ...value,
            rrule: value.req_rule,
        });
    });
}
/**
 * Reformat Locations objects
 * @param {Array} locations 
 */
export function normalizeLocations(locations) {
    return _.uniq(
        _.transform(locations, function (locations, value, key) {
            locations.push(
                (locations[key] = {
                    ...value,
                    default_dayly_rate: '$ ' + value.default_dayly_rate,
                    default_hourly_rate: '$ ' + value.default_hourly_rate,
                })
            );
        })
    );
}
/**
 * Reformat Ptos objects
 * @param {Array} ptos 
 */
export function normalizePto(ptos) {
    let ptoTypes = _.uniqBy(
        _.map(ptos, function (p, i) {
            return p.root.type;
        }),
        'pk'
    );
    return _.transform(ptoTypes, function (pt, value, key) {
        let pto = _.filter(ptos, function (p, i) {
            return p.root.type.pk === value.pk;
        });
        let new_pto = _.map(pto, function (p, i) {
            return {
                ..._.omit(p, ['root']),
                ...p.root,
            };
        });
        pt.push({
            ...value,
            pto_policies: new_pto,
        });
    });
}
export const FLATPICKR_DATETIME_FORMAT = 'Y-m-d h:i';
export const FLATPICKR_DATE_FORMAT = 'Y-m-d';
export const FLATPICKR_TIME_FORMAT = 'h:i';

// to use only with date-fns
export const DATETIME_FORMAT = 'yyyy-MM-dd HH:mm';
export const DATETIME_FORMAT_SEC = 'yyyy-MM-dd HH:mm:ss';
export const DATETIME_FORMAT_TZ = 'yyyy-MM-dd HH:mm:ssXXXXX';
export const DATETIME_FORMAT_TZ_WT = 'yyyy-MM-ddTHH:mm:ssXXXXX';
export const DATE_FORMAT = 'yyyy-MM-dd';
export const TIME_FORMAT = 'HH:mm';
export const TIME_FORMATS = 'HH:mm:ss';
export const USER_FORMAT = 'EEEEEE do MMM';
export const USER_FORMAT_YEAR = 'EEEEEE do MMM yyyy';
export const USER_FORMAT_DT = 'EEEEEE do MMM HH:mm:ss';
export const USER_FRIENDLY_FORMAT = 'EEEEEE do MMM';

export function formatDJDate(d) {
    // if ( !_.isEmpty(d)) return '--  Not Needed --'
    if (d === null || d.getFullYear() === 1970) return '--  Not Needed --';
    let g = new Date(d).toISOString();
    return `${g.substr(0, 10)}`;
}
/**
 * 
 * @param {any} time 
 */
export function toUTCTimezone(time) {
    return time
        .split('+')
        .map((i, j) => {
            if (j === 0) return i;
            return parseInt(i.split(':')[0]);
        })
        .join(' UTC+');
}
export function calculPtoDuration(deadlines) {
    return differenceInBusinessDays(new Date(deadlines[1]), new Date(deadlines[0]));
}
export function calculLeaveDuration(start, end) {
    return differenceInBusinessDays(new Date(end), new Date(start));
}
export function sumDateLeaveOfPto(ptoLeaves) {
    let sum = 0;
    _.map(ptoLeaves, function (pl, i) {
        sum += calculLeaveDuration(pl.start, pl.end);
    });
    return sum;
}
export function getStatus(number) {
    let status = true;
    if (number <= 0) {
        status = false;
    }
    return status;
}
export function getDatas(p) {
    let number = calculPtoDuration(p.root_policy.deadlines) - sumDateLeaveOfPto(p.pto_leaves);
    let unit = 'Days';
    let status = true;
    switch (number) {
        case number < 1 && number > 0:
            unit = 'Hours';
            status = true;
            number = number * 24;
            break;
        case number <= 0 && number > -1:
            unit = 'Hours';
            status = false;
            number = number * 24;
            break;
        case number <= -1:
            unit = 'Days';
            status = false;
            break;
        default:
            break;
    }
    return { number: number, unit: unit, status: status };
}
export const workerType = {
    0: 'EXEMPT ANNUAL SALARY',
    1: 'NON EXEMPT ANNUAL SALARY',
    2: 'NON EXEMPT HOURLY SALARY',
    3: 'CONTRACTOR HOURLY SALARY',
};

export const workerTypeSalary = {
    'EXEMPT ANNUAL SALARY': 'Per Year',
    'NON EXEMPT ANNUAL SALARY': 'Per year',
    'NON EXEMPT HOURLY SALARY': 'Per Hour',
    'CONTRACTOR HOURLY SALARY': 'Per Hour',
};
export const NOTIF_TYPES = {
    0: 'SHIFT_ASSIGNATION',
    1: 'SHIFT_ASSIGNATION_ACCEPT',
    2: 'SHIFT_ASSIGNATION_REJECT',
    3: 'SHIFT_ASSIGNATION_REMOVE',
    4: 'OVERTIME_ASSIGNATION',
    5: 'OVERTIME_ASSIGNATION_REMOVE',
    6: 'OVERTIME_ASSIGNATION_ACCEPT',
    7: 'CONTRACT_RECEIVED',
}
export const REQUIREMENT_TYPES = {
    'text': 'text',
    'number': 'number',
    'date': 'date',
    'picture': 'picture',
    'file': 'file',
}
export const REQUIREMENT_TARGETS = {
    'contract': 'contract',
    'employee': 'employee',
}
export function reduceFraction(numerator,denominator){
    var pgcd = function pgcd(a,b){
      return b ? pgcd(b, a%b) : a;
    };
    pgcd = pgcd(numerator, denominator);
    return (numerator / pgcd) / (denominator / pgcd)
}
/**
 * 
 * @param {any} dateSet 
 * @param {any} template 
 * @param {any} attendances 
 */
export function redistributeAttendanceOnDates(dateSet, template, attendances) {
    let _attendances = [];
    dateSet.map((date, index) => {
        let _atts = attendances.filter((attendanceLine) =>
            isSameDay(parse(attendanceLine.date, 'dd MMM yyyy', new Date()), date)
        );
        if (_atts.length > 0) {
            _attendances.push({
                ..._atts[0],
                empty: false,
            });
        } else {
            _attendances.push({
                ...template,
                date: format(date, 'dd MMM yyyy'), // the date of the curretn days
                pk: date.toString(),
                empty: true,
            });
        }

    });
    return _attendances
}

export function getCurrentWeekWork(workWeeks) {
    let __sDefaultDate = moment().format('YYYY-MM-DD');
    let sDefaultDate = new Date(__sDefaultDate)
    let defaultWorkWeek = {}
    !_.isEmpty(workWeeks) && _.filter(workWeeks, (weekSet, index) => {

        let __fd = parse(weekSet.split('--')[0].substring(0, 19), 'yyyy-MM-dd HH:mm:ss', new Date())
        let __ld = parse(weekSet.split('--')[1].substring(0, 19), 'yyyy-MM-dd HH:mm:ss', new Date())
        let fDate = format(__fd, USER_FORMAT_YEAR);
        //let _fDate = format(parse(weekSet.split('--')[1], 'yyyy-MM-dd HH:mm:ssXXXXX', new Date()), )
        let sDate = format(__ld, USER_FORMAT_YEAR);
        if (sDefaultDate >= __fd && sDefaultDate <= __ld) {
            defaultWorkWeek = { label: `from ${fDate} to ${sDate}`, value: weekSet }
        }

    });
    return defaultWorkWeek
}

export function printDay(day) {

    switch (day) {
        case 0:
            return { value: 0, label: "Monday" }
            break;
        case 1:
            return { value: 1, label: "Tuesday" }
            break;
        case 2:
            return { value: 2, label: "Wednesday" }
            break;
        case 3:
            return { value: 3, label: "Thursday" }
            break;
        case 4:
            return { value: 4, label: "Friday" }
            break;
        case 5:
            return { value: 5, label: "Saturday" }
            break;
        case 6:
            return { value: 6, label: "Sunday" }
            break;
        default:
            return { value: 0, label: "Monday" }
            break;
    }
}

export function printNameAddress(address) {
    let name = '';
    if (!_.isEmpty(address)) {
        for (let index = 0; index < address.length; index++) {
            name += ' ' + address[index].long_name;
        }
    }
    return name;
}

/**
 * Print toast alert
 * @param {String} title 
 * @param {any} message 
 * @param {any} _toasterRef 
 * @param {any} icon 
 */
export function renderAlertInfo(title, message, _toasterRef, icon ) {
    const toast = _toasterRef;
    toast.toast(
        new ToastItem(
            `${title}`,
            `${message}`,
             icon
        )
    );
}
