import { createReducer } from 'reduxsauce';
import { Types } from './actions';

const INITIAL_STATE = {
    error: null, worker: null, 
    loading: false, importObject: null, 
    workers: [], uploading: false,
    assign_shift: null,
    creationDone: false,
    salary: {},
    salaryAssignStatus: 'ON_HOLD',
    workerFileActivity: [],
    workerFileActivityWorkWeek: [],
    qrcodeToken: '',
    loadingQRCode: false,
    avatar : null,
    loadingImport: false,
    errorImport: null,
    importSuccess : false,
    messageImport : null,
    approveLoading: false,
    approveWorker: [],
    errorApproveWorker: null,
    approvedLoading : false,
    approvedWorkers : [],
    errorApprovedWorker : null,
    approveeLoading: false,
    approveeWorker: {},
    errorApproveeWorker: null,
    transitFile: null,
    transitionStatus: 0,
    stopEmploymentData: null,
}

export const transitFile = (state = INITIAL_STATE, action ) => { 
    return {...state,  loading: true}
}

export const transitFileDone = (state = INITIAL_STATE, action) => {
  return { ...state, error: null, transitFile: action.data, loading: false };
};

export const transitFileFail = (state = INITIAL_STATE, action) => {
  return { ...state, error: action.error, loading: false };
};

// one worker
export const setWorkers = (state = INITIAL_STATE, action) => {
  return { ...state, error: null, workers: action.workers, loading: false };
};

export const fetchWorker = (state = INITIAL_STATE, action) => {
  return { ...state, error: null, worker: action.worker, loading: true };
};

export const fetchWorkerSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, error: null, workers: action.payload, loading: false , importSuccess : false,};
};

export const fetchWorkerFail = (state = INITIAL_STATE, action) => {
  return { ...state, error: action.error, worker: null, loading: false, importSuccess : false, };
};

export const fetchQRCodeToken = (state = INITIAL_STATE, action) => {
  return { ...state, loadingQRCode: true };
};

export const fetchQRCodeTokenDone = (state = INITIAL_STATE, action) => {
  return { ...state, qrcodeToken: action.token, loadingQRCode: false };
};

export const fetchQRCodeTokenFail = (state = INITIAL_STATE, action) => {
  return { ...state, error: action.error, loadingQRCode: false };
};
export const createWorker = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: null,
    loading: true,
    creationDone: false,
    importSuccess : false,
  };
};
export const createWorkerSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: null,
    worker: action.worker,
    loading: false,
    creationDone: true,
    importSuccess : false,
  };
};

export const createWorkerFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: action.error,
    // worker: null,
    loading: false,
    creationDone: false,
    importSuccess : false,
  };
};

export const updateWorker = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
    worker: action.worker,
    importSuccess : false,
  };
};

export const updateWorkerDone = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    importSuccess : false,
  };
};

export const updateWorkerFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
    importSuccess : false,
  };
};

export const deleteWorker = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: null,
    loading: true,
  };
};

export const deleteWorkerDone = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: null,
    loading: false,
    importSuccess : false,
  };
};

export const deleteWorkerFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
    importSuccess : false,
  };
};

export const uploadWorker = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loadingImport: true,
        uploading: true,
        messageImport : null, 
        errorImport: null,
        importSuccess : false,
    }
}

export const uploadWorkerDone = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        
        loadingImport: false,
        importObject: action.obj,
        uploading: false,
        errorImport: null,
        messageImport : "Import successful",
        importSuccess : true,
    }
}

export const uploadWorkerFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loadingImport: false,
        uploading: false,
        errorImport: action.error,
        messageImport : "Importation Failed",
        importSuccess : false,
    }
}
export const approveWorkers = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    approveLoading: true,
  };
};

export const approveWorkersDone = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    approveLoading: false,
    approveWorker: action.approveWorker,
    errorApproveWorker: null,
  };
};

export const approveWorkersFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    errorApproveWorker: action.error,
    approveLoading: false,
  };
};
export const approvedWorkers = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    approvedLoading: true,
  };
};

export const approvedWorkersDone = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    approvedLoading: false,
    approvedWorker: action.data.data.results,
    errorApprovedWorker: null,
  };
};

export const approvedWorkersFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    errorApprovedWorker: action.error,
    approvedLoading: false,
  };
};

export const approveeWorkers = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    approveeLoading: true,
  };
};

export const approveeWorkersDone = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        approveeLoading: false,
        approveeWorker: action.data.data.results,
        errorApproveeWorker: null
    }
}

export const approveeWorkersFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    errorApproveeWorker: action.error,
    approveeLoading: false,
  };
};
export const assignShiftWorkers = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: null,
    loading: true,
  };
};

export const assignWorkersDone = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: null,
    loading: false,
    assign_shift: action,
  };
};

export const assignWorkersFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

export const createBulkWorker = (state = INITIAL_STATE, action) => {
  return { ...state, loading: true };
};

export const createBulkWorkerDone = (state = INITIAL_STATE, action) => {
  return { ...state, workers: action.worker, loading: false };
};

export const createBulkWorkerFail = (state = INITIAL_STATE, action) => {
  return { ...state, error: action.error, loading: false };
};

export const fetchDetailsWorker = (state = INITIAL_STATE, action) => {
  return { ...state, loading: true };
};

export const fetchDetailsWorkerDone = (state = INITIAL_STATE, action) => {
  return { ...state, workers: action.worker, loading: false };
};

export const fetchDetailsWorkerFail = (state = INITIAL_STATE, action) => {
  return { ...state, error: action.error, loading: false };
};

export const assignSalary = (state = INITIAL_STATE, action) => {
  return { ...state, loading: true };
};

export const assignSalaryDone = (state = INITIAL_STATE, action) => {
  return { ...state, loading: false, salary: action.salary, salaryAssignStatus: 'DONE' };
};

export const assignSalaryFail = (state = INITIAL_STATE, action) => {
  return { ...state, loading: false, error: action.error, salaryAssignStatus: 'FAIL' };
};

export const getWorkerFileActivity = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
  };
};

export const getWorkerFileActivityDone = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    workerFileActivity: action.activity,
  };
};

export const getWorkerFileActivityFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    error: action.error,
  };
};

export const getWorkerFileActivityWorkWeek = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
  };
};

export const getWorkerFileActivityWorkWeekDone = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    workerFileActivityWorkWeek: action.activity,
  };
};

export const getWorkerFileActivityWorkWeekFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    error: action.error,
  };
};

export const updateAvatar = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
  };
};

export const updateAvatarDone = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    avatar: action.avatar,
  };
};

export const updateAvatarFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

export const stopEmployment = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
  };
};

export const stopEmploymentDone = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    stopEmploymentData: action.data,
    error: null,
  };
};

export const stopEmploymentFail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    error: action.error,
    stopEmploymentData: null,
  };
};

export const HANDLERS = {
  [Types.SET_WORKERS]: setWorkers,

  [Types.FETCH_WORKER]: fetchWorker,
  [Types.FETCH_WORKER_SUCCESS]: fetchWorkerSuccess,
  [Types.FETCH_WORKER_FAIL]: fetchWorkerFail,

  [Types.CREATE_WORKER]: createWorker,
  [Types.CREATE_WORKER_SUCCESS]: createWorkerSuccess,
  [Types.CREATE_WORKER_FAIL]: createWorkerFail,

  [Types.UPDATE_WORKER]: updateWorker,
  [Types.UPDATE_WORKER_DONE]: updateWorkerDone,
  [Types.UPDATE_WORKER_FAIL]: updateWorkerFail,

  [Types.DELETE_WORKER]: deleteWorker,
  [Types.DELETE_WORKER_DONE]: deleteWorkerDone,
  [Types.DELETE_WORKER_FAIL]: deleteWorkerFail,

  [Types.UPLOAD_WORKER]: uploadWorker,
  [Types.UPLOAD_WORKER_DONE]: uploadWorkerDone,
  [Types.UPLOAD_WORKER_FAIL]: uploadWorkerFail,

  [Types.UPDATE_AVATAR]: updateAvatar,
  [Types.UPDATE_AVATAR_DONE]: updateAvatarDone,
  [Types.UPDATE_AVATAR_FAIL]: updateAvatarFail,

  [Types.CREATE_BULK_WORKER]: createBulkWorker,
  [Types.CREATE_BULK_WORKER_DONE]: createBulkWorkerDone,
  [Types.CREATE_BULK_WORKER_FAIL]: createBulkWorkerFail,

  [Types.ASSIGN_SHIFT_WORKER]: assignShiftWorkers,
  [Types.ASSIGN_SHIFT_WORKER_DONE]: assignWorkersDone,
  [Types.ASSIGN_SHIFT_WORKER_FAIL]: assignWorkersFail,

  [Types.ASSIGN_SALARY]: assignSalary,
  [Types.ASSIGN_SALARY_DONE]: assignSalaryDone,
  [Types.ASSIGN_SALARY_FAIL]: assignSalaryFail,

  [Types.GET_WORKER_FILE_ACTIVITY]: getWorkerFileActivity,
  [Types.GET_WORKER_FILE_ACTIVITY_DONE]: getWorkerFileActivityDone,
  [Types.GET_WORKER_FILE_ACTIVITY_FAIL]: getWorkerFileActivityFail,

  [Types.GET_WORKER_FILE_ACTIVITY_WORK_WEEK]: getWorkerFileActivityWorkWeek,
  [Types.GET_WORKER_FILE_ACTIVITY_WORK_WEEK_DONE]: getWorkerFileActivityWorkWeekDone,
  [Types.GET_WORKER_FILE_ACTIVITY_WORK_WEEK_FAIL]: getWorkerFileActivityWorkWeekFail,

  [Types.FETCH_QR_CODE_TOKEN]: fetchQRCodeToken,
  [Types.FETCH_QR_CODE_TOKEN_DONE]: fetchQRCodeTokenDone,
  [Types.FETCH_QR_CODE_TOKEN_FAIL]: fetchQRCodeTokenFail,

  [Types.APPROVE_WORKERS]: approveWorkers,
  [Types.APPROVE_WORKERS_DONE]: approveWorkersDone,
  [Types.APPROVE_WORKERS_FAIL]: approveWorkersFail,

  [Types.APPROVED_WORKERS]: approvedWorkers,
  [Types.APPROVED_WORKERS_DONE]: approvedWorkersDone,
  [Types.APPROVED_WORKERS_FAIL]: approvedWorkersFail,

  [Types.APPROVEE_WORKERS]: approveeWorkers,
  [Types.APPROVEE_WORKERS_DONE]: approveeWorkersDone,
  [Types.APPROVEE_WORKERS_FAIL]: approveeWorkersFail,

  [Types.TRANSIT_FILE]: transitFile,
  [Types.TRANSIT_FILE_DONE]: transitFileDone,
  [Types.TRANSIT_FILE_FAIL]: transitFileFail,

  [Types.STOP_EMPLOYMENT]: stopEmployment,
  [Types.STOP_EMPLOYMENT_DONE]: stopEmploymentDone,
  [Types.STOP_EMPLOYMENT_FAIL]: stopEmploymentFail,
};

export default createReducer(INITIAL_STATE, HANDLERS);
