export default class RequirementModel {
    /**
     * @type {String}
     */
    id = '';

    /**
     * @type {String}
     */
    title = '';

    /**
     * @type {String}
     */
    r_type= '';


    /**
    * @type {Boolean}
    */
   is_required = false

    /**
     * @type {String}
     */
    target = '';

    toJson(){
        return {
            pk: this.id,
            title: this.title,
            r_type: this.r_type,
            is_required: this.is_required,
            target: this.target,
        }
    }
    static fromJson(json){
        const model = new RequirementModel();
        if (json !== null && json !== undefined){
            model.id = json.pk
            model.title = json.title;
            model.r_type = json.r_type;
            model.is_required = json.is_required;
            model.target = json.target;
        }
        return model;
    }


}