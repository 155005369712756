
import { createReducer } from 'reduxsauce'
import {Types} from './actions'
import PayrollModel from '../../models/PayrollModel'
const INITIAL_STATE = {
    error: null, payrolls: [], loading: false, timeSheets: []
}

// reducers here


// init case
export const timesheetComputation = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true,
    }
}


// good case
export const timesheetComputationDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
    }
}


// bad case
export const timesheetComputationFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        error: action.error
    }
}


// init case
export const timesheetResetComputation = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true,
    }
}


// good case
export const timesheetResetComputationDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
    }
}


// bad case
export const timesheetResetComputationFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        error: action.error
    }
}

// init case
export const payrollComputation = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true
    }
}


// good case
export const payrollComputationDone = (state = INITIAL_STATE, action ) => {
    
    return {
        ...state,
        loading: false
    }
}


// bad case
export const payrollComputationFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
       // payrolls: null,
        error: action.error,
        loading: false,
    }
}

// init case
export const payrollResetComputation = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true
    }
}


// good case
export const payrollResetComputationDone = (state = INITIAL_STATE, action ) => {
    
    return {
        ...state,
        loading: false
    }
}


// bad case
export const payrollResetComputationFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
       // payrolls: null,
        error: action.error,
        loading: false,
    }
}

    // handlers
export const HANDLERS = {
    
    [Types.TIMESHEET_COMPUTATION]: timesheetComputation,
    [Types.TIMESHEET_COMPUTATION_DONE]: timesheetComputationDone,
    [Types.TIMESHEET_COMPUTATION_FAIL]: timesheetComputationFail,
    
    [Types.TIMESHEET_RESET_COMPUTATION]: timesheetResetComputation,
    [Types.TIMESHEET_RESET_COMPUTATION_DONE]: timesheetResetComputationDone,
    [Types.TIMESHEET_RESET_COMPUTATION_FAIL]: timesheetResetComputationFail,

    [Types.PAYROLL_COMPUTATION]: payrollComputation,
    [Types.PAYROLL_COMPUTATION_DONE]: payrollComputationDone,
    [Types.PAYROLL_COMPUTATION_FAIL]: payrollComputationFail,

    [Types.PAYROLL_RESET_COMPUTATION]: payrollResetComputation,
    [Types.PAYROLL_RESET_COMPUTATION_DONE]: payrollResetComputationDone,
    [Types.PAYROLL_RESET_COMPUTATION_FAIL]: payrollResetComputationFail,
   
}
export default createReducer(INITIAL_STATE, HANDLERS)
