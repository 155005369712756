import AttachmentModel from './AttachmentModel';

export default class ProjectModel {
  /**
   * @var {String}
   */
  pk = '';

  /**
   * @type {String}
   */
  title = '';

  /**
   * @type {String}
   */
  description = '';

  /**
   * @type {AttachmentModel}
   */
  attachments = null;

  

  constructor() {
    this.toJson = this.toJson.bind(this);
  }

  /**
   * Converts this instance in json format
   * @returns {Object} The json format of this instance.
   */
  toJson() {
    return {
      pk: this.pk,
      title: this.title,
      description: this.description,
      attachment: this.attachments.toJson(),
     
    };
  }

  /**
   * Creates a new instance of ProjectModel from a json data.
   * @param {Object} json The json data to parse into an ProjectModel instance.
   * @returns {ProjectModel}
   */
  static fromJson(json) {
    const model = new ProjectModel();

    if (json !== null && json !== undefined) {
      model.pk = json.pk;
      model.title = json.title;
      model.description = json.description;
      model.attachments = AttachmentModel.fromJson(json.attachments);
      
    }
    return model;
  }
}
