import Cookies from 'universal-cookie';
import {api, handle_response} from '../../helpers/api'
import _ from 'lodash';
const cookies = new Cookies();

export async function fetchNotificationApi(notification = 0){
    api.setHeaders(
        {
            'Tenant': cookies.get('user').user.company.domains[0].domain
        }
    )
    const url = (notification.notification === 0 || notification.notification === null  || notification.notification === undefined )  ? '/notifications' : '/notifications/' + notification
    return await api.get(url).then(handle_response)
}


