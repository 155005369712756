
import { createActions } from 'reduxsauce'

export const {Types, Creators} = createActions({
        
    createJob: ['job'],
    createJobDone: ['job'],
    createJobFail: ['error'],
    
    updateJob: ['job'],
    updateJobDone: ['job'],
    updateJobFail: ['error'],
    
    fetchJob: null,
    fetchJobDone: ['jobs'],
    fetchJobFail: ['error'],
    
    deleteJob: ['job'],
    deleteJobDone: ['job'],
    deleteJobFail: ['error'],

    assignWorkerToPosition: ['position'],
    assignWorkerToPositionDone: ['position'],
    assignWorkerToPositionFail: ['error'],

    uploadJob: ['job_obj'],
    uploadJobDone: ['obj'],
    uploadJobFail: ['error'],
});
    