import Cookies from 'universal-cookie'; 
import {api, handle_response} from '../../helpers/api'
import _ from 'lodash'

export async function fetchAttachmentsApi(){
    return await api.get('/attachments' ).then(handle_response)
}
export async function fetchProjectsApi(project=null){

   return await api.get(_.isEmpty(project) ? '/projects' : '/project/'+project).then(handle_response)
}
export async function createProjectApi(project){
    console.log(project)
    return await api.post('/projects', project).then(handle_response)
}
export async function createAttachmentApi(attachment){
    console.log(attachment)
    const form = new FormData()
    form.append('file', attachment)
    form.append('title', attachment.name)
    const headers = {
      'Content-Type': 'multipart/form-data'
    }
    return await api.post('/project-attachments', form, {headers}).then(handle_response)
}
export async function updateProjectApi(project){
    return await api.patch('/projects/'+project.pk, project).then(handle_response)
}
export async function updateAttachmentApi(attachment){
    return await api.patch('/project-attachments/'+attachment.pk, attachment).then(handle_response)
}
export async function deleteProjectApi(project){
    return await api.delete('/projects/'+project.pk).then(handle_response)
}
export async function deleteAttachmentApi(attachment){
    return await api.delete('/project-attachments/'+attachment.pk).then(handle_response)
}
