import Cookies from 'universal-cookie'; 
import {api, handle_response} from '../../helpers/api'


export async function fetchPayrollApi(){
    return await api.get('/payroll').then(handle_response)
}
export async function createPayrollApi({payroll}){
   return await api.post('/payroll', {...payroll}).then(handle_response)
}


export async function updatePayrollApi({payroll}){
   
   return await api.patch('/payroll/'+payroll.pk, {...payroll}).then(handle_response)
}


export async function deletePayrollApi(payroll){
   
   return await api.delete('/payroll/'+ payroll.payroll).then(handle_response)
}
