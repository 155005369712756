// @flow
import jwtDecode from 'jwt-decode';
import { Cookies } from 'react-cookie';
import {api} from './api'
import _ from 'lodash'
/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const user = getLoggedInUser();
    
    if (!user || user === null) {
        return false;
    }
     //if(_.isNull(user.company)) return false
     if(_.isEmpty(user.user.company)) return true

    // todo: valider la structure de l'objet stocker et lancer l'authentification si c'est pas correct
    
    api.setHeaders({
        'Authorization': `Bearer ${user.access}`,
       // 'Tenant': user.user.company.domains[0].domain
    })
    const decoded = jwtDecode(user.access);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
        
        return false;
    } else {
       
        return true;
    }
};

/**
 * Returns the logged in user
 */
function getLoggedInUser () {
    const cookies = new Cookies();
    const user = cookies.get('user');
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

/**
 * Merge all permissions
 */
function mergeAllPermissions(){
    let cookies = new Cookies();
    let permissions = {}
    for (let cookie in cookies.getAll()){
        if(cookie.split('_')[0] === 'perms'){
            let perm_name = cookie.split('_')[1]
            permissions[perm_name] = cookies.get(cookie)
        }
    }
    return permissions
}
/**
 * Remove all permissions in cookies
 */
function removeAllPemissions(){
    let cookies = new Cookies();
    let permissions = {}
    for (let cookie in cookies.getAll()){
        if(cookie.split('_')[0] === 'perms'){
            let perm_name = cookie.split('_')[1]
            cookies.remove(cookie)
        }
    }
    return permissions
}

/**
 * save permissions intp multiple cookies
 * @param {Sa} content 
 */
function splitCookies(content){
    let cookiers_map = []
    let cookies = new Cookies();
    for (let key in content){
        cookies.set(`perms_${key}`, content[key], {path: "/"})
    }   
}
function splitPermissions(permissions){
    let cookies = new Cookies();
    let permissions_split = []
    for (let key in permissions){
        permissions_split.push()
        cookies.set(`perms_${key}`, permissions[key], {path: "/"})
    }   
}
/**
 * format user information 
 */
function formatUser(data){
    let user = {

    }
}

export { isUserAuthenticated, getLoggedInUser, mergeAllPermissions, removeAllPemissions, splitCookies };
