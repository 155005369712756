
import { createReducer } from 'reduxsauce'
import {Types} from './actions'
import {normalizeLocations} from '../../helpers/utils'
const INITIAL_STATE = {
    error: null, location: [], loading: false, rates: [],
    loadingImport: false,importObject: {},uploading: false,
    errorImport: null,loadingCreate : false, errorCreated : null, 
    messageCreate : null, 
}

// reducers here


export const fetchRates = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: true,
        loadingCreate : false,
        errorCreated : null,
        messageCreate : null
    }
}

export const fetchRatesDone = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        rates: action.rates,
        loadingCreate : false,
        errorCreated : null,
        messageCreate : null
    }
}

export const fetchRatesFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: true,
        error: action.error,
        loadingCreate : false,
        errorCreated : null,
        messageCreate : null
    }
}

// init case
export const createLocation = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
       // location: action.location,
        loading: false,
        loadingCreate : true,
        errorCreated : null,
        messageCreate : null
    }
}


// good case
export const createLocationDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
       // location: action.location,
        loading: false,
        loadingCreate : false,
        errorCreated : null,
        messageCreate : "Location Create Successful"
    }
}


// bad case
export const createLocationFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
       // location: null,
       // error: true,
        loading: false,
        error: null,
        loadingCreate : false,
        errorCreated : action.error,
        messageCreate : "Location Create failed"
    }
}



// init case
export const updateLocation = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        loadingCreate : true,
        errorCreated : null,
        messageCreate : null
        
    }
}


// good case
export const updateLocationDone = (state = INITIAL_STATE, action ) => {
    /**
     * remove location from the reducer here because it is only one object,
     * will trigger a new location fetch in the saga
    **/
    return {
        ...state,
        loading: false,
        loadingCreate : false,
        errorCreated : null,
        messageCreate : "Location Update Successful"
    }
}


// bad case
export const updateLocationFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        loadingCreate : false,
        errorCreated : action.error,
        messageCreate : "Location Update Failed",
        error: null
    }
}



// init case
export const fetchLocation = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
       // location: action.location,
        loading: true,
        loadingCreate : false,
        errorCreated : null,
        messageCreate : null
    }
}

// good case
export const fetchLocationDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        location: normalizeLocations(action.location.results),
        loading: false,
        loadingCreate : false,
        errorCreated : null,
        messageCreate : null
    }
}

// bad case
export const fetchLocationFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
       // error: true,
        loading: false,
        error: action.error,
        loadingCreate : false,
        errorCreated : null,
        messageCreate : null
    }
}

// init case
export const deleteLocation = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        //location: action.location,
        loading: false,
        loadingCreate : true,
        errorCreated : null,
        messageCreate : null
    }
}


// good case
export const deleteLocationDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        //location: action.location,
        loading: false,
        loadingCreate : false,
        errorCreated : null,
        messageCreate : "Location Delete SuccessFul"
    }
}


// bad case
export const deleteLocationFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        //location: null,
       // error: true,
        loading: false,
        error: null,
        loadingCreate : false,
        errorCreated : action.error,
        messageCreate : "Location Delete Fail"
    }
}

export const assignEmployeesLocation = (state = INITIAL_STATE , action ) => {
    return {
        ...state,
        loading: true,
        assignLocationStatus: 'PENDING',
    }
}


export const assignEmployeesLocationDone = (state = INITIAL_STATE , action ) => {
    return {
        ...state,
        loading: false,
        assignLocationStatus: 'DONE',
    }
}


export const assignEmployeesLocationFail = (state = INITIAL_STATE , action ) => {
    return {
        ...state,
        loading: false,
        assignLocationStatus: 'FAIL',
    }
}   


export const uploadLocation = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loadingImport: true,
        uploading: true
    }
}

export const uploadLocationDone = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        
        loadingImport: false,
        importObject: action.obj,
        uploading: false,
        errorImport: null
    }
}

export const uploadLocationFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loadingImport: false,
        uploading: false,
        errorImport: action.error
    }
}
// handlers
export const HANDLERS = {

    [Types.CREATE_LOCATION]: createLocation,
    [Types.CREATE_LOCATION_DONE]: createLocationDone,
    [Types.CREATE_LOCATION_FAIL]: createLocationFail,

    [Types.UPDATE_LOCATION]: updateLocation,
    [Types.UPDATE_LOCATION_DONE]: updateLocationDone,
    [Types.UPDATE_LOCATION_FAIL]: updateLocationFail,

    [Types.FETCH_LOCATION]: fetchLocation,
    [Types.FETCH_LOCATION_DONE]: fetchLocationDone,
    [Types.FETCH_LOCATION_FAIL]: fetchLocationFail,

    [Types.DELETE_LOCATION]: deleteLocation,
    [Types.DELETE_LOCATION_DONE]: deleteLocationDone,
    [Types.DELETE_LOCATION_FAIL]: deleteLocationFail,

    [Types.FETCH_RATES]: fetchRates,
    [Types.FETCH_RATES_DONE]: fetchRatesDone,
    [Types.FETCH_RATES_FAIL]: fetchRatesFail,

    [Types.ASSIGN_EMPLOYEES_LOCATION]: assignEmployeesLocation,
    [Types.ASSIGN_EMPLOYEES_LOCATION_DONE]: assignEmployeesLocationDone,
    [Types.ASSIGN_EMPLOYEES_LOCATION_FAIL]: assignEmployeesLocationFail,

    [Types.UPLOAD_LOCATION]: uploadLocation,
    [Types.UPLOAD_LOCATION_DONE]: uploadLocationDone,
    [Types.UPLOAD_LOCATION_FAIL]: uploadLocationFail,
}
export default createReducer(INITIAL_STATE, HANDLERS)
