import Cookies from 'universal-cookie'; 
import {api, handle_response} from '../../helpers/api'
import { toUTCTimezone } from '../../helpers/utils'
const cookies = new Cookies();


export async function fetchAttendanceApi(attendance = {}){
    let url = '/attendance'
    const {start_date, end_date} = attendance
    if (start_date && end_date ){
        url += '?start='+start_date + '&end='+end_date;
    }
    return await api.get(url).then(handle_response)
}

export async function fetchFinancialAttendanceApi(attendance = {}){
    let url = '/global-work-comp'
    const {start_date, end_date} = attendance
    if (start_date && end_date ){
        url += '?date1='+start_date + '&date2='+end_date;
    }
    return await api.get(url).then(handle_response)
}

export async function approveAttendanceApi(pk, attendance){
    return await api.patch(`/attendance/approve/${pk}`, attendance).then(handle_response)
}

export async function editAttendanceApi({attendance}){
    return await api.patch(`/attendance/${attendance.pk}`, attendance).then(handle_response)
}

export async function fetchFileAttendanceApi(file, date1, date2){
    // todo: find a right way to handle timezone conversion from "+HH:MM" to UTC+X format
    let _date1 = date1.split('+')[0]
    let _date2 = date2.split('+')[0]
    return await api.get(`/attendance?file=${file}&start=${_date1}&end=${_date2}`).then(handle_response)
}

export async function fetchTimesheetApi(file, start, end){
    return await api.get(`/timesheet?start=${start}&end=${end}&file=${file}`).then(handle_response)
}

export async function fetchAllTimesheetApi(){
    return await api.get(`/global-timesheet`).then(handle_response)
}

export async function fetchAllPayrollApi(){
    return await api.get(`/global-pay`).then(handle_response)
}

export async function fetchPayrollApi(file, start, end){
    return await api.get(`/pay?payroll_start=${start}&payroll_end=${end}&file=${file}`).then(handle_response)
}

export async function completeBreakApi(attendance_id){
    return await api.post('attendance-fake-break-completion', {attendance_id}).then(handle_response)
}
export async function acceptAssignment(){
    return await api.post(`/auto-accept-assignments`).then(handle_response)
}