// @flow
import Cookies from 'universal-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { registerApi, loginApi, compagnyCreateApi, changePassword, api, getUserDetailApi, verify, getUserAccountDetailApi } from '../../helpers/api';
import { splitCookies, removeAllPemissions} from '../../helpers/authUtils'
import { LOGIN_USER, LOGOUT_USER, REGISTER_USER, FORGET_PASSWORD, CREATE_COMPANY, GET_USER_DETAIL, GET_USER_ACCOUNT_DETAILS } from './constants';
import _ from 'lodash';


import {
    loginUserSuccess,
    loginUserFailed,
    registerUserSuccess,
    registerUserFailed,
    forgetPasswordSuccess,
    forgetPasswordFailed,
    verifySuccess,
    verifyFailed,
    compagnyCreateSuccess,
    compagnyCreateFailed,
    compagnyDontExist,
    emailAlreadyExist,
    //getUserDetail,
    getUserDetailDone,
    getUserDetailFail,
    getUserAccountDetail,
} from './actions';

const cookies = new Cookies()
/**
 * Sets the session
 * @param {*} user
 */
export const setSession = user => {
    console.log(user)
    if (user !== null) {
        if (!_.isEmpty(user.access) && !_.isEmpty(user.user.compagny)) {
            api.setHeaders({
                'Authorization': `Bearer ${user.access}`,
                'Tenant': user.user.company.domains[0].domain
            })
        }
        if (!_.isEmpty(user.token)) {
            api.setHeaders({
                'Authorization': `Bearer ${user.token}`,
            })
        }
        cookies.remove('user', {path: '/'})
        cookies.set('user', JSON.stringify(user), { path: '/' });
    } else {
        
        for (let key in cookies.getAll()){
            cookies.remove('user', {path: '/'})
            cookies.remove(key)
        }
        cookies.remove('user')
    }
};

// const initSession = () => {
//     let cookies = new Cookies();
//     let user = cookies.get('user')
//     api.setHeaders({
//         'Authorization': `Bearer ${user.access}`,
//         'Tenant': user.company.domains[0].domain
//     })
// }

/**
 * Login the user
 * @param {*} payload - login and password
 */
function* login({ payload: { login, password } }) {
    // const options = {
    //     body: JSON.stringify({ login, password }),
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    // };

    const response = yield call(loginApi, login, password);
    if (response.status) {
        console.log(response)
        const user = {
            access: response.access,
            refresh: response.refresh,
            user: {
                id: response.user.id,
                first_name: response.user.first_name,
                last_name: response.user.last_name,
                email: response.user.email,
                unique_id: response.user.unique_id,
                companies: [ ...response.user.companies ],
                company : null
            },
            role: 'Admin',
        }        
       // splitCookies(response.user.permissions);

        if (_.isNull(user.role)) {
            var _err_txt = 'user don\'t have a permission'
            response.error_message = _err_txt
            setSession(null);
            yield put(loginUserFailed(_err_txt));
        }
        //setPartialSession ()
        setSession(user);

        if (response.user.company == null) {
            cookies.set('companyNotexist', true);
            yield put(compagnyDontExist());
        } else {
            yield put(loginUserSuccess(user));
        }
    } else {
        var _err_txt = ''
        const p = response
        for (var key in p) {
            if (p.hasOwnProperty(key)) {
                if (key !== 'status_code' && key !== 'status') {
                    _err_txt += key + ' : ' + p[key] + '\n'
                }
            }
        }
        response.error_message = _err_txt
        setSession(null);
        yield put(loginUserFailed(_err_txt));
    }

}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
    try {
        cookies.remove('user')
        removeAllPemissions()
        setSession(null);
        yield call(() => {
            if(_.isEmpty(history)){
                window.location.href = "/account/login"
            }
            history.push('/account/login');
        });
    } catch (error) {
        console.log(error)
    }
}

/**
 * Register the user
 * @param {*} payload - fullname and email
 */
function* register({ payload: { fullname, email, public_phone } }) {
    const response = yield call(registerApi, fullname, email, public_phone);
    if (response.status) {
        const user = {
            access: response.access,
            refresh : response.refresh,
            token : response.token,
            user : {
                ..._.omit(response, ['access', 'refresh', 'token'])
            },
            role: 'Admin'
         }
        setSession(user);
        yield put(registerUserSuccess(response));
    } else {
        var _err_txt = ''
        const p = response
        for (var key in p) {
            if (p.hasOwnProperty(key)) {
                if (key !== 'status_code' && key !== 'status') {
                    _err_txt += key + ' : ' + p[key] + '\n'
                }
            }
        }
        if (response.email[0] === "A user with that email already exists.") {
            yield put(emailAlreadyExist(_err_txt));
        } else {
            response.error_message = _err_txt
            yield put(registerUserFailed(_err_txt));
        }

    }

}

/**
 * Register the user
 * @param {*} payload - fullname and email
 */
function* createCompagny({ payload }) {
    // const { name, head_location, company_type, owner, token, industries } = payload

    const response = yield call(compagnyCreateApi, payload);
    if (response.status) {
        
        // let cookies = new Cookies();
        // cookies.set('compagany', JSON.stringify(response.data), { path: '/' });
        yield put(compagnyCreateSuccess(response.data));
         yield put(getUserAccountDetail()) // launch the fetching for the current user

        /*const user = {
            access: response.data.access,
            refresh: response.data.refresh,
            role: 'Admin',
            ...response.data.user
        }*/
        //setSession(user);


    } else {
        var _err_txt = ''
        const p = response
        for (var key in p) {
            if (p.hasOwnProperty(key)) {
                if (key !== 'status_code' && key !== 'status') {
                    _err_txt += key + ' : ' + p[key] + '\n'
                }
            }
        }
        response.error_message = _err_txt
        yield put(compagnyCreateFailed(response.data));
    }
}

/**
 * forget password
 */
function* forgetPassword({ payload: { username } }) {
    // const options = {
    //     body: JSON.stringify({ username }),
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    // };

    try {
        const response = yield call(changePassword, username);
        yield put(forgetPasswordSuccess(response.message));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(forgetPasswordFailed(message));
    }
}

function* verifySaga({ payload: { code } }) {
    try {
        const response = yield call(verify, code);
        yield put(verifySuccess(response.message));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid code';
                break;
            default:
                message = error;
        }
        yield put(verifyFailed(message));
    }
}

function* getDetailUserSaga(init_company = false) {

    const response = yield call(getUserDetailApi,init_company)
    if (response.status) {
        const user = {
            access: response.data.access,
            refresh: response.data.refresh,
            user: {
                id: response.id,
                first_name: response.data.first_name,
                last_name: response.data.last_name,
                email: response.data.email,
                unique_id : response.data.unique_id,
                company: {
                    name: !_.isEmpty(response.data.files.base_location ) ? response.data.files.base_location.name : "",
                    location: !_.isEmpty(response.data.files.base_location ) ? response.data.files.base_location.state_name : "",
                    city: !_.isEmpty(response.data.files.base_location ) ? response.data.files.base_location.city : "",
                    public_phone: !_.isEmpty(response.data.files.base_location  ) ? response.data.files.base_location.phone : "",
                    public_email: !_.isEmpty(response.data.files.base_location.public_email ) ? response.data.files.base_location.email : "",
                    registration_id: !_.isEmpty(response.data.files.base_location.registration_id ) ? response.data.files.base_location.registration_id : "",
                    company_type: !_.isEmpty(response.data.files.base_location.company_type ) ? response.data.files.base_location.company_type : "",
                    ..._.omit(response.data.files.base_location, ["name","state_name","city","phone", "email"]) 
                }
            },
            role: 'Admin',
        }
       // splitCookies(response.data.user.permissions);
        setSession(user);
        yield put(getUserDetailDone(response.data))
    } else {
        yield put(getUserDetailFail(response))
    }

}
function* getUserAccountDetailSaga() {

    const response = yield call(getUserAccountDetailApi)
    if (response.status) {
        api.setHeaders({
            'Tenant': response.data.user.company.domains[0].domain
        })
        const user = {
            access : response.data.access,
            refresh : response.data.refresh,
            user : {
                ..._.omit(response.data.user, ['permissions'])
            },
            role: 'Admin'
        }
        setSession(user);
        splitCookies(response.data.user.permissions);
        yield put(getUserDetailDone(response.data))
    } else {
        yield put(getUserDetailFail(response))
    }

}
export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchGetUserDetail() {
    yield takeEvery(GET_USER_DETAIL, getDetailUserSaga)
}
export function* watchGetUserAccountDetail(){
    yield takeEvery(GET_USER_ACCOUNT_DETAILS, getUserAccountDetailSaga)
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, register);
}

export function* watchCreateCompany() {
    yield takeEvery(CREATE_COMPANY, createCompagny)
}

export function* watchForgetPassword() {
    yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

function* authSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchCreateCompany),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchForgetPassword),
        fork(watchGetUserDetail),
        fork(watchGetUserAccountDetail)
    ]);
}

export default authSaga;
