import { createActions } from 'reduxsauce'

export const {Types, Creators} = createActions({
    
    fetchAttendance: ['attendance', 'time_interval'],
    fetchAttendanceDone: ['attendance'],
    fetchAttendanceFail: ['error'],

    fetchFinance: ['time_interval'],
    fetchFinanceDone: ['financial_info'],
    fetchFinanceFail: ['error'],

    approveAttendance: ['pk', 'attendace'],
    approveAttendanceDone: null,
    approveAttendanceFail: ['error'],

    fetchFileAttendance: ['file', 'date1', 'date2'],
    fetchFileAttendanceDone: ['attendances'],
    fetchFileAttendanceFail: ['error'],

    fetchTimesheet: ['file', 'start', 'end'],
    fetchTimesheetDone: ['timesheet'],
    fetchTimesheetFail: ['error' ],

    fetchAllTimesheet: null,
    fetchAllTimesheetDone: ['timesheet'],
    fetchAllTimesheetFail: ['error' ],


    fetchAllPayroll: null,
    fetchAllPayrollDone: ['payroll'],
    fetchAllPayrollFail: ['error' ],

    fetchPayrolls: ['file', 'start', 'end'],
    fetchPayrollsDone: ['payroll'],
    fetchPayrollsFail: ['error' ],

    editAttendance: ['attendance'],
    editAttendanceDone: ['attendance'],
    editAttendanceFail: ['error'],

    acceptAssignment: null,
    acceptAssignmentDone: ['attendance'],
    acceptAssignmentFail: ['error'],

    completeBreak: ['attendance'],
    completeBreakDone: ['attendance'],
    completeBreakFail: ['error'],
});
