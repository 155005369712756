// @flow
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILED,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILED,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_FAILED,
    VERIFY,
    VERIFY_SUCCESS,
    VERIFY_FAILED,
    CREATE_COMPANY,
    COMPAGNY_CREATE_SUCCESS,
    COMPAGNY_CREATE_FAILED,
    EMAIL_ALREADY_EXIST,
    COMPANY_DONT_EXIST,
    SET_SHOW_TO_FALSE,
    GET_USER_DETAIL,
    GET_USER_DETAIL_DONE,
    GET_USER_DETAIL_FAIL,
    GET_USER_ACCOUNT_DETAILS,
    GET_USER_ACCOUNT_DETAILS_DONE,
    GET_USER_ACCOUNT_DETAILS_FAIL
} from './constants';

import { getLoggedInUser } from '../../helpers/authUtils';

const INIT_STATE = {
    user: getLoggedInUser(),
    loading: false,
    company: null,
    show: false,
    profile: {},
    errorLogin : null,
    errorRegister : null,
    errorConfirm : null,
    errorPasswordReset : null,
    codeVerificationStatus : null,
    passwordResetStatus : null,
    user_details : null,
    user_detail : null  // this is a details of an user after login only
};


const Auth = (state = INIT_STATE, action) => {
    
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, loading: true,show: false  };
        case LOGIN_USER_SUCCESS:
            return { ...state, user: action.payload, loading: false, show: false, errorLogin: null };
        case LOGIN_USER_FAILED:
            return { ...state, errorLogin: action.payload, loading: false, show: false };
        case REGISTER_USER:
            return { ...state, loading: true, errorRegister:null, show: false };
        case REGISTER_USER_SUCCESS:
            return { ...state, user: action.payload, loading: false,show: false, errorRegister: null };
        case REGISTER_USER_FAILED:
            return { ...state, errorRegister: action.payload, loading: false , show: false};
        case LOGOUT_USER:
            return { ...state, user: null, loading: false };
        case FORGET_PASSWORD:
            return { ...state, loading: true };
        case FORGET_PASSWORD_SUCCESS:
            return { ...state, passwordResetStatus: action.payload, loading: false, errorPasswordReset: null,show: false  };
        case FORGET_PASSWORD_FAILED:
            return { ...state, errorPasswordReset: action.payload, loading: false, show: false };
        case VERIFY:
            return { ...state, loading: true };
        case VERIFY_SUCCESS:
            return { ...state, codeVerificationStatus: action.payload, loading: false, errorPasswordReset: null,show: false  };
        case VERIFY_FAILED:
            return { ...state, errorPasswordReset: action.payload, loading: false, show: false };
        case COMPAGNY_CREATE_FAILED:
            return { 
                ...state, 
                errorConfirm: action.payload, 
                loading: false, 
                show: false };
        case CREATE_COMPANY:
            return { 
                ...state, 
                errorConfirm: null, 
                loading: true, 
                show: true 
            };
        case COMPANY_DONT_EXIST:
            return { 
                ...state, 
                errorConfirm: null, 
                loading: false, 
                show: true 
            };
        case EMAIL_ALREADY_EXIST:
            return { 
                ...state, 
                errorConfirm: null, 
                loading: false, 
                show: true };
        case COMPAGNY_CREATE_SUCCESS:
            return { 
                ...state, 
               // company: action.payload, 
                loading: false, 
                errorConfirm: null,
                show: false 
            };
        case SET_SHOW_TO_FALSE :
            return { ...state, errorConfirm: null, loading: false, show: false};
        case GET_USER_DETAIL:
            return {...state, loading: true}
        case GET_USER_DETAIL_DONE:
            return {
                ...state, 
                loading: false, 
                user_details: action.user
            }
        case GET_USER_DETAIL_FAIL:
            return {...state, loading: false, ErrorLogin: action.error}
        case GET_USER_ACCOUNT_DETAILS:
                return {...state, loading: true}
        case GET_USER_ACCOUNT_DETAILS_DONE:
            
            return {
                ...state, 
                loading: false, 
                user_detail: action.user
            }
        case GET_USER_ACCOUNT_DETAILS_FAIL:
                return {...state, loading: false, ErrorLogin: action.error}
        default:
            return { ...state };
    }
};

export default Auth;
