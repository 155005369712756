import { api, handle_response } from '../helpers/api';

export default class Resource {
  /**
   * @var {String} uri The path to the resource API.
   */
  uri = '/';

  constructor(path) {
    this.uri = path;

    this.all = this.all.bind(this);
    this.list = this.list.bind(this);
    this.create = this.create.bind(this);
    this.get = this.list.bind(this);
    this.update = this.update.bind(this);
    this.delete = this.delete.bind(this);
  }

  async all() {
    return handle_response(await api.get(this.uri, { 'no-paginate': 1 }));
  }

  async list(offset, limit) {
    return handle_response(await api.get(this.uri, { offset, limit }));
  }

  async create(data) {
    return handle_response(await api.post(this.uri, data));
  }

  async get(pk) {
    return handle_response(await api.get(`${this.uri}/${pk}`));
  }

  async update(pk, data) {
    return handle_response(await api.patch(`${this.uri}/${pk}`, data));
  }

  async delete(pk) {
    return handle_response(await api.delete(`${this.uri}/${pk}`));
  }
}
