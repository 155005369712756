
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {Types, Creators} from './actions'
// api import
import { 
    createRoleApi, 
    updateRoleApi, 
    fetchRoleApi, 
    deleteRoleApi, 
    fetchPermissionApi
} from './api'
// types import
const { CREATE_ROLE,UPDATE_ROLE,FETCH_ROLE,DELETE_ROLE, FETCH_PERMISSION } = Types
// success, fail import

const {
    createRoleDone,
    createRoleFail, 
    updateRoleDone,
    updateRoleFail, 
    fetchRole,
    fetchRoleDone,
    fetchRoleFail, 
    deleteRoleDone,
    deleteRoleFail, 
    fetchPermissionDone,
    fetchPermissionFail, 
} = Creators

// sagas handler

function* createRoleSaga(action){
    const response = yield call(createRoleApi, action.role);
    if (response.status){
        yield put(createRoleDone(response))
        yield put(fetchRole())
    }else{
        yield put(createRoleFail(response))
        
    }
}

function* updateRoleSaga(role){
    const response = yield call(updateRoleApi, role);
    if (response.status){
        yield put(updateRoleDone(response))
        yield put(fetchRole());
    }else{
        yield put(updateRoleFail(response))
    }
}

function* fetchRoleSaga(role){
    const response = yield call(fetchRoleApi, role);
    if (response.status){
        yield put(fetchRoleDone(response.data.results))
    }else{
        yield put(fetchRoleFail(response.data))
    }
}

function* deleteRoleSaga(role){
    const response = yield call(deleteRoleApi, role);
    if (response.status){
        yield put(deleteRoleDone(response))
        yield put(fetchRole())

    }else{
        yield put(deleteRoleFail(response))
    }
}


function* fetchPermissionSaga(permission){
    const response = yield call(fetchPermissionApi);
    if (response.status){
        yield put(fetchPermissionDone(response.data))
    }else{
        yield put(fetchPermissionFail(response))
    }
}

//saga watchers

export function* watchcreateRole(){
    yield takeEvery(CREATE_ROLE, createRoleSaga)
}

export function* watchupdateRole(){
    yield takeEvery(UPDATE_ROLE, updateRoleSaga)
}

export function* watchfetchRole(){
    yield takeEvery(FETCH_ROLE, fetchRoleSaga)
}

export function* watchdeleteRole(){
    yield takeEvery(DELETE_ROLE, deleteRoleSaga)
}

export function* watchfetchPermission(){
    yield takeEvery(FETCH_PERMISSION, fetchPermissionSaga)
}

  

    
// saga builder

export default function* RoleSaga(){
    yield all([
        fork(watchcreateRole),
        fork(watchupdateRole),
        fork(watchfetchRole),
        fork(watchdeleteRole),
        fork(watchfetchPermission)
    ])
}
    


    