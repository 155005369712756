import { createReducer } from 'reduxsauce'
import {Types} from './actions'


const INITIAL_STATE = {
    error: null, leaves: [], loading: false, 
    acceptedLeave: {}, rejectedLeave:{}, leave: null, employeeLeaves : [], 
    acceptedError : null, rejectedError: null,
    loadingEmployeeLeave : false, errorEmployeeLeave : null
}


export const fetchLeaves = (state = INITIAL_STATE, action)=>{
    
    return {
        ...state,
        loading: true
    }
}

export const fetchLeavesDone = (state = INITIAL_STATE, action) => {
    return {
        ...state, 
        loading: false,
        leaves: action.leaves
    }
}

export const fetchLeavesFail = (state = INITIAL_STATE, action) => {
    
    return {
        ...state, 
        loading: false,
        error: action.error
    }
}



export const fetchLeavesToValidate = (state = INITIAL_STATE, action)=>{
    
    return {
        ...state,
        loading: true
    }
}

export const fetchLeavesToValidateDone = (state = INITIAL_STATE, action) => {
    
    return {
        ...state, 
        loading: false,
        leaves: action.leaves
    }
}

export const fetchLeavesToValidateFail = (state = INITIAL_STATE, action) => {
    
    return {
        ...state, 
        loading: false,
        error: action.error
    }
}

export const acceptLeave = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: true
    }
}

export const acceptLeaveDone = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        acceptedLeave: action.leave
    }
}

export const acceptLeaveFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        acceptedError: action.error
    }
}


export const rejectLeave = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: true,
        
    }
}

export const rejectLeaveDone = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        rejectedLeave: action.leave
    }
}

export const rejectLeaveFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        rejectedError: action.error
    }
}

export const createLeave = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: true
    }
}

export const createLeaveDone = ( state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        leave: action.leave
    }

}

export const createLeaveFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        error: action.error
    }
}
//update leave

export const updateLeave = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: true
    }
}

export const updateLeaveDone = ( state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        leave: action.leave
    }

}

export const updateLeaveFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        error: action.error
    }
}
//end update leave

//delete leave 
export const deleteLeave = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: true
    }
}

export const deleteLeaveDone = ( state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
    }

}

export const deleteLeaveFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        error: action.error
    }
}

//end delete leave
export const fetchEmployeeLeave = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loadingEmployeeLeave: true
    }
}

export const fetchEmployeeLeaveDone = ( state = INITIAL_STATE, action) => {
    return {
        ...state,
        loadingEmployeeLeave: false,
        employeeLeaves: action.employeeLeaves
    }

}

export const fetchEmployeeLeaveFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loadingEmployeeLeave: false,
        errorEmployeeLeave: action.errorEmployeeLeave
    }
}


export const HANDLER = {
    [Types.FETCH_LEAVES]: fetchLeaves,
    [Types.FETCH_LEAVES_DONE]: fetchLeavesDone,
    [Types.FETCH_LEAVES_FAIL]: fetchLeavesFail,

    [Types.FETCH_LEAVES_TO_VALIDATE]: fetchLeavesToValidate,
    [Types.FETCH_LEAVES_TO_VALIDATE_DONE]: fetchLeavesToValidateDone,
    [Types.FETCH_LEAVES_TO_VALIDATE_FAIL]: fetchLeavesToValidateFail,

    [Types.ACCEPT_LEAVE]: acceptLeave,
    [Types.ACCEPT_LEAVE_DONE]: acceptLeaveDone,
    [Types.ACCEPT_LEAVE_FAIL]: acceptLeaveFail,

    [Types.REJECT_LEAVE]: rejectLeave,
    [Types.REJECT_LEAVE_DONE]: rejectLeaveDone,
    [Types.REJECT_LEAVE_FAIL]: rejectLeaveFail,

    [Types.CREATE_LEAVE]: createLeave,
    [Types.CREATE_LEAVE_DONE]: createLeaveDone,
    [Types.CREATE_LEAVE_FAIL]: createLeaveFail,

    [Types.UPDATE_LEAVE]: updateLeave,
    [Types.UPDATE_LEAVE_DONE]: updateLeaveDone,
    [Types.UPDATE_LEAVE_FAIL]: updateLeaveFail,

    [Types.DELETE_LEAVE]: deleteLeave,
    [Types.DELETE_LEAVE_DONE]: deleteLeaveDone,
    [Types.DELETE_LEAVE_FAIL]: deleteLeaveFail,

    [Types.FETCH_EMPLOYEE_LEAVE]: fetchEmployeeLeave,
    [Types.FETCH_EMPLOYEE_LEAVE_DONE]: fetchEmployeeLeaveDone,
    [Types.FETCH_EMPLOYEE_LEAVE_FAIL]: fetchEmployeeLeaveFail
}

export default createReducer(INITIAL_STATE, HANDLER)