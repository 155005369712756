
  
import { createReducer } from 'reduxsauce'
import {Types} from './action'
const INITIAL_STATE = {
    address: {}
}

export const storeAddress = (state = INITIAL_STATE, action ) => {
    return {
        address: action.address
    }
}
export const getAddress = (state = INITIAL_STATE, action ) => {
    return {
        address: action.address
    }
}
    
// handlers
export const HANDLERS = {
    
    [Types.STORE_ADDRESS]: storeAddress,
    [Types.GET_ADDRESS]: getAddress,
 
}

export default createReducer(INITIAL_STATE, HANDLERS)