import React, { Component } from 'react';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';

export class ToastItem {
  id = Date.now();

  /**
   * @private
   */
  _visible = true;

  /**
   * @private
   */
  _fade = false;

  /**
   * @private
   */
  _closeHandler = 0;

  /**
   * @private
   */
  _paused = false;

  title = '';
  message = '';
  icon = null;
  visibleTime = 5000;

  constructor(title, message, icon, visibleTime = 5000) {
    this.title = title;
    this.message = message;
    this.icon = icon;
    this.visibleTime = visibleTime;
  }
}

class ToasterState {
  /**
   * @private
   * @type {Toaster}
   */
  _component = null;

  /**
   * @type {ToastItem[]}
   */
  items = [];

  /**
   *
   * @param {Toaster} component
   */
  constructor(component) {
    this._component = component;
  }

  _update = () => {
    if (this._component === null) return;
    this._component.setState(this);
  };

  /**
   * Adds a new toast item in the list
   * @param {ToastItem} toast
   */
  addToast = (toast) => {
    toast._visible = true;
    toast._fade = false;
    toast._closeHandler = setTimeout(() => {
      this.closeToast(toast.id);
    }, toast.visibleTime);

    this.items.push(toast);
    this._update();
  };

  /**
   * Closes a toast with the given id
   * @param {Number} id
   */
  closeToast = (id) => {
    if (!this.items.some((i) => i.id === id)) return;

    this.items[this.items.findIndex((v) => v.id === id)]._fade = true;
    this._update();

    // TODO: Optimize this ?
    setTimeout(() => {
      this.items[this.items.findIndex((v) => v.id === id)]._visible = false;
      this._update();

      setTimeout(() => {
        this.items.splice(
          this.items.findIndex((v) => v.id === id),
          1
        );
        this._update();
      }, 250);
    }, 250);
  };
}

export class Toaster extends Component {
  constructor(props) {
    super(props);

    this.state = new ToasterState(this);
  }

  /**
   * Adds a new toast message.
   * @param {ToastItem} item
   */
  toast = (item) => {
    this.state.addToast(item);
  };

  /**
   * Tries to pause a toast dismiss.
   * @param {number} id
   */
  pause = (id) => {
    const toast = this.state.items.find((i) => i.id === id);
    if (!toast._paused) {
      clearTimeout(toast._closeHandler);
      toast._paused = true;
    }
  };

  /**
   * Resumes a paused toast.
   * @param {number} id
   */
  resume = (id) => {
    const toast = this.state.items.find((i) => i.id === id);
    if (toast._paused) {
      toast._closeHandler = setTimeout(() => {
        this.state.closeToast(toast.id);
      }, toast.visibleTime);
      toast._paused = false;
    }
  };

  /**
   * Closes the toast with the given id.
   * @param {Number} id
   */
  close = (id) => {
    const toast = this.state.items.find((i) => i.id === id);
    clearTimeout(toast._closeHandler);
    this.state.closeToast(id);
  };

  /**
   * Closes all toasts in the toaster.
   */
  closeAll = () => {
    this.state.items.forEach((i) => this.close(i.id));
  };

  render() {
    return (
      <React.Fragment>
        <div className="toaster-toast-container">
          {this.state.items.map((i) => (
            <Toast
              key={i.id}
              onMouseEnter={() => this.pause(i.id)}
              onMouseLeave={() => this.resume(i.id)}
              isOpen={i._visible}
              fade={i._fade}
              className={!i._fade ? 'animate__animated animate__fadeInRight animate__faster' : ''}>
              <ToastHeader toggle={() => this.close(i.id)} icon={i.icon}>
                {i.title}
              </ToastHeader>
              <ToastBody>{i.message}</ToastBody>
            </Toast>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
