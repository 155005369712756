import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {Types, Creators} from './actions'
// api import
import { 
    createDeviceApi, 
    updateDeviceApi, 
    fetchDeviceApi, 
    deleteDeviceApi, 
    fetchApiKeyApi,
    fetchNewApiKeyApi
} from './api'
// types import
const { CREATE_DEVICE,UPDATE_DEVICE,FETCH_DEVICE,DELETE_DEVICE, FETCH_API_KEY , FETCH_NEW_API_KEY} = Types
// success, fail import
const { createDeviceDone,
    createDeviceFail, 
    updateDeviceDone,
    updateDeviceFail, 
    fetchDevice,
    fetchDeviceDone,
    fetchDeviceFail, 
    deleteDeviceDone,
    deleteDeviceFail,
    fetchApiKeyDone,
    fetchApiKeyFail
 } = Creators

// sagas handler
function* fetchApiKeySaga(action){
    const {data, status} = yield call(fetchApiKeyApi)
    if (status){
        yield put(fetchApiKeyDone(data.apikey))
    }else{
        yield put(fetchApiKeyFail(data))
    }
}

function* fetchNewApiKeySaga(action){
    const {data, status} = yield call(fetchNewApiKeyApi)
    if (status){
        yield put(fetchApiKeyDone(data.apikey))
    }else{
        yield put(fetchApiKeyFail(data))
    }
}

function* createDeviceSaga(device){
    const response = yield call(createDeviceApi, device);
    if (response.status){
        //yield put(createDeviceDone());
        yield put(fetchDevice());// update device list
    }else{
        yield put(createDeviceFail(response))
    }
}

function* updateDeviceSaga(device){
    const response = yield call(updateDeviceApi, device);
    if (response.status){
      //  yield put(updateDeviceDone(response))
        yield put(fetchDevice());

    }else{
        yield put(updateDeviceFail(response))
    }
}

function* fetchDeviceSaga(device){
    const response = yield call(fetchDeviceApi, device);

    if (response.status){
        yield put(fetchDeviceDone(response.data))
    }else{
        yield put(fetchDeviceFail(response))
    }
}

function* deleteDeviceSaga(device){
    const response = yield call(deleteDeviceApi, device);
    if (response.status){
        yield put(deleteDeviceDone(response))
        yield put(fetchDevice());

    }else{
        yield put(deleteDeviceFail(response))
    }
}

//saga watchers

export function* watchFetchApiKey(){
    yield takeEvery(FETCH_API_KEY, fetchApiKeySaga)
    yield takeEvery(FETCH_NEW_API_KEY, fetchNewApiKeySaga)
}
export function* watchcreateDevice(){
    yield takeEvery(CREATE_DEVICE, createDeviceSaga)
}

export function* watchupdateDevice(){
    yield takeEvery(UPDATE_DEVICE, updateDeviceSaga)
}

export function* watchfetchDevice(){
    yield takeEvery(FETCH_DEVICE, fetchDeviceSaga)
}

export function* watchdeleteDevice(){
    yield takeEvery(DELETE_DEVICE, deleteDeviceSaga)
}

// saga builder

export default function* deviceSaga(){
    yield all([
        fork(watchcreateDevice),
        fork(watchupdateDevice),
        fork(watchfetchDevice),
        fork(watchdeleteDevice),
        fork(watchFetchApiKey)
    ])
}
    


    