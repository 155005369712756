import Cookies from 'universal-cookie'; 
import {api, handle_response} from '../../helpers/api'
const cookies = new Cookies();

export async function fetchShift(shift = 0){
    const url = shift === 0 ? '/shift?start=2020-01-01&end=2020-12-31' : '/shift/' + shift
    return await api.get(url).then(handle_response)
}
export async function showShift(shift){
    const url = '/shift/' + shift
    return await api.get(url).then(handle_response)
}

export async function fetchDefaultShiftApi(action){
    return await api.get('/shift/default').then(handle_response)
}

export async function createShift(shift){
    
    ///api.setHeader('Tenant', cookies.get('tenant'));
    return await api.post('/shift', {...shift}).then(handle_response)
}


export async function updateShift({shift}){
    //api.setHeader('Tenant', cookies.get('tenant'));
    return await api.patch('/shift/' + shift.pk, {...shift}).then(handle_response)
}


export async function deleteShift(shift_id){
    //api.setHeader('Tenant', cookies.get('tenant'));
    return await api.delete('/shift/'+ shift_id).then(handle_response)
}

export async function fetchShiftEmployee(shift){
    const url =  '/employees-shifts-eligible?shifts='+shift.shift.shifts+'&employees_for_regular=1'
    return await api.get(url).then(handle_response)
}

export async function fetchOvertimeShiftEmployee(shift){
    const url = '/employees-shifts-eligible?shifts='+shift.shifts+'&employees_for_overtime=1'
    return await api.get(url).then(handle_response)
}

export async function fetchOvertimeDateForShift(shift){
    const url = '/employees-shifts-eligible?shifts='+shift.shifts+'&employees_overtime_dates=1'
    return await api.get(url).then(handle_response)
}
export async function assignEmployeeShift(shift){
    const url = '/shift-assignment'
    return await api.post(url, [...shift]).then(handle_response)
}


export async function acceptAssignmentApi(shift_id, status){
    return await api.patch(`/shift-acceptance/${shift_id}`, {'user_accepted': status}).then(handle_response)
}

export async function createAttendance(shift){
    return await api.post('/attendance-fake-generation', shift.shift).then(handle_response)
}
export async function breakAttendanceCompletionApi(attendance){
    return await api.post('/attendance-fake-break-completion', attendance.attendance).then(handle_response)
}