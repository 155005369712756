import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchCompanies: ['offset', 'limit'],
  fetchCompaniesDone: ['companies', 'offset', 'limit'],
  fetchCompaniesFail: ['error'],

  fetchIndustries: null,
  fetchIndustriesDone: ['industries'],
  fetchIndustriesFail: ['error'],

  fetchLocations: null,
  fetchLocationsDone: ['locations'],
  fetchLocationsFail: ['error'],

  sendRFP: ['contract'],
  sendRFPDone: ['contract'],
  sendRFPFail: ['error'],

  sendInvite: ['invites'],
  sendInviteDone: null,
  sendInviteFail: ['error'],
});
