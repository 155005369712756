import { createActions } from 'reduxsauce'

export const {Types, Creators} = createActions({
        
    createRequirement: ['requirement'],
    createRequirementDone: ['requirement'],
    createRequirementFail: ['error'],
    
    updateRequirement: ['requirement'],
    updateRequirementDone: ['requirement'],
    updateRequirementFail: ['error'],
    
    fetchRequirement: ['requirements'],
    fetchRequirementDone: ['requirements'],
    fetchRequirementFail: ['error'],
    
    deleteRequirement: ['requirement'],
    deleteRequirementDone: ['requirement'],
    deleteRequirementFail: ['error'],

    
});
    