
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {Types, Creators} from './actions'
// api import
import { 
    createPTOApi, 
    updatePTOApi, 
    fetchPTOApi, 
    deletePTOApi, 
    fetchWorkerPTOList,
    workerDetails,
    ptoLocationApi,
    employeePtoLocationApi,
    CreatePtoTypeApi,
    updatePtoTypeApi,
    deletePtoTypeApi,
    PtoTypeApi,
    assignEmployeesApi,
    getUserDetailsApi
} from './api'
import { createPtoTypeDone, deletePtoTypeFail  } from './reducers';
// types import
const { CREATE_PTO,UPDATE_PTO,FETCH_PTO,DELETE_PTO, FETCH_WORKER_PTO_LIST, FETCH_DETAILS_WORKER, PTO_LOCATION, 
    EMPLOYEE_PTO_LOCATION, FETCH_PTO_TYPE, CREATE_PTO_TYPE,UPDATE_PTO_TYPE,DELETE_PTO_TYPE, ASSIGN_EMPLOYEES , FETCH_USER_DETAILS
} = Types
// success, fail import

const {
    createPTODone,
    createPTOFail, 
    updatePTODone,
    updatePTOFail, 
    fetchPTO,
    fetchPTODone,
    fetchPTOFail, 
    deletePTODone,
    deletePTOFail, 
    fetchWorkerPTOListDone,
    fetchWorkerPTOListFail,
    fetchDetailsWorker,
    fetchDetailsWorkerDone,
    fetchDetailsWorkerFail,
    ptoLocationDone,
    ptoLocationFail,
    employeePtoLocationDone,
    employeePtoLocationFail,
    fetchPtoTypeDone,
    fetchPtoTypeFail,
    fetchPtoType,
    createPtoTypeFail,
    updatePtoTypeFail,
    deleteProTypeFail,
    assignEmployeesDone,
    assignEmployeesFail,

    fetchUserDetailsDone,
    fetchUserDetailsFail,
} = Creators

// sagas handler

function* createPTOSaga(action){
    const response = yield call(createPTOApi, action.pto);
    if (response.status){
        for (const key in action.pto.locations) {
            if (action.pto.locations.hasOwnProperty(key)) {
                let ptoLocation = {
                    pto_policy : response.data.pk,
                    state : action.pto.locations[key]
                }
                const ptoLoc = yield call (ptoLocationApi, ptoLocation)
                if(ptoLoc.status){
                    yield put(ptoLocationDone(ptoLoc))
                }else {
                    yield put(ptoLocationFail(ptoLoc))
                }
            }
        }
        yield put(createPTODone(response))
        yield put(fetchPTO())
    }else{
        yield put(createPTOFail(response))
        
    }
}

function* updatePTOSaga(pto){
    const response = yield call(updatePTOApi, pto);
    if (response.status){
        yield put(updatePTODone(response))
        yield put(fetchPtoType());
    }else{
        yield put(updatePTOFail(response))
    }
}

function* fetchPTOSaga(pto){
    const response = yield call(fetchPTOApi, pto);
    if (response.status){
        yield put(fetchPTODone(response))
        
    }else{
        yield put(fetchPTOFail(response))
    }
}

function* deletePTOSaga(pto){
    const response = yield call(deletePTOApi, pto);
    if (response.status){
        yield put(deletePTODone(response))
        yield put(fetchPtoType())

    }else{
        yield put(deletePTOFail(response))
    }
}

function* fetchWorkerPTOListSaga({worker_id}){
    const response = yield call(fetchWorkerPTOList, worker_id)
    if(response.status){
        yield put(fetchWorkerPTOListDone(response.data))
    }else{
        yield put(fetchWorkerPTOListFail(response.data))
    }
}


function* fetchDetailsWorkerSaga(unique_id){
    const response = yield call(workerDetails, unique_id.worker)
    if(response.status){
        yield put(fetchDetailsWorkerDone(response))
    }else{
        yield put(fetchDetailsWorkerFail(response))
    }
}

function*  ptoLocationSaga(ptoLocation){
    const response = yield call(ptoLocationApi, ptoLocation)
    if(response.status){
        yield put(ptoLocationDone(response))
    }else{
        yield put(ptoLocationFail(response))
    }
}

function* employeePtoLocationSaga(employeePtoLocation){
    const response = yield call(employeePtoLocationApi, employeePtoLocation)
    if(response.status){
        yield put(employeePtoLocationDone(response))
    }else{
        yield put(employeePtoLocationFail(response))
    }
}

function* createPtoTypeSaga({ptoType}){
    const response = yield call(CreatePtoTypeApi, ptoType)

    if(response.status){
        yield put(fetchPtoType())
    }else{
        yield put(createPtoTypeFail(response))
    }
}
function* updatePtoTypeSaga({ptoType}){
    const response = yield call(updatePtoTypeApi, ptoType)

    if(response.status){
        yield put(fetchPtoType())
    }else{
        yield put(updatePtoTypeFail(response))
    }
}
function* deletePtoTypeSaga({ptoType}){
    const response = yield call(deletePtoTypeApi, ptoType)

    if(response.status){
        yield put(fetchPtoType())
    }else{
        yield put(deletePtoTypeFail(response))
    }
}
function* fetchPtoTypeSaga(){
    const response = yield call(PtoTypeApi)
    if(response.status){
        yield put(fetchPtoTypeDone(response))
    }else{
        yield put(fetchPtoTypeFail(response))
    }
}

function* assignEmployeeSaga({assignment}){
    const response = yield call(assignEmployeesApi, assignment)
    if(response.status){
        yield put(assignEmployeesDone(response))
    }else{
        yield put(assignEmployeesFail(response))
    }
}

function* fetchUserDetailsSaga(){
    const response = yield call(getUserDetailsApi)
    if(response.status){
        yield put(fetchUserDetailsDone(response.data));
    }else{
        yield put(fetchUserDetailsFail(response.data));
    }
}

//saga watchers

export function* watchCreatePTO(){
    yield takeEvery(CREATE_PTO, createPTOSaga)
}

export function* watchUpdatePTO(){
    yield takeEvery(UPDATE_PTO, updatePTOSaga)
    yield takeEvery(UPDATE_PTO_TYPE, updatePtoTypeSaga)
}

export function* watchFetchPTO(){
    yield takeEvery(FETCH_PTO, fetchPTOSaga)
    yield takeEvery(FETCH_PTO_TYPE, fetchPtoTypeSaga)
}

export function* watchDeletePTO(){
    yield takeEvery(DELETE_PTO, deletePTOSaga)
    yield takeEvery(DELETE_PTO_TYPE, deletePtoTypeSaga)
}

export function* watchFetchWorkerPTOList(){
    yield takeEvery(FETCH_WORKER_PTO_LIST,fetchWorkerPTOListSaga)
}

export function* watchFetchDetailsWorker(){
    yield takeEvery(FETCH_DETAILS_WORKER, fetchDetailsWorkerSaga)
    yield takeEvery(FETCH_USER_DETAILS, fetchUserDetailsSaga)
}   
export function* watchPtoLocation(){
    yield takeEvery(PTO_LOCATION, ptoLocationSaga)
};
export function* watchEmployeePtoLocation() {
    yield takeEvery(EMPLOYEE_PTO_LOCATION, employeePtoLocationSaga)
}
export function* watchCreatePtoType(){
    yield takeEvery(CREATE_PTO_TYPE, createPtoTypeSaga)
}

export function* watchAssignEmployee(){
    yield takeEvery(ASSIGN_EMPLOYEES, assignEmployeeSaga)
}

// saga builder

export default function* ptoSaga(){
    yield all([
        fork(watchCreatePTO),
        fork(watchUpdatePTO),
        fork(watchFetchPTO),
        fork(watchDeletePTO),
        fork(watchFetchWorkerPTOList),
        fork(watchFetchDetailsWorker),
        fork(watchPtoLocation),
        fork(watchEmployeePtoLocation),
        fork(watchCreatePtoType),
        fork(watchAssignEmployee)
    ])
}
    


    