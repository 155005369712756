
import { createActions } from 'reduxsauce'

export const {Types, Creators} = createActions({

   
    fetchRole: ['role'],
    fetchRoleDone: ['roles'],
    fetchRoleFail: ['error'],

    createRole: ['role'],
    createRoleDone: ['role'],
    createRoleFail: ['error'],
    
    updateRole: ['role'],
    updateRoleDone: ['role'],
    updateRoleFail: ['error'],

    deleteRole: ['role'],
    deleteRoleDone: ['role'],
    deleteRoleFail: ['error'],

    fetchPermission: ['permission'],
    fetchPermissionDone: ['permissions'],
    fetchPermissionFail: ['error'],

});
