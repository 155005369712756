
import { createActions } from 'reduxsauce'

export const {Types, Creators} = createActions({

    createLocation: ['location'],
    createLocationDone: ['location'],
    createLocationFail: ['error'],

    updateLocation: ['location'],
    updateLocationDone: ['location'],
    updateLocationFail: ['error'],

    fetchLocation: ['location'],
    fetchLocationDone: ['location'],
    fetchLocationFail: ['error'],

    deleteLocation: ['location'],
    deleteLocationDone: ['location'],
    deleteLocationFail: ['error'],

    fetchRates: null,
    fetchRatesDone: ['rates'],
    fetchRatesFail: ['error'],

    assignEmployeesLocation: ['assignment'],
    assignEmployeesLocationDone: null,
    assignEmployeesLocationFail: ['error'],
    
    uploadLocation: ['location_obj'],
    uploadLocationDone: ['obj'],
    uploadLocationFail: ['error'],
});
