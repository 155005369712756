
import { createActions } from 'reduxsauce'

export const {Types, Creators} = createActions({
        
    createPayroll: ['payroll'],
    createPayrollDone: ['payroll'],
    createPayrollFail: ['error'],
    
    updatePayroll: ['payroll'],
    updatePayrollDone: ['payroll'],
    updatePayrollFail: ['error'],
    
    fetchPayroll: null,
    fetchPayrollDone: ['payroll'],
    fetchPayrollFail: ['error'],
    
    deletePayroll: ['payroll'],
    deletePayrollDone: null,
    deletePayrollFail: ['error']
    
});
    