import axios from 'axios';
import { create } from 'apisauce';
import Cookies from 'universal-cookie';
import _ from 'lodash';
import { logoutUser } from '../redux/auth/actions';
import { store } from '../index';
import { createBrowserHistory } from 'history';

// const store = configureStore()
const cookies = new Cookies();

export const BASE_URL = 'https://api.dev.workerly.io/api/v1';
// export const BASE_URL = 'http://127.0.0.1:8000/api/v1';

// define the api
//const token = cookies.get('user_key') ? cookies.get('user_key') : ''
const tenant = cookies.get('user_tenant') ? cookies.get('user_tenant') : 'workerly.io';
export const api = create({
  baseURL: BASE_URL,
  headers: { Tenant: tenant },
});

// todoL fetcth token and refresh function todo

/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
const fetchJSON = async (url, options = {}) => {
  try {
    const response = await fetch(url, options);
    if (!response.status === 200) {
      throw response.json();
    }

    const json = await response.json();
    return json;
  } catch (error) {
    throw error;
  }
};

const registerApi = async (name, email) => {
  return await api.post('/accounts/signup', { first_name: name, email: email }).then(function (response) {
    if (response.ok) {
      return { status: true, ...response.data };
    } else {
      return { status: false, ...response.data };
    }
  });
};

const loginApi = async (email, password) => {
  api.setHeader('Tenant', 'workerly.io');
  return await api
    .post('/token', {
      email: email,
      password: password,
    })
    .then(function (response) {
      if (response.ok) {
        const { user } = response.data;
        if (user.company === null) {
          return { status: true, ...response.data };
        }
        return { status: true, ...response.data };
      } else {
        return { status: false, ...response.data };
      }
    });
};

const logoutApi = async () => {
  return await api.post('/logout').then(function (response) {
    if (response.ok) {
      return { status: true, ...response.data };
    } else {
      return { status: false, ...response.data };
    }
  });
};

const getUserDetailApi = async (init_company = false) => {
  return await api.get('/user-details').then(handle_response);
};

const getUserAccountDetailApi = async () => {
  return await api.get('/account-details').then(handle_response);
};

const getAllIndustries = async () => {
  return await api.get('/industries', {}, { headers: { Tenant: 'workerly.io' } }).then(handle_response);
};

const compagnyCreateApi = async (company) => {
  api.deleteHeader('Tenant');
  api.setHeader('Authorization', 'Bearer ' + company.token);
  return await api.post('/company', { ...company }).then(handle_response);
};

const getAllRates = async () => {
  return await api.get('/rate', {}, { headers: { Tenant: 'workerly.io' } }).then(handle_response);
};

const locationListApi = async () => {
  return await axios
    .get(BASE_URL + '/location')
    .then(function (response) {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch(function (error) {
      return error;
    });
};
const changePassword = async (email) => {
  return await axios
    .post(BASE_URL + '/accounts/send-reset-password-link/', {
      login: email,
    })
    .then(function (response) {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch(function (error) {
      return error;
    });
};
const verify = async (code) => {
  return await axios
    .post(BASE_URL + '/verify/', {
      code: code,
    })
    .then(function (response) {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch(function (error) {
      return error;
    });
};
export {
  getUserDetailApi,
  fetchJSON,
  registerApi,
  loginApi,
  locationListApi,
  changePassword,
  compagnyCreateApi,
  getAllRates,
  verify,
  getAllIndustries,
  getUserAccountDetailApi,
  logoutApi,
};

/**
 * the handle the return of any simple query as the all get
 * normalized by Apisauce this function just build a cleanner response object
 * with 2 fields for the response
 * @param {*} response
 */
export function handle_response(response) {
  const __ = { data: response.data, status: false, error: '' };
  if (response.ok) {
    __.status = true;
  } else {
    if (response.status === 404 && response.data.code === 'T002') {
      store.dispatch(logoutUser());
      //return history.push("/account/logout")
    }
    if (response.status === 401 && response.data.code === 'T002') {
      store.dispatch(logoutUser());
      //return history.push("/account/logout")
    }
    __.status = false;
    __.error = (() => {
      if (_.isObject(response.data) && _.has(response.data, 'detail')) return response.data.detail;

      if (_.isString(response.data)) return response.data;

      if (!_.isEmpty(response.error)) return response.error;

      return 'An error occurred. Please try again later. If the error persist, please contact support for help.';
    })();
  }

  return __;
}
