import _ from 'lodash';
import moment from 'moment';
import CompanyModel from './company';
import ContractLocationModel from './contract_location';
import Cookies from 'universal-cookie'
import ProjectModel from './Project';
import RequirementModel from './Requirement';

const cookies = new Cookies()

export default class ContractModel {
  /**
   * @type {String}
   */
  id = '';

  /**
   * @type {String}
   */
  title = '';

  /**
   * @type {String}
   */
  description = '';

  /**
   * @type {String}
   */
  instructions = '';

  /**
   * @type {String}
   */
  companyId = '';

  /**
   * @type {Date}
   */
  startDate = null;

  /**
   * @type {Date}
   */
  endDate = null;

  /**
   * @type {Date}
   */
  created = null;

  /**
   * @type {Date}
   */
  modified = null;

  /**
   * @type {Boolean}
   */
  isActive = false;

  /**
   * @type {Boolean}
   */
  isDraft = false;

  /**
   * @type {'PENDING' | 'ACCEPTED' | 'REJECTED' | 'CLOSED'}
   */
  status = 'PENDING';

  /**
   * @type {ContractLocationModel[]}
   */
  locations = [];

  /**
   * @type {String[]}
   */
  targetCompaniesId = [];

  /**
   * @type {CompanyModel[]}
   */
  targetCompanies = [];

  /**
   * @type {Number}
   */
  workersNb = 0;

  /**
   * @type {Number}
   */
  locationsNb = 0;

  /**
   * @type {Number}
   */
  bidsCount = 0;

  /**
   * @type {CompanyModel}
   */
  emitter = cookies.get('user').user.company.pk;
  /**
   * @type {ProjectModel}
   */
  project = {}

  /**
   * @type {RequirementModel}
   * 
   */
  requirements_values = []

  constructor() {
    this.toJson = this.toJson.bind(this);
  }

  /**
   * Converts this instance in json format
   * @returns {Object} The json format of this instance.
   */
  toJson() {
    return {
      pk: this.id,
      contract_comp: this.companyId,
      start_date: moment(this.startDate).format('YYYY-MM-DD'),
      created: this.created,
      modified: this.modified,
      end_date: moment(this.endDate).format('YYYY-MM-DD'),
      contract_locations: this.locations.map((i) => i.toJson()),
      is_active: this.isActive,
      accept_status: this.status,
      number_of_workers: this.workersNb,
      number_of_locations: this.locationsNb,
      target_companies: this.targetCompaniesId,
      title: this.title,
      is_draft: this.isDraft,
      description: this.description,
      instructions: this.instructions,
      bid_count: this.bidsCount,
      emitter : this.emitter,
      project : this.project,
      requirements_values : this.requirements_values
    };
  }

  /**
   * Creates a new instance of ContractModal from a json data.
   * @param {Object} json The json data to parse into an ContractModal instance.
   * @returns {ContractModel}
   */
  static fromJson(json) {
    const model = new ContractModel();

    if (json !== null && json !== undefined) {
      model.id = json.pk;
      model.companyId = json.contract_comp;
      model.startDate = new Date(json.start_date);
      model.created = json.created;
      model.modified = json.modified;
      model.endDate = new Date(json.end_date);
      model.isActive = json.is_active;
      model.status = json.accept_status;
      model.workersNb = json.number_of_workers;
      model.locationsNb = json.number_of_locations;
      model.title = json.title;
      model.isDraft = json.is_draft;
      model.description = json.description;
      model.bidsCount = json.bid_count;
      model.emitter = json.emitter;
      model.project = ProjectModel.fromJson(json.project);
      model.requirements_values = json.requirements_values

      if (_.isArray(json.target_companies)) {
        model.targetCompanies = json.target_companies.map((i) => CompanyModel.fromJson(i));
        model.targetCompaniesId = model.targetCompanies.map((i) => i.id);
      }

      if (_.isArray(json.requirements_values)) {
        model.requirements_values = json.requirements_values.map((i) => RequirementModel.fromJson(i));
      }
      

      if (_.isArray(json.contract_locations)) {
        model.locations = json.contract_locations.map((i) => ContractLocationModel.fromJson(i));
      }
    }

    return model;
  }
}
