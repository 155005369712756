import { isArray } from 'lodash';
import LocationModel from './location';
import IndustryModel from './industry';

export default class CompanyModel {
  /**
   * @type {String}
   */
  id = '';

  /**
   * @type {LocationModel}
   */
  headLocation = null;

  /**
   * @type {LocationModel[]}
   */
  locations = [];

  /**
   * @type {IndustryModel[]}
   */
  industries = [];

  /**
   * @type {String}
   */
  name = '';

  /**
   * @type {String}
   */
  cover = '';

  /**
   * @type {String}
   */
  description = '';

  /**
   * @type {String}
   */
  logo = '';

  /**
   * @type {String}
   */
  email = '';

  /**
   * @type {String}
   */
  phone = '';

  /**
   * @type {String}
   */
  specialities = '';

  constructor() {
    this.toJson = this.toJson.bind(this);
  }

  toJson() {
    return {
      pk: this.id,
      head_location: this.headLocation.toJson(),
      locations: this.locations.map((i) => i.toJson()),
      industries: this.industries.map((i) => i.toJson()),
      name: this.name,
      public_cover_image: this.cover,
      public_description: this.description,
      public_email: this.email,
      public_logo: this.logo,
      public_phone: this.phone,
      specialities: this.specialities,
    };
  }

  static fromJson(json) {
    const model = new CompanyModel();

    if (json !== null && json !== undefined) {
      model.id = json.pk;
      model.headLocation = LocationModel.fromJson(json.head_location);
      model.name = json.name;
      model.cover = json.public_cover_image;
      model.description = json.public_description;
      model.email = json.public_email;
      model.logo = json.public_logo;
      model.phone = json.public_phone;
      model.specialities = json.specialities;

      if (isArray(json.industries)) {
        model.industries = json.industries.map((i) => IndustryModel.fromJson(i));
      }

      if (isArray(json.locations)) {
        model.locations = json.locations.map((i) => LocationModel.fromJson(i));
      }
    }

    return model;
  }
}
