import LocationModel from './location';

export default class ContractLocationModel {
  /**
   * @description The item id.
   * @type {String}
   */
  id = '';

  /**
   * @type {String}
   */
  locationId = '';

  /**
   * @description The location.
   * @type {LocationModel}
   */
  location = null;

  /**
   * @description The number of contractors.
   * @type {Number}
   */
  contractorsNb = 0;

  /**
   * @description The hourly rate.
   * @type {Number}
   */
  hourlyRate = 0;

  /**
   * Constructs a new instance of ContractLocationModel.
   * @param {String} id The object id.
   */
  constructor(id) {
    this.id = id;
    this.toJson = this.toJson.bind(this);
  }

  /**
   * Converts this instance in json format
   * @returns {Object} The json format of this instance.
   */
  toJson() {
    const locationId = this.location !== null ? this.location.id : this.locationId;
    return {
      pk: this.id,
      location: locationId,
      number_of_workers: this.contractorsNb,
      hour_rate: this.hourlyRate,
    };
  }

  /**
   * Creates a new instance of ContractLocationModel from a json data.
   * @param {Object} json The json data to parse into an ContractLocationModel instance.
   * @returns {ContractLocationModel}
   */
  static fromJson(json) {
    const model = new ContractLocationModel();

    if (json !== null && json !== undefined) {
      model.id = json.pk;
      model.locationId = json.location;
      model.contractorsNb = json.number_of_workers;
      model.hourlyRate = json.hour_rate;
    }

    return model;
  }
}
