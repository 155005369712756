/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED';
export const VERIFY = 'VERIFY';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const VERIFY_FAILED = 'VERIFY_FAILED';
export const CREATE_COMPANY = 'CREATE_COMPANY'
export const COMPAGNY_CREATE_SUCCESS = 'COMPAGNY_CREATE_SUCCESS';
export const COMPAGNY_CREATE_FAILED = 'COMPAGNY_CREATE_FAILED';
export const COMPANY_DONT_EXIST = 'COMPANY_DONT_EXIST';
export const EMAIL_ALREADY_EXIST = 'EMAIL_ALREADY_EXIST';
export const SET_SHOW_TO_FALSE = 'SET_SHOW_TO_FALSE';
export const GET_USER_DETAIL = 'GET_USER_DETAIL'
export const GET_USER_DETAIL_DONE = 'GET_USER_DETAIL_DONE'
export const GET_USER_DETAIL_FAIL = 'GET_USER_DETAIL_FAIL'
export const GET_USER_ACCOUNT_DETAILS = 'GET_USER_ACCOUNT_DETAILS'
export const GET_USER_ACCOUNT_DETAILS_DONE = 'GET_USER_ACCOUNT_DETAILS_DONE'
export const GET_USER_ACCOUNT_DETAILS_FAIL = 'GET_USER_ACCOUNT_DETAILS_FAIL'
