
import { createActions } from 'reduxsauce'

export const {Types, Creators} = createActions({
        
    fetchAttachments: ['project'],
    fetchAttachmentsDone: ['attachments'],
    fetchAttachmentsFail: ['error'],

    fetchProjects: ['project'],
    fetchProjectsDone: ['projects'],
    fetchProjectsFail: ['error'],

    createProject: ['project'],
    createProjectDone: ['project'],
    createProjectFail: ['error'],

    createAttachment: ['attachment'],
    createAttachmentDone: ['attachment'],
    createAttachmentFail: ['error'],

    updateProject : ['project'],
    updateProjectDone : ['project'],
    updateProjectFail : ['error'],

    updateAttachment: ['attachment'],
    updateAttachmentDone: ['attachment'],
    updateAttachmentFail: ['error'],

    deleteProject : ['project'],
    deleteProjectDone : ['project'],
    deleteProjectFail : ['error'],

    deleteAttachment: ['attachment'],
    deleteAttachmentDone: ['attachment'],
    deleteAttachmentFail: ['error'],

    assignAttachmentProject: ['attachment'],
    assignAttachmentProjectDone: ['payroll'],
    assignAttachmentProjectFail: ['error'],
    
});
    