
import { createReducer } from 'reduxsauce'
import {Types} from './actions'
const INITIAL_STATE = {
error: null, jobs: [], loading: false,errorCreated : null, loadingCreate : false, 
assignPositionStatus: '', loadingImport: false,importObject: {},uploading: false,errorImport: null
}

// reducers here


// init case
export const createJob = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true,
        
    }
}


// good case
export const createJobDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
    }
}


// bad case
export const createJobFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        error: action.error
    }
}



// init case
export const updateJob = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true
    }
}


// good case
export const updateJobDone = (state = INITIAL_STATE, action ) => {
    
    return {
        ...state,
        loading: false
    }
}


// bad case
export const updateJobFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        //job: [],
        error: action.error,
        loading: false,
    }
}


// init case
export const fetchJob = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true,
    }
}


// good case
export const fetchJobDone = (state = INITIAL_STATE, action ) => {
    
    return {
        ...state,
        loading: false,
        jobs: action.jobs
    }
}


// bad case
export const fetchJobFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        //job: null,
        loading: false,
        error: action.error
    }
}

// init case
export const deleteJob = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: true
    }
}


// good case
export const deleteJobDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false
    }
}


// bad case
export const deleteJobFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        error: action.error
    }
}

export const assignPositionEmployee = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: true,
        assignPositionStatus: ''
    }
}

export const assignPositionEmployeeDone = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        assignPositionStatus: 'DONE'
    }
}

export const assignPositionEmployeeFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        assignPositionStatus: 'FAIL'
    }
}
export const uploadJob = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loadingImport: true,
        uploading: true
    }
}

export const uploadJobDone = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        
        loadingImport: false,
        importObject: action.obj,
        uploading: false,
        errorImport: null
    }
}

export const uploadJobFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loadingImport: false,
        uploading: false,
        errorImport: action.error
    }
}

    // handlers
export const HANDLERS = {
    
    [Types.CREATE_JOB]: createJob,
    [Types.CREATE_JOB_DONE]: createJobDone,
    [Types.CREATE_JOB_FAIL]: createJobFail,
    
    [Types.UPDATE_JOB]: updateJob,
    [Types.UPDATE_JOB_DONE]: updateJobDone,
    [Types.UPDATE_JOB_FAIL]: updateJobFail,
    
    [Types.FETCH_JOB]: fetchJob,
    [Types.FETCH_JOB_DONE]: fetchJobDone,
    [Types.FETCH_JOB_FAIL]: fetchJobFail,

    [Types.DELETE_JOB]: deleteJob,
    [Types.DELETE_JOB_DONE]: deleteJobDone,
    [Types.DELETE_JOB_FAIL]: deleteJobFail,

    [Types.UPLOAD_JOB]: uploadJob,
    [Types.UPLOAD_JOB_DONE]: uploadJobDone,
    [Types.UPLOAD_JOB_FAIL]: uploadJobFail,
    
}
export default createReducer(INITIAL_STATE, HANDLERS)
