
import { createReducer } from 'reduxsauce'
import {Types} from './actions'
const LOADING = 'LOADING'
const SUCCESS = 'SUCCESS'
const FAIL = 'FAIL'

const INITIAL_STATE = {
    error: null, attendance: [], loading: false, financial: [], time_interval: [],
    approvingAttendance: 'HOLD', fileAttendances: [], current_attendance: {},
    timesheet: [], payroll : [], globalPayroll: [], acceptAssignmentData : null, acceptedLoading : false, acceptAssignmentError : null,acceptAssignmentSuccess: false
}

// reducers here

        
// init case
export const fetchAttendance = (state = INITIAL_STATE, action ) => {
    
    return {
        ...state,
        loading: true,
        time_interval: action.time_interval
    }
}


// good case
export const fetchAttendanceDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        attendance: action.attendance.results,
        loading: false
    }
}


// bad case
export const fetchAttendanceFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        error: true,
        loading: false,
        error: action.error
    }
}

export const fetchFileAttendance = (state = INITIAL_STATE, action ) => {
    
    return {
        ...state,
        loading: true,
    }
}


// good case
export const fetchFileAttendanceDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        fileAttendances: action.attendances,
        loading: false
    }
}


// bad case
export const fetchFileAttendanceFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        error: action.error
    }
}
    

export const fetchFinance = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: true,
        time_interval: action.time_interval
    }
}

export const fetchFinanceDone = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        financial: action.financial_info
    }
}

export const fetchFinanceFail = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        error: action.error
    }
}

export const approveAttendance = (state = INITIAL_STATE, action ) => {
    
    return {
        ...state,
        approvingAttendance: LOADING,
    }
}


export const approveAttendanceDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        approvingAttendance: SUCCESS,
    }
}

export const approveAttendanceFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        approvingAttendance: FAIL,
        error: action.error
    }
}
// init case
export const editAttendance = (state = INITIAL_STATE, action ) => {
    
    return {
        ...state,
        loading: true,
        current_attendance: action.attendance
    }
}


// good case
export const editAttendanceDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        current_attendance: action.attendance,
        loading: false
    }
}


// bad case
export const editAttendanceFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        error: action.error
    }
}



// init case
export const completeBreak = (state = INITIAL_STATE, action ) => {
    
    return {
        ...state,
        loading: true,
        current_attendance: action.attendance
    }
}


// good case
export const completeBreakDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        current_attendance: action.attendance,
        loading: false
    }
}


// bad case
export const completeBreakFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        loading: false,
        error: action.error
    }
}

export const fetchTimesheet = (state = INITIAL_STATE, action ) =>  {
    return {
        ...state,
        loading: true
    }
}

export const fetchTimesheetDone = (state = INITIAL_STATE, action ) =>  {
    return {
        ...state,
        loading: false,
        timesheet: action.timesheet.results
    }
}

export const fetchTimesheetFail = (state = INITIAL_STATE, action ) =>  {
    return {
        ...state,
        loading: false,
        error: action.error
    }
}


export const fetchAllTimesheet = (state = INITIAL_STATE, action ) =>  {
    return {
        ...state,
        loading: true
    }
}

export const fetchAllTimesheetDone = (state = INITIAL_STATE, action ) =>  {
    
    return {
        ...state,
        loading: false,
        timesheet: action.timesheet
    }
}

export const fetchAllTimesheetFail = (state = INITIAL_STATE, action ) =>  {
    return {
        ...state,
        loading: false,
        error: action.error
    }
}


export const fetchAllPayroll = (state = INITIAL_STATE, action ) =>  {
    return {
        ...state,
        loading: true
    }
}

export const fetchAllPayrollDone = (state = INITIAL_STATE, action ) =>  {
    
    return {
        ...state,
        loading: false,
        globalPayroll: action.payroll
    }
}

export const fetchAllPayrollFail = (state = INITIAL_STATE, action ) =>  {
    return {
        ...state,
        loading: false,
        error: action.error
    }
}


export const fetchPayroll = (state = INITIAL_STATE, action ) =>  {
    
    return {
        ...state,
        loading: true
    }
}

export const fetchPayrollsDone = (state = INITIAL_STATE, action ) =>  {
    
    return {
        ...state,
        loading: false,
        payroll: action.payroll
    }
}

export const fetchPayrollsFail = (state = INITIAL_STATE, action ) =>  {
    return {
        ...state,
        loading: false,
        error: action.error
    }
}


        
// init case
export const acceptAssignment = (state = INITIAL_STATE, action ) => {
    
    return {
        ...state,
        acceptedLoading: true,
        acceptAssignmentSuccess : false

    }
}


// good case
export const acceptAssignmentDone = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        acceptAssignmentData: action.attendance,
        acceptedLoading: false,
        acceptAssignmentSuccess : true
    }
}


// bad case
export const acceptAssignmentFail = (state = INITIAL_STATE, action ) => {
    return {
        ...state,
        acceptedLoading: false,
        acceptAssignmentError: action.error,
        acceptAssignmentSuccess : false
    }
}
// handlers
export const HANDLERS = {
    
    [Types.FETCH_ATTENDANCE]: fetchAttendance,
    [Types.FETCH_ATTENDANCE_DONE]: fetchAttendanceDone,
    [Types.FETCH_ATTENDANCE_FAIL]: fetchAttendanceFail,

    [Types.FETCH_FILE_ATTENDANCE]: fetchFileAttendance,
    [Types.FETCH_FILE_ATTENDANCE_DONE]: fetchFileAttendanceDone,
    [Types.FETCH_FILE_ATTENDANCE_FAIL]: fetchFileAttendanceFail,

    [Types.FETCH_FINANCE]: fetchFinance,
    [Types.FETCH_FINANCE_DONE]: fetchFinanceDone,
    [Types.FETCH_FINANCE_FAIL]: fetchFinanceFail,

    [Types.APPROVE_ATTENDANCE]: approveAttendance,
    [Types.APPROVE_ATTENDANCE_DONE]: approveAttendanceDone,
    [Types.APPROVE_ATTENDANCE_FAIL]: approveAttendanceFail,

    [Types.EDIT_ATTENDANCE]: editAttendance,
    [Types.EDIT_ATTENDANCE_DONE]: editAttendanceDone,
    [Types.EDIT_ATTENDANCE_FAIL]: editAttendanceFail,

    [Types.COMPLETE_BREAK]: completeBreak,
    [Types.COMPLETE_BREAK_DONE]: completeBreakDone,
    [Types.COMPLETE_BREAK_FAIL]: completeBreakFail,

    [Types.FETCH_TIMESHEET]: fetchTimesheet,
    [Types.FETCH_TIMESHEET_DONE]: fetchTimesheetDone,
    [Types.FETCH_TIMESHEET_FAIL]: fetchTimesheetFail,

    [Types.FETCH_ALL_TIMESHEET]: fetchAllTimesheet,
    [Types.FETCH_ALL_TIMESHEET_DONE]: fetchAllTimesheetDone,
    [Types.FETCH_ALL_TIMESHEET_FAIL]: fetchAllTimesheetFail,

    [Types.FETCH_ALL_PAYROLL]: fetchAllPayroll,
    [Types.FETCH_ALL_PAYROLL_DONE]: fetchAllPayrollDone,
    [Types.FETCH_ALL_PAYROLL_FAIL]: fetchAllPayrollFail,

    [Types.FETCH_PAYROLLS]: fetchPayroll,
    [Types.FETCH_PAYROLLS_DONE]: fetchPayrollsDone,
    [Types.FETCH_PAYROLLS_FAIL]: fetchPayrollsFail,

    [Types.ACCEPT_ASSIGNMENT]: acceptAssignment,
    [Types.ACCEPT_ASSIGNMENT_DONE]: acceptAssignmentDone,
    [Types.ACCEPT_ASSIGNMENT_FAIL]: acceptAssignmentFail,
}
    
export default createReducer(INITIAL_STATE, HANDLERS)