import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {Types, Creators} from './actions'
import Cookies  from 'universal-cookie'
// api import
import { 
createWeekworkApi, 
updateWeekworkApi, 
fetchWeekworkApi, 
deleteWeekworkApi
} from './api'
import { watchcreateHoliday } from '../holidays/sagas';
// types import
const { CREATE_WEEKWORK,UPDATE_WEEKWORK,FETCH_WEEKWORK,DELETE_WEEKWORK} = Types
// success, fail import
const { 
    createWeekworkDone,
    createWeekworkFail, 
    updateWeekworkDone,
    updateWeekworkFail, 
    fetchWeekworkDone,
    fetchWeekworkFail, 
    deleteWeekworkDone,
    deleteWeekworkFail,
    fetchWeekwork
 } = Creators

// sagas handler

function* createWeekworkSaga(weekwork){
    const response = yield call(createWeekworkApi, weekwork);
    if (response.status){
        yield put(createWeekworkDone(response))
        yield put(fetchWeekwork());
    }else{
        yield put(createWeekworkFail(response))
    }
}

function* updateWeekworkSaga(weekwork){
    const response = yield call(updateWeekworkApi, weekwork);
    if (response.status){
        //yield put(updateWeekworkDone(response))
        yield put(fetchWeekwork());
    }else{
        yield put(updateWeekworkFail(response))
    }
}

function* fetchWeekworkSaga(){
    const response = yield call(fetchWeekworkApi);
    if (response.status){
        let cookies = new Cookies()
       // cookies.set('departments', response.data, {path:"/"})
        yield put(fetchWeekworkDone(response.data.results))
    }else{
        yield put(fetchWeekworkFail(response))
    }
}

function* deleteWeekworkSaga(weekwork){
    const response = yield call(deleteWeekworkApi, weekwork);
    if (response.status){
        yield put(deleteWeekworkDone(response))
        yield put(fetchWeekwork());

    }else{
        yield put(deleteWeekworkFail(response))
    }
}
    
//saga watchers

export function* watchcreateWeekwork(){
    yield takeEvery(CREATE_WEEKWORK, createWeekworkSaga)
}

export function* watchupdateWeekwork(){
    yield takeEvery(UPDATE_WEEKWORK, updateWeekworkSaga)
}

export function* watchfetchWeekwork(){
    yield takeEvery(FETCH_WEEKWORK, fetchWeekworkSaga)
}

export function* watchdeleteWeekwork(){
    yield takeEvery(DELETE_WEEKWORK, deleteWeekworkSaga)
}


    
// saga builder

export default function* WeekworkSaga(){
    yield all([
        fork(watchcreateWeekwork),
        fork(watchupdateWeekwork),
        fork(watchfetchWeekwork),
        fork(watchdeleteWeekwork),
    ])
}