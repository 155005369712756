import _ from 'lodash'
import { format } from 'date-fns';
import { DATETIME_FORMAT } from '../helpers/utils'

export default class PayrollModel {
    pk = '';
    name = '';
    isDefault = true;
    payroll_schedule = 0;
    start = null;
    timesheetComputeDelay = 3;
    yearPayrolls = [];

    static getScheduleLabel(schedule){
        switch (schedule) {
            case 0:
                return 'Monthly'
            case 1:
                return "Semi-Monthly"
            case 2:
                return "Bi-Weekly"
            case 3:
                return "Weekly"
        
        }
    }
    
    
    static scheduleOptions(){
        return [
            {
                label: 'Monthly',
                value: 0
            },
            {
                label: 'Semi-Monthly',
                value: 1
            },
            {
                label: 'Bi-Weekly',
                value: 2
            },
            {
                label: 'Weekly',
                value: 3
            }
        ]
    }

    construcor() {
        this.toJson = this.toJson.bind(this);
    }

    toJson() {
        // need to convert start into string with HH:MM:SS form
        return {
            pk: this.pk,
            name: this.name,
            is_default: this.isDefault,
            payroll_schedule: this.payroll_schedule,
            start: this.start,
            timesheet_compute_delay: this.timesheetComputeDelay,
            yearPayrolls: this.yearPayrolls,
        }
    }

    static fromJson(json){
        const model = new PayrollModel();
        if (json !== null && json !== undefined){
            model.pk = json.pk;
            model.start = json.start;
            model.name = json.name;
            model.payroll_schedule = json.payroll_schedule;
            model.isDefault = json.default;
            model.timesheetComputeDelay = json.timesheet_compute_delay;
            model.yearPayrolls = json.year_payrolls;
        }
        return model;
    }

    /**
     * 
     * @param {Array} jsonList list of payroll object from api in json.
     */
    static fromJsonList(jsonList){
        let _obj_list = []
        if(!_.isArray(jsonList)){
            return []
        }
        jsonList.map((element, index) => {
            _obj_list.push(PayrollModel.fromJson(element))
        })
        return _obj_list
    }

    static columnHeaders(){
        return ['pk', 'name', 'payroll_schedule', 'default', 'timesheet_compute_delay']
    }
}
