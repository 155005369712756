import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Types, Creators } from './actions';
import { Creators as PtoCreators } from '../pto/actions';
import {
  fetchShift,
  createShift,
  updateShift,
  deleteShift,
  fetchDefaultShiftApi,
  fetchShiftEmployee,
  fetchOvertimeShiftEmployee,
  fetchOvertimeDateForShift,
  assignEmployeeShift,
  showShift,
  acceptAssignmentApi,
  createAttendance,
  breakAttendanceCompletionApi,
} from './api';
import { assignShift } from './reducers';
const {
  CREATE_SHIFT,
  SHOW_SHIFT,
  FETCH_SHIFT,
  FETCH_DEFAULT_SHIFT,
  UPDATE_SHIFT,
  DELETE_SHIFT,
  FETCH_SHIFT_EMPLOYEE,
  FETCH_OVERTIME_SHIFT_EMPLOYEE,
  FETCH_OVERTIME_DATE_FOR_SHIFT,
  ASSIGN_SHIFT,
  ACCEPT_SHIFT_ASSIGNMENT,
  CREATE_ATTENDANCE,
  BREAK_ATTENDANCE_COMPLETION,
} = Types;
const {
  createShiftDone,
  createShiftFail,

  fetchShiftSuccess,
  fetchShiftFail,

  setShift,

  showShiftSuccess,
  showShiftFail,

  updateShiftDone,
  updateShiftFail,

  deleteShiftDone,
  deleteShiftFail,

  fetchDefaultShiftDone,
  fetchDefaultShiftFail,

  fetchShiftSuccessEmployee,
  fetchShiftFailEmployee,

  fetchOvertimeShiftSuccessEmployee,
  fetchOvertimeShiftFailEmployee,

  fetchOvertimeDateForShiftDone,
  fetchOvertimeDateForShiftFail,

  assignShiftDone,
  assignShiftFail,

  acceptShiftAssignmentDone,
  acceptShiftAssignmentFail,

  createAttendanceDone,
  createAttendanceFail,

  breakAttendanceCompletion,
  breakAttendanceCompletionDone,
  breakAttendanceCompletionFail,
} = Creators;

// fetchDefaultShift

function* fetchDefaultShiftSaga(action) {
  const response = yield call(fetchDefaultShiftApi);
  if (response.status) {
    yield put(fetchDefaultShiftDone(response.data.results));
  } else {
    yield put(fetchDefaultShiftFail(response.data));
  }
}
// fetch all shift
function* fetchShiftSaga({ shift }) {
  const response = yield call(fetchShift, shift);
  if (response.status) {
    yield put(fetchShiftSuccess(response.data));
  } else {
    yield put(fetchShiftFail(response));
  }
}
// fetch all shift
function* showShiftSaga({ shift }) {
  const response = yield call(showShift, shift);
  if (response.status) {
    yield put(showShiftSuccess(response.data));
  } else {
    yield put(showShiftFail(response));
  }
}
function* setShiftSaga({ shift }) {
  yield put(setShift(shift));
}
//create shift
function* createShiftSaga({ shift }) {
  const response = yield call(createShift, shift);
  if (response.status) {
    yield put(createShiftDone(response.data));
    yield put(Creators.fetchShift()); /// update shift list
  } else {
    yield put(createShiftFail(response));
  }
}

//delete shift
function* deleteShiftSaga(shift_id) {
  const response = yield call(deleteShift, shift_id.shift);
  if (response.status) {
    yield put(deleteShiftDone(response));
    yield put(Creators.fetchShift()); /// update shift list
  } else {
    yield put(deleteShiftFail(response));
  }
}
//update shift
function* updateShiftSaga(shift) {
  const response = yield call(updateShift, shift);
  if (response.status) {
    yield put(updateShiftDone(response));
    yield put(Creators.fetchShift()); /// update shift list
  } else {
    yield put(updateShiftFail(response));
  }
}
// fetch all shift
function* fetchShiftEmployeeSaga(shift) {
  const response = yield call(fetchShiftEmployee, shift);
  if (response.status) {
    yield put(fetchShiftSuccessEmployee(response.data));
  } else {
    yield put(fetchShiftFailEmployee(response));
  }
}
// fetch all shift
function* fetchOvertimeShiftEmployeeSaga(shift) {
  const response = yield call(fetchOvertimeShiftEmployee, shift.shift);
  if (response.status) {
    yield put(fetchOvertimeShiftSuccessEmployee(response.data));
  } else {
    yield put(fetchOvertimeShiftFailEmployee(response));
  }
}
// fetch employees with overtime date on a shift
function* fetchOvertimeDateForShiftSaga(shift) {
  const response = yield call(fetchOvertimeDateForShift, shift.shift);
  if (response.status) {
    yield put(fetchOvertimeDateForShiftDone(response.data));
  } else {
    yield put(fetchOvertimeDateForShiftFail(response));
  }
}
//assign employee to shift
function* assignShiftSaga({ shift }) {
  const response = yield call(assignEmployeeShift, shift);
  if (response.status) {
    yield put(assignShiftDone(response.data));
  } else {
    yield put(assignShiftFail(response.error));
  }
}

function* acceptShiftSaga({ shift_id, status }) {
  const response = yield call(acceptAssignmentApi, shift_id, status);
  if (response.status) {
    yield put(PtoCreators.fetchUserDetails()); // update user's informations
    yield put(acceptShiftAssignmentDone(response.data));
  } else {
    yield put(acceptShiftAssignmentFail(response));
  }
}

function* createAttendanceSaga(shift) {
  const response = yield call(createAttendance, shift);
  if (response.status) {
    yield put(createAttendanceDone(response.data));
    
  } else {
    yield put(createAttendanceFail(response));
  }
}

function* breakAttendanceCompletionSaga(attendance) {
  const response = yield call(breakAttendanceCompletionApi, attendance);
  if (response.status) {
    yield put(breakAttendanceCompletionDone(response.data));
  } else {
    yield put(breakAttendanceCompletionFail(response));
  }
}

export function* watchFetchShift() {
  yield takeEvery(FETCH_SHIFT, fetchShiftSaga);
  yield takeEvery(FETCH_DEFAULT_SHIFT, fetchDefaultShiftSaga);
  yield takeEvery(FETCH_SHIFT_EMPLOYEE, fetchShiftEmployeeSaga);
  yield takeEvery(FETCH_OVERTIME_SHIFT_EMPLOYEE, fetchOvertimeShiftEmployeeSaga);
  yield takeEvery(FETCH_OVERTIME_DATE_FOR_SHIFT, fetchOvertimeDateForShiftSaga);
}

export function* watchCreateShift() {
  yield takeEvery(CREATE_SHIFT, createShiftSaga);
}
export function* watchAssignShift() {
  yield takeEvery(ASSIGN_SHIFT, assignShiftSaga);
  yield takeEvery(ACCEPT_SHIFT_ASSIGNMENT, acceptShiftSaga);
}
export function* watchDeleteShift() {
  yield takeEvery(DELETE_SHIFT, deleteShiftSaga);
}
export function* watchUpdateShift() {
  yield takeEvery(UPDATE_SHIFT, updateShiftSaga);
}
export function* watchShowShift() {
  yield takeEvery(SHOW_SHIFT, showShiftSaga);
}
export function* watchCreateAttendance() {
  yield takeEvery(CREATE_ATTENDANCE, createAttendanceSaga);
}

export function* watchBreakAttendanceCompletion() {
  yield takeEvery(BREAK_ATTENDANCE_COMPLETION, breakAttendanceCompletionSaga);
}

export default function* shiftSaga() {
  yield all([
    fork(watchCreateShift),
    fork(watchDeleteShift),
    fork(watchUpdateShift),
    fork(watchFetchShift),
    fork(watchAssignShift),
    fork(watchShowShift),
    fork(watchCreateAttendance),
    fork(watchBreakAttendanceCompletion),
  ]);
}
