import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Types, Creators } from './actions';
import {
  fetchContract,
  createContract,
  updateContract,
  deleteContract,
  fetchDefaultContractApi,
  fetchContractEmployee,
  fetchMarketPlace,
} from './api';
import RFPResource from '../../api/rfp';
import ContractModel from '../../models/contract';

const rfpResource = new RFPResource();

const {
  CREATE_CONTRACT,
  FETCH_MARKET_PLACE,
  FETCH_CONTRACT,
  FETCH_DEFAULT_CONTRACT,
  UPDATE_CONTRACT,
  DELETE_CONTRACT,
  FETCH_CONTRACT_EMPLOYEE,
  FETCH_PROPOSALS,
  DELETE_PROPOSAL,
} = Types;

const {
  createContractDone,
  createContractFail,

  fetchContractSuccess,
  fetchContractFail,

  updateContractDone,
  updateContractFail,

  deleteContractDone,
  deleteContractFail,

  fetchDefaultContractDone,
  fetchDefaultContractFail,

  fetchContractSuccessEmployee,
  fetchContractFailEmployee,

  fetchMarketPlaceSuccessEmployee,
  fetchMarketPlaceFailEmployee,

  fetchProposalsDone,
  fetchProposalsFail,

  deleteProposalDone,
  deleteProposalFail,
} = Creators;

// fetchDefaultContract

function* fetchDefaultContractSaga(action) {
  const response = yield call(fetchDefaultContractApi);
  if (response.status) {
    yield put(fetchDefaultContractDone(response.data.results));
  } else {
    yield put(fetchDefaultContractFail(response.data));
  }
}

// fetch all contract
function* fetchContractSaga({ contract }) {
  const response = yield call(fetchContract, contract);
  if (response.status) {
    yield put(fetchContractSuccess(response.data));
  } else {
    yield put(fetchContractFail(response));
  }
}
//create contract
function* createContractSaga({ contract }) {
  const response = yield call(createContract, contract);
  if (response.status) {
    yield put(createContractDone(response.data));
    yield put(Creators.fetchContract()); /// update contract list
  } else {
    yield put(createContractFail(response));
  }
}

//delete contract
function* deleteContractSaga(contract_id) {
  const response = yield call(deleteContract, contract_id);
  if (response.status) {
    yield put(deleteContractDone(response));
    yield put(Creators.fetchContract()); /// update contract list
  } else {
    yield put(deleteContractFail(response));
  }
}
//update contract
function* updateContractSaga(contract) {
  const response = yield call(updateContract, contract);
  if (response.status) {
    yield put(updateContractDone(response));
    yield put(Creators.fetchContract()); /// update contract list
  } else {
    yield put(updateContractFail(response));
  }
}
// fetch all contract
function* fetchContractEmployeeSaga(employee_pk) {
  const response = yield call(fetchContractEmployee, employee_pk);
  if (response.status) {
    yield put(fetchContractSuccessEmployee(response.data));
  } else {
    yield put(fetchContractFailEmployee(response));
  }
}

// fetch all market place
// TODO: handle pagination of data from API
function* fetchMarketPlaceEmployeeSaga() {
  const response = yield call(fetchMarketPlace);

  if (response.status) {
    yield put(fetchMarketPlaceSuccessEmployee(response.data.results));
  } else {
    yield put(fetchMarketPlaceFailEmployee(response));
  }
}

function* fetchContractProposalsSaga({ offset, limit }) {
  const response = yield call(rfpResource.list, offset, limit);

  if (response.status) {
    yield put(fetchProposalsDone(response.data.results.map((i) => ContractModel.fromJson(i))));
  } else {
    yield put(fetchProposalsFail(response.data));
  }
}

function* deleteProposalSaga({ proposal }) {
  const response = yield call(rfpResource.delete, proposal);

  if (response.status) {
    yield put(deleteProposalDone());
  } else {
    yield put(deleteProposalFail(response.data));
  }
}

export function* watchFetchContract() {
  yield takeEvery(FETCH_CONTRACT, fetchContractSaga);
  yield takeEvery(FETCH_DEFAULT_CONTRACT, fetchDefaultContractSaga);
  yield takeEvery(FETCH_CONTRACT_EMPLOYEE, fetchContractEmployeeSaga);
  yield takeEvery(FETCH_MARKET_PLACE, fetchMarketPlaceEmployeeSaga);
}

export function* watchCreateContract() {
  yield takeEvery(CREATE_CONTRACT, createContractSaga);
}

export function* watchDeleteContract() {
  yield takeEvery(DELETE_CONTRACT, deleteContractSaga);
}

export function* watchUpdateContract() {
  yield takeEvery(UPDATE_CONTRACT, updateContractSaga);
}

export function* watchFetchProposals() {
  yield takeEvery(FETCH_PROPOSALS, fetchContractProposalsSaga);
}

export function* watchDeleteProposal() {
  yield takeEvery(DELETE_PROPOSAL, deleteProposalSaga);
}

function* contractSaga() {
  yield all([
    fork(watchCreateContract),
    fork(watchDeleteContract),
    fork(watchUpdateContract),
    fork(watchFetchContract),
    fork(watchFetchProposals),
    fork(watchDeleteProposal),
  ]);
}

export default contractSaga;
