
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {Types, Creators} from './actions'
// api import
import { 
    fetchDashboardApi
} from './api'

// types import
const { FETCH_DASHBOARD } = Types
// success, fail import

const {
    fetchDashboardDone,
    fetchDashboardFail
} = Creators

// sagas handler

function* fetchDashboardSaga(){
    const response = yield call(fetchDashboardApi);
    if (response.status){
        yield put(fetchDashboardDone(response.data))
    }else{
        yield put(fetchDashboardFail(response.data))
    }
}


//saga watchers

export function* watchFetchDashboard(){
    yield takeEvery(FETCH_DASHBOARD, fetchDashboardSaga)
}


// saga builder

export default function* dashboardSaga(){
    yield all([
        fork(watchFetchDashboard),
    ])
}
    


    