import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Types, Creators } from './action'
// api import
import {
    fetchAttachmentsApi,
    fetchProjectsApi,
    createAttachmentApi,
    createProjectApi,
    updateAttachmentApi,
    updateProjectApi,
    deleteAttachmentApi,
    deleteProjectApi
} from './api'
import _ from 'lodash'
// types import
const { FETCH_PROJECTS, FETCH_ATTACHMENTS, CREATE_PROJECT, CREATE_ATTACHMENT, UPDATE_PROJECT, UPDATE_ATTACHMENT, DELETE_PROJECT, DELETE_ATTACHMENT } = Types
// success, fail import
const {
    fetchProjects,
    fetchProjectsDone,
    fetchProjectsFail,
    createProjectDone,
    createProjectFail,
    updateProjectDone,
    updateProjectFail,
    deleteProjectDone,
    deleteProjectFail,
    fetchAttachments,
    fetchAttachmentsDone,
    fetchAttachmentsFail,
    createAttachmentDone,
    createAttachmentFail,
    updateAttachmentDone,
    updateAttachmentFail,
    deleteAttachmentDone,
    deleteAttachmentFail,
} = Creators

// sagas handler

function* fetchProjectsSaga() {
    const response = yield call(fetchProjectsApi);
    if (response.status) {
        yield put(fetchProjectsDone(response))
    } else {
        yield put(fetchProjectsFail(response))
    }
}

function* fetchAttachmentsSaga() {
    const response = yield call(fetchAttachmentsApi);
    if (response.status) {
        yield put(fetchAttachmentsDone());
    } else {
        yield put(fetchAttachmentsFail(response))
    }
}
function* createProjectSaga({project}) {
    console.log("project",project)
    let p = {}
    if(_.isEmpty(project.attachments)){
         p = {
            name : project.title,
            description : project.description,
            attachments : []
        }
        
    }else{
        let attachments = []
        for (let index = 0; index < project.attachments.length; index++) {
            const attachment = project.attachments[index];
            console.log("attachment", attachment)
            const response = yield call(createAttachmentApi, attachment);
                if (response.status) {
                    attachments.push(response.data.id)
                    yield put(createAttachmentDone(response));
                } else {
                    yield put(createAttachmentFail(response))
                }
        }
        p = {
            name : project.title,
            description : project.description,
            attachments : attachments
        }

    }
    const response = yield call(createProjectApi, p);
        if (response.status) {
            yield put(createProjectDone(response))
            yield put(fetchProjects())
        } else {
            yield put(createProjectFail(response))
        }
    
}

function* createAttachmentSaga(attachment) {
    const response = yield call(createAttachmentApi, attachment);
    if (response.status) {
        yield put(createAttachmentDone(response));
    } else {
        yield put(createAttachmentFail(response))
    }
}

function* updateAttachmentSaga(attachment) {
    const response = yield call(updateAttachmentApi, attachment);
    if (response.status) {
        yield put(updateAttachmentDone(response));
    } else {
        yield put(updateAttachmentFail(response))
    }
}
function* updateProjectSaga(project) {
    const response = yield call(updateProjectApi, project);
    if (response.status) {
        yield put(updateProjectDone(response))
    } else {
        yield put(updateProjectFail(response))
    }
}


function* deleteAttachmentSaga(attachment) {
    const response = yield call(deleteAttachmentApi, attachment);
    if (response.status) {
        yield put(deleteAttachmentDone(response));
    } else {
        yield put(deleteAttachmentFail(response))
    }
}
function* deleteProjectSaga(project) {
    const response = yield call(deleteProjectApi, project);
    if (response.status) {
        yield put(deleteProjectDone(response))
    } else {
        yield put(deleteProjectFail(response))
    }
}
  
    //saga watchers

    export function* watchFetch() {
        yield takeEvery(FETCH_PROJECTS, fetchProjectsSaga)
        yield takeEvery(FETCH_ATTACHMENTS, fetchAttachmentsSaga)

    }

    //saga watchers

    export function* watchCreate() {
        yield takeEvery(CREATE_PROJECT, createProjectSaga)
        yield takeEvery(CREATE_ATTACHMENT, createAttachmentSaga)
    }

    export function* watchUpdate() {
        yield takeEvery(UPDATE_ATTACHMENT, updateAttachmentSaga)
        yield takeEvery(UPDATE_PROJECT, updateProjectSaga)
    }
    export function* watchDelete() {
        yield takeEvery(DELETE_ATTACHMENT, deleteAttachmentSaga)
        yield takeEvery(DELETE_PROJECT, deleteProjectSaga)
    }

    // saga builder

    export default function* PayrollSaga() {
        yield all([
            fork(watchFetch),
            fork(watchCreate),
            fork(watchUpdate),
            fork(watchDelete)
        ])
    }